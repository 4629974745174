/* tslint:disable */
/* eslint-disable */

/**
 * Product Sustainability Attribute Category
 */
export enum ProductSustainabilityCategoryEnum {
  Biodegradability = 'biodegradability',
  Biodiversity = 'biodiversity',
  Renewables = 'renewables',
  EcoCertification = 'eco-certification',
  ResourceEfficiency = 'resource-efficiency',
  Emissions = 'emissions',
  HealthAndSafety = 'health-and-safety',
  Durability = 'durability',
  SustainableDevelopmentGoals = 'sustainable-development-goals',
  ResourceAndEcosystems = 'resource-and-ecosystems',
  ClimateChange = 'climate-change',
  Water = 'water',
  WasteReduction = 'waste-reduction',
  CostSavings = 'cost-savings',
  Energy = 'energy',
  Sun = 'sun',
  Windmill = 'windmill'
}
