import { delay, Observable, of } from 'rxjs';

import { AnnouncementApiService, SystemAnnouncementResponseDto } from '../../api';

const serverDelay = 500; // ms

export const mockAnnouncementApiService: Partial<AnnouncementApiService> = {
  getSystemAnnouncement(): Observable<SystemAnnouncementResponseDto> {
    return of({ systemAnnouncement: null }).pipe(delay(serverDelay));
  },
};
