/* tslint:disable */
/* eslint-disable */

/**
 * Updated state of the cart tour to be saved in the DB. Since we save it as JSON and we always return the whole object, we also need the whole object back including tours that have not been updated.
 */
export enum TourState {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED'
}
