/* tslint:disable */
/* eslint-disable */

/**
 * Salesforce case status, can be open or closed for normal cases and open, approved or rejected for document requests
 */
export enum InquiryApprovalStatus {
  Open = 'Open',
  Approved = 'Approved',
  Rejected = 'Rejected',
  Closed = 'Closed'
}
