/* tslint:disable */
/* eslint-disable */

/**
 * Type of the Announcement
 */
export enum AnnouncementTypeEnum {
  Info = 'info',
  Error = 'error',
  Warning = 'warning',
  Success = 'success'
}
