import {
  CartCalculationStatusEnum,
  CartResponseDto,
  SavedCartListResponseDto,
  SavedCartResponseDto,
} from '../../api';

export const mockCart: CartResponseDto = {
  applicableUnloadingPoints: ['Unloading Point 1', 'Unloading Point 2'],
  id: '2ccc1693-aeaf-4438-8a7e-ff62c858b247',
  poNumber: 'po-1234',
  // deliveryInstructions: 'Please leave the delivery with our doorman',
  // emailAddresses: ['string'],
  errors: [
    // 'Sample error message on cart level'
  ],
  // warnings: [
  //   //'Sample warning message on cart level'
  // ],
  lineItems: [
    {
      id: '92fcf632-9683-42be-a5db-18ad76e30222',
      errors: ['Sample line item level error message'],
      unloadingPoint: 'Unloading Point 1',
      freeTexts: [
        {
          code: 'ZAX1',
          value: 'Sample free text for itemInvoiceText',
        },
        {
          code: 'ZK03',
          value: 'Sample free text for optionalInstructions',
        },
        {
          code: 'ZAI1',
          value: 'Sample free text for orderProcessInstructions',
        },
      ],
      partners: [
        {
          partnerType: 'VE',
          name: 'Max Mustermann',
          email: 'max.mustermann@mail.com',
          phone: '+49 123 123 123',
        },
        {
          partnerType: 'VE',
          name: 'Max Mustermann',
          email: 'max.mustermann@mail.com',
          phone: '+49 123 123 123',
        },
      ],
      product: {
        key: '41547bf8-bd07-48c5-b923-c87794d1ba61',
        name: 'Magnafloc® 155',
        slug: 'magnafloc-155-30482101',
      },
      article: {
        key: '50120575',
        customerArticleId: '523211',
        name: 'Magnafloc® 155 25KG PE-Bag',
        unitOfMeasure: 'KGM',
        packagingType: 'VERP',
        dangerousGoodsIndicator: false,
        packagingSize: 25,
        packagingSizeText: '25KG PE-Bag',
      },
      quantity: 1234,
      unitNetPrice: {
        value: 0,
        currency: 'AUD',
      },
      unitGrossPrice: {
        value: 0,
        currency: '',
      },
      totalNetPrice: {
        value: 657,
        currency: 'USD',
      },
      netPrice: {
        value: 123.8,
        currency: 'AUD',
      },
      totalTax: {
        value: 18.65,
        currency: 'AUD',
      },
      grossPrice: {
        value: 0,
        currency: '',
      },
      totalGrossPrice: {
        value: 0,
        currency: 'AUD',
      },
      totalPriceWithoutIpi: {
        value: 0,
        currency: 'AUD',
      },
      tooltipNetPrice: {
        value: 0,
        currency: '',
      },
      netPriceUnit: {
        value: 1,
        currency: 'EUR',
      },
      grossPriceUnit: '',
      netPriceUom: 'KGM',
      netPriceUomAbbreviation: 'KG',
      netPriceUomLongText: 'Kilogram',
      uom: 'KGM',
      totalQuantity: 30850,
      grossPriceUom: '',
      validUoms: [
        {
          uom: 'KG',
          abbreviation: 'KG',
          longText: 'Kilogram',
          decimals: 3,
          packageSize: 25,
        },
      ],
      ipiPercentage: 0,
      icmsPercentage: 0,
      pisCofinsPercentage: '',
      // requestedDeliveryDate: '2027-03-21',
      requestedDeliveryTimeFrom: '01:00',
      requestedDeliveryTimeTo: '02:30',
      salesArea: 'AU01-M6-01',
      poNumber: '1',
      processType: 'STANDARD',
      erp: 'COBALT',
      orderType: 'ZTE',
      incotermCode: 'DDP',
      validIncotermCodes: [
        'CFR:SC,W0,WC',
        'CIF:S0,SC,T0,W0,WC',
        'CIP:A0,A1,SC,T0,W0,WC',
        'CPT:A0,A1,SC,T0,W0,WC',
        'DAP:A0,A1,SC,T0,W0,WC',
        'DAT:A0,A1,SC,T0,W0,WC',
        'DDP:A0,A1,SC,T0,W0,WC',
        'EXW:10,1C,4C,A0,A1,T0',
        'FAS:4C',
        'FCA:10,1C,4C,A0,A1,SC,T0,W0,WC',
        'FOB:4C,T0',
      ],
      validIncotermDescriptions: [
        {
          code: 'CIF',
          desc: 'Cost, Insurance, Freight',
        },
        {
          code: 'DDP',
          desc: 'Delivered Duty Paid',
        },
        {
          code: 'DAP',
          desc: 'Delivered at place',
        },
        {
          code: 'EXW',
          desc: 'Ex Works',
        },
        {
          code: 'FCA',
          desc: 'Free Carrier',
        },
        {
          code: 'CPT',
          desc: 'Carriage Paid To',
        },
        {
          code: 'DAT',
          desc: '++ungültig/not valid+++',
        },
        {
          code: 'FOB',
          desc: 'Free on Board',
        },
        {
          code: 'FAS',
          desc: 'Free Alongside Ship',
        },
        {
          code: 'CIP',
          desc: 'Carriage & Insurance Paid',
        },
        {
          code: 'CFR',
          desc: 'Cost and Freight',
        },
      ],
      incotermCode2: 'Sunshine',
      validShippingConditionCodes: [
        '10:EXW,FCA',
        '1C:EXW,FCA',
        '4C:EXW,FAS,FCA,FOB',
        'A0:CIP,CPT,DAP,DAT,DDP,EXW,FCA',
        'A1:CIP,CPT,DAP,DAT,DDP,EXW,FCA',
        'S0:CIF',
        'SC:CFR,CIF,CIP,CPT,DAP,DAT,DDP,FCA',
        'T0:CIF,CIP,CPT,DAP,DAT,DDP,EXW,FCA,FOB',
        'W0:CFR,CIF,CIP,CPT,DAP,DAT,DDP,FCA',
        'WC:CFR,CIF,CIP,CPT,DAP,DAT,DDP,FCA',
      ],
      validShippingConditionDescriptions: [
        {
          code: 'A1',
          desc: 'Air Pax A-Service',
        },
        {
          code: 'SC',
          desc: 'sea/container',
        },
        {
          code: '1C',
          desc: 'cust.truck/container',
        },
        {
          code: 'W0',
          desc: 'inland vessel',
        },
        {
          code: 'T0',
          desc: 'truck',
        },
        {
          code: 'WC',
          desc: 'Waterway-container',
        },
        {
          code: 'S0',
          desc: 'sea',
        },
        {
          code: '10',
          desc: 'customer truck',
        },
        {
          code: '4C',
          desc: 'sea/container cust.',
        },
        {
          code: 'A0',
          desc: 'Air Pax B-Service',
        },
      ],
      shippingConditionCode: 'T0',
      shippingConditionDescription: 'truck',
      plantCode: 'AU1B',
      plantDescription: 'Toll Laverton North',
      validPlantCodes: [
        {
          code: 'AU1B',
          name: 'Toll Laverton North',
        },
      ],
      paymentTermCode: 'GM15',
      paymentTermDescription: 'Net end of 1st month',
      validPaymentTermDescriptions: [
        {
          code: 'GM15',
          desc: 'Net end of 1st month',
        },
        {
          code: 'GM16',
          desc: 'Mock Selection',
        },
      ],
      incotermDescription: 'Delivered Duty Paid',
      grossWeight: 31096.8, // total gross weight in lineItemGroupComponent
      grossWeightUom: 'KGM',
      grossWeightUomAbbreviation: 'KG', // total gross weight unit in lineItemGroupComponent
      grossWeightUomLongText: 'Kilogram',
      customNumber: '000011',
    },
    {
      id: '92fcf632-9683-42be-a5db-18ad76e30222-second',
      product: {
        key: '41547bf8-bd07-48c5-b923-c87794d1ba61',
        name: 'Magnafloc® 155',
        slug: 'magnafloc-155-30482101',
      },
      article: {
        key: '50120575',
        customerArticleId: '523211',
        name: 'Magnafloc® 155 25KG PE-Bag',
        unitOfMeasure: 'KGM',
        packagingType: 'VERP',
        dangerousGoodsIndicator: false,
        packagingSize: 25,
        packagingSizeText: '25KG PE-Bag',
      },
      quantity: 1234,
      unitNetPrice: {
        value: 0,
        currency: 'AUD',
      },
      unitGrossPrice: {
        value: 0,
        currency: '',
      },
      totalNetPrice: {
        value: 657,
        currency: 'USD',
      },
      netPrice: {
        value: 0,
        currency: 'AUD',
      },
      grossPrice: {
        value: 0,
        currency: '',
      },
      totalGrossPrice: {
        value: 0,
        currency: 'AUD',
      },
      totalPriceWithoutIpi: {
        value: 0,
        currency: 'AUD',
      },
      tooltipNetPrice: {
        value: 0,
        currency: '',
      },
      netPriceUnit: {
        value: 1,
        currency: 'EUR',
      },
      grossPriceUnit: '',
      netPriceUom: 'KGM',
      netPriceUomAbbreviation: 'KG',
      netPriceUomLongText: 'Kilogram',
      uom: 'KGM',
      totalQuantity: 30850,
      grossPriceUom: '',
      validUoms: [
        {
          uom: 'KG',
          abbreviation: 'KG',
          longText: 'Kilogram',
          decimals: 3,
          packageSize: 25,
        },
      ],
      ipiPercentage: 0,
      icmsPercentage: 0,
      pisCofinsPercentage: '',
      requestedDeliveryDate: '2027-03-21',
      salesArea: 'AU01-M6-01',
      poNumber: '1',
      processType: 'STANDARD',
      erp: 'COBALT',
      orderType: 'ZTE',
      incotermCode: 'DDP',
      validIncotermCodes: [
        'CFR:SC,W0,WC',
        'CIF:S0,SC,T0,W0,WC',
        'CIP:A0,A1,SC,T0,W0,WC',
        'CPT:A0,A1,SC,T0,W0,WC',
        'DAP:A0,A1,SC,T0,W0,WC',
        'DAT:A0,A1,SC,T0,W0,WC',
        'DDP:A0,A1,SC,T0,W0,WC',
        'EXW:10,1C,4C,A0,A1,T0',
        'FAS:4C',
        'FCA:10,1C,4C,A0,A1,SC,T0,W0,WC',
        'FOB:4C,T0',
      ],
      validIncotermDescriptions: [
        {
          code: 'CIF',
          desc: 'Cost, Insurance, Freight',
        },
        {
          code: 'DDP',
          desc: 'Delivered Duty Paid',
        },
        {
          code: 'DAP',
          desc: 'Delivered at place',
        },
        {
          code: 'EXW',
          desc: 'Ex Works',
        },
        {
          code: 'FCA',
          desc: 'Free Carrier',
        },
        {
          code: 'CPT',
          desc: 'Carriage Paid To',
        },
        {
          code: 'DAT',
          desc: '++ungültig/not valid+++',
        },
        {
          code: 'FOB',
          desc: 'Free on Board',
        },
        {
          code: 'FAS',
          desc: 'Free Alongside Ship',
        },
        {
          code: 'CIP',
          desc: 'Carriage & Insurance Paid',
        },
        {
          code: 'CFR',
          desc: 'Cost and Freight',
        },
      ],
      incotermCode2: 'Sunshine',
      validShippingConditionCodes: [
        '10:EXW,FCA',
        '1C:EXW,FCA',
        '4C:EXW,FAS,FCA,FOB',
        'A0:CIP,CPT,DAP,DAT,DDP,EXW,FCA',
        'A1:CIP,CPT,DAP,DAT,DDP,EXW,FCA',
        'S0:CIF',
        'SC:CFR,CIF,CIP,CPT,DAP,DAT,DDP,FCA',
        'T0:CIF,CIP,CPT,DAP,DAT,DDP,EXW,FCA,FOB',
        'W0:CFR,CIF,CIP,CPT,DAP,DAT,DDP,FCA',
        'WC:CFR,CIF,CIP,CPT,DAP,DAT,DDP,FCA',
      ],
      validShippingConditionDescriptions: [
        {
          code: 'A1',
          desc: 'Air Pax A-Service',
        },
        {
          code: 'SC',
          desc: 'sea/container',
        },
        {
          code: '1C',
          desc: 'cust.truck/container',
        },
        {
          code: 'W0',
          desc: 'inland vessel',
        },
        {
          code: 'T0',
          desc: 'truck',
        },
        {
          code: 'WC',
          desc: 'Waterway-container',
        },
        {
          code: 'S0',
          desc: 'sea',
        },
        {
          code: '10',
          desc: 'customer truck',
        },
        {
          code: '4C',
          desc: 'sea/container cust.',
        },
        {
          code: 'A0',
          desc: 'Air Pax B-Service',
        },
      ],
      shippingConditionCode: 'T0',
      shippingConditionDescription: 'truck',
      plantCode: 'AU1B',
      plantDescription: 'Toll Laverton North',
      paymentTermCode: 'GM15',
      paymentTermDescription: 'Net end of 1st month',
      incotermDescription: 'Delivered Duty Paid',
      grossWeight: 31096.8, // total gross weight in lineItemGroupComponent
      grossWeightUom: 'KGM',
      grossWeightUomAbbreviation: 'KG', // total gross weight unit in lineItemGroupComponent
      grossWeightUomLongText: 'Kilogram',
      customNumber: '000010',
    },
  ],
  lineItemGroups: [
    {
      freeTexts: [
        {
          code: 'ZAV1',
          value: 'Sample free text for shippingInstructions',
        },
        {
          code: 'ZAIH',
          value: 'Sample free text for remarks',
        },
        {
          code: 'ZAKX',
          value: 'Sample free text for notes',
        },
        {
          code: 'ZAF1',
          value: 'Sample free text for invoiceText',
        },
        {
          code: 'ZAI1',
          value: 'Sample free text for overallShippingInstructions',
        },
      ],
      id: '0000000101',
      lineItems: [
        '92fcf632-9683-42be-a5db-18ad76e30222',
        '92fcf632-9683-42be-a5db-18ad76e30222-second',
      ],
      poNumber: '123123123', // Purchase Order in lineItemGroupComponent
      salesArea: 'AU01-M6-01',
      salesOrg: 'AU01',
      salesOrgDescription: 'BASF Australia Ltd', // top line on the lineItemGroupComponent
      distributionChannelCode: 'M6',
      distributionChannelDescription: 'Mining Solutions', // top line on the lineItemGroupComponent
      shippingCondition: 'T0',
      shippingConditionDescription: 'truck',
      orderType: 'ZTE',
      orderTypeDescription: 'Order',
      grossPrice: {
        // Total of Order in Figma
        value: 2345,
        currency: 'AUD',
      },
      netPrice: {
        // Total all Line Items in Figma
        value: 1879,
        currency: 'AUD',
      },
      totalTax: {
        value: 18.65,
        currency: 'AUD',
      },
      notafiscalTotalFullPrice: {
        value: 0,
        currency: 'AUD',
      },
      notafiscalTotalPrice: {
        value: 0,
        currency: 'AUD',
      },
      totalSurcharge: {
        value: 0,
      },
      totalWeight: 31096.8,
      weightUom: 'KGM',
      weightUomAbbreviation: 'KG',
      weightUomLongText: 'Kilogram',
      errors: [
        'The current order value is 0.00 AUD. This does not meet your minimum order value of 1,669.40 AUD. Please adapt your order value to be able to continue.',
      ],
      warnings: [],
    },
    {
      id: '0000000102',
      lineItems: ['2a3f7366-5187-47e8-9380-aaff634a8b1a'],
      poNumber: '123123123',
      salesArea: 'AU01-YH-01',
      salesOrg: 'AU01',
      salesOrgDescription: 'BASF Australia Ltd',
      distributionChannelCode: 'YH',
      distributionChannelDescription: 'Fuel & Lubricant Sol',
      shippingCondition: 'SC',
      shippingConditionDescription: 'sea/container',
      orderType: 'ZTE',
      orderTypeDescription: 'Order',
      grossPrice: {
        value: 0,
        currency: 'AUD',
      },
      netPrice: {
        value: 0,
        currency: 'USD',
      },
      totalTax: {
        value: 0,
        currency: 'USD',
      },
      notafiscalTotalFullPrice: {
        value: 0,
        currency: 'USD',
      },
      notafiscalTotalPrice: {
        value: 0,
        currency: 'USD',
      },
      totalSurcharge: {
        value: 0,
      },
      totalWeight: 218,
      weightUom: 'KGM',
      weightUomAbbreviation: 'KG',
      weightUomLongText: 'Kilogram',
      errors: [],
      warnings: [],
    },
  ],
  totalNetPrice: {
    value: 2200.1,
    currency: 'EUR',
  },
  totalSurcharge: {
    value: 100.1,
    currency: 'EUR',
  },
  totalGrossPrice: {
    value: 2300.2,
    currency: 'EUR',
  },
  lastModifiedAt: '2022-02-01T07:28:00Z',
  // expiresAt: '2022-03-03T07:28:00Z',
  cartState: 'ACTIVE',
  // calculationStatus: 'CALCULATION_REQUIRED',
  calculationStatus: CartCalculationStatusEnum.CalculationRequired,
  lastCalculatedAt: '2022-02-01T07:28:00Z',
  incoTerm: {
    code: 'FCA',
  },
  shipToAccountBlock: true,
  invalidArticles: ['string'],
};

export const invalidMockCart: CartResponseDto = {
  lineItems: [],
  lineItemGroups: [],
  id: '2ccc1693-aeaf-4438-8a7e-ff62c858b247',
  poNumber: 'po-1234',
  totalNetPrice: {
    value: 2200.1,
    currency: 'EUR',
  },
  totalGrossPrice: {
    value: 2200.1,
    currency: 'EUR',
  },
  lastModifiedAt: '2022-02-01T07:28:00Z',
  cartState: 'ACTIVE',
  calculationStatus: CartCalculationStatusEnum.Calculated,
  lastCalculatedAt: '2022-02-01T07:28:00Z',
  incoTerm: {
    code: 'FCA',
  },
  shipToAccountBlock: true,
  invalidArticles: ['string'],
};

export const mockEvSavedCartResponse: SavedCartResponseDto = {
  id: '35e69193-8819-4e37-871c-ba270f91b3c5',
  creationDate: '2023-05-15',
  name: 'Some random name',
  totalItemsCount: 1,
  lineItems: [
    {
      id: '1d641832-97a6-49c2-9a5d-f5a77a473c80',
      product: {
        key: '30630097',
        name: 'Drimax® 1239 NF 1000KG Composite - IBC (PE/Metal)',
        slug: 'drimax-1239-nf',
      },
      article: {
        name: 'something',
        key: '50420997',
        unitOfMeasure: 'KGM',
        packagingType: 'VERP',
        packagingSize: 1000.0,
        customerArticleId: '523211',
      },
      quantity: 1,
    },
    {
      id: '1d641832-97a6-49c2-9a5d-f5a77a473c81',
      product: {
        key: '30630092',
        name: 'Drimax® 1239 NF 1000KG Composite - IBC (PE/Metal) 2',
        slug: 'drimax-1239-nf',
      },
      article: {
        name: 'something',
        key: '50420992',
        unitOfMeasure: 'KGM',
        packagingType: 'VERP',
        packagingSize: 500.0,
        customerArticleId: '523212',
      },
      quantity: 3,
    },
    {
      id: '1d641832-97a6-49c2-9a5d-f5a77a473c82',
      product: {
        key: '30630093',
        name: 'Drimax® 1239 NF 1000KG Composite - IBC (PE/Metal) 3',
        slug: 'drimax-1239-nf',
      },
      article: {
        name: 'something',
        key: '50420993',
        unitOfMeasure: 'KGM',
        packagingType: 'VERP',
        packagingSize: 100.0,
        customerArticleId: '523213',
      },
      quantity: 12,
    },
  ],
};

export const mockEvSavedCartListResponse: SavedCartListResponseDto = {
  total: 2,
  count: 2,
  limit: 10,
  offset: 0,
  savedCarts: [
    {
      id: '9dd982ab-6c5c-4ae5-9be5-905f0289f8ec',
      creationDate: '2023-05-15',
      name: 'test',
      totalItemsCount: 1,
      lineItems: [
        {
          id: 'df9ba83b-c335-4383-bbbf-37af84036163',
          product: {
            key: '30502414',
            name: 'Magnafloc® 1425 1080KG Composite - IBC (PE/Metal)',
            slug: 'magnafloc-1425',
          },
          article: {
            name: 'something',
            key: '50136111',
            unitOfMeasure: 'KGM',
            packagingType: 'VERP',
            packagingSize: 1080.0,
            customerArticleId: null,
          },
          quantity: 1,
        },
      ],
    },
    mockEvSavedCartResponse,
    mockEvSavedCartResponse,
    mockEvSavedCartResponse,
    mockEvSavedCartResponse,
    mockEvSavedCartResponse,
  ],
};

export const mockEvSavedCartNamedChangedResponse: SavedCartResponseDto = {
  id: '35e69193-8819-4e37-871c-ba270f91b3c5',
  creationDate: '2023-05-15',
  name: 'new name',
  totalItemsCount: 1,
  lineItems: [
    {
      id: '1d641832-97a6-49c2-9a5d-f5a77a473c80',
      product: {
        key: '30630097',
        name: 'Drimax® 1239 NF 1000KG Composite - IBC (PE/Metal)',
        slug: 'drimax-1239-nf',
      },
      article: {
        name: 'something',
        key: '50420997',
        unitOfMeasure: 'KGM',
        packagingType: 'VERP',
        packagingSize: 1000.0,
        customerArticleId: '523211',
      },
      quantity: 1,
    },
  ],
};

export const mockEvSavedCartRemovedItemResponse: SavedCartResponseDto = {
  id: '35e69193-8819-4e37-871c-ba270f91b3c5',
  creationDate: '2023-05-15',
  name: 'Some random name',
  totalItemsCount: 0,
  lineItems: [],
};
