/* tslint:disable */
/* eslint-disable */

/**
 * User's permission based on location selection.
 */
export enum PermissionsEnum {
  Checkout = 'checkout',
  CheckoutDeliveryTime = 'checkout_delivery_time',
  CheckoutHeaderAdditionalNotes = 'checkout_header_additional_notes',
  CheckoutHeaderInvoiceText = 'checkout_header_invoice_text',
  CheckoutHeaderPoAttachment = 'checkout_header_po_attachment',
  CheckoutHeaderRemarksOrderProcessInstructions = 'checkout_header_remarks_order_process_instructions',
  CheckoutHeaderShipmentHandlingInstructions = 'checkout_header_shipment_handling_instructions',
  CheckoutHeaderShipmentInstructions = 'checkout_header_shipment_instructions',
  CheckoutHeaderTexts = 'checkout_header_texts',
  CheckoutItemInvoiceText = 'checkout_item_invoice_text',
  CheckoutItemOptSpecialInstructions = 'checkout_item_opt_special_instructions',
  CheckoutItemOrderProcessInstructions = 'checkout_item_order_process_instructions',
  CheckoutPlantSelection = 'checkout_plant_selection',
  CheckoutUnloadingInstructions = 'checkout_unloading_instructions',
  CheckoutUnloadingPoint = 'checkout_unloading_point',
  FinancialOverview = 'financial_overview',
  ManagePlantTest = 'manage_plant_test',
  UomSelection = 'uom_selection',
  OrderHistory = 'order_history',
  PriceDisplay = 'price_display',
  TrackAndTrace = 'track_and_trace'
}
