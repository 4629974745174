/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DocumentListResponseDto } from '../models/document-list-response-dto';
import { DownloadTrackingConsentResponseDto } from '../models/download-tracking-consent-response-dto';

@Injectable({
  providedIn: 'root',
})
export class DocumentsApiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation downloadSdsDocument
   */
  static readonly DownloadSdsDocumentPath = '/api/documents/download/sds';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadSdsDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadSdsDocument$Response(params: {
    bi?: string;
    language: string;
    material: string;
    salesOrg: string;
    sbgv: string;
    supplier?: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentsApiService.DownloadSdsDocumentPath, 'get');
    if (params) {
      rb.query('bi', params.bi, {});
      rb.query('language', params.language, {});
      rb.query('material', params.material, {});
      rb.query('salesOrg', params.salesOrg, {});
      rb.query('sbgv', params.sbgv, {});
      rb.query('supplier', params.supplier, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `downloadSdsDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadSdsDocument(params: {
    bi?: string;
    language: string;
    material: string;
    salesOrg: string;
    sbgv: string;
    supplier?: string;
  }): Observable<void> {

    return this.downloadSdsDocument$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation downloadPimDocument
   */
  static readonly DownloadPimDocumentPath = '/api/documents/download/pim';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadPimDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadPimDocument$Response(params: {
    documentId: string;
    productId: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentsApiService.DownloadPimDocumentPath, 'get');
    if (params) {
      rb.query('documentId', params.documentId, {});
      rb.query('productId', params.productId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `downloadPimDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadPimDocument(params: {
    documentId: string;
    productId: string;
  }): Observable<void> {

    return this.downloadPimDocument$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getDocumentList
   */
  static readonly GetDocumentListPath = '/api/documents';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocumentList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentList$Response(params: {
    productId: string;
  }): Observable<StrictHttpResponse<DocumentListResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentsApiService.GetDocumentListPath, 'get');
    if (params) {
      rb.query('productId', params.productId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DocumentListResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDocumentList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentList(params: {
    productId: string;
  }): Observable<DocumentListResponseDto> {

    return this.getDocumentList$Response(params).pipe(
      map((r: StrictHttpResponse<DocumentListResponseDto>) => r.body as DocumentListResponseDto)
    );
  }

  /**
   * Path part for operation getUsersDocumentTrackingConsents
   */
  static readonly GetUsersDocumentTrackingConsentsPath = '/api/documents/download-tracking-consents';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUsersDocumentTrackingConsents()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsersDocumentTrackingConsents$Response(params?: {
  }): Observable<StrictHttpResponse<DownloadTrackingConsentResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentsApiService.GetUsersDocumentTrackingConsentsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DownloadTrackingConsentResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUsersDocumentTrackingConsents$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsersDocumentTrackingConsents(params?: {
  }): Observable<DownloadTrackingConsentResponseDto> {

    return this.getUsersDocumentTrackingConsents$Response(params).pipe(
      map((r: StrictHttpResponse<DownloadTrackingConsentResponseDto>) => r.body as DownloadTrackingConsentResponseDto)
    );
  }

}
