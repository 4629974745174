/* tslint:disable */
/* eslint-disable */

/**
 * Open Order Status options. It returns grouped results
 */
export enum OpenOrderStatusEnum {
  Open = 'open',
  Shipped = 'shipped',
  All = 'all'
}
