import { delay, Observable, of } from 'rxjs';

import { QuestionnaireDto, ThickenerExplorerApiService } from '../../api';
import { mockQuestionnaireData } from '../data';
import { mockThickenerGraphResponseDto } from '../data/mock-thickener-graph.data';

const localDB = {
  mockQuestionnaire: mockQuestionnaireData,
  mockGraphData: mockThickenerGraphResponseDto,
};

const serverDelay = 500; // ms

export const mockThickenerExplorerApiService: Partial<ThickenerExplorerApiService> = {
  getQuestionnaire(): Observable<QuestionnaireDto> {
    return of(localDB.mockQuestionnaire).pipe(delay(serverDelay));
  },
  getGraphData(): Observable<any> {
    return of(localDB.mockGraphData).pipe(delay(serverDelay));
  },
};
