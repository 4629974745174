/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { QuestionnaireDto } from '../models/questionnaire-dto';
import { ThickenerGraphResponseDto } from '../models/thickener-graph-response-dto';

@Injectable({
  providedIn: 'root',
})
export class ThickenerExplorerApiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getQuestionnaire
   */
  static readonly GetQuestionnairePath = '/api/thickener-explorer';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getQuestionnaire()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQuestionnaire$Response(params?: {
  }): Observable<StrictHttpResponse<QuestionnaireDto>> {

    const rb = new RequestBuilder(this.rootUrl, ThickenerExplorerApiService.GetQuestionnairePath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<QuestionnaireDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getQuestionnaire$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQuestionnaire(params?: {
  }): Observable<QuestionnaireDto> {

    return this.getQuestionnaire$Response(params).pipe(
      map((r: StrictHttpResponse<QuestionnaireDto>) => r.body as QuestionnaireDto)
    );
  }

  /**
   * Path part for operation getGraphData
   */
  static readonly GetGraphDataPath = '/api/thickener-explorer/graph-data/{questionnaireLeafId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGraphData()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGraphData$Response(params: {
    questionnaireLeafId: string;
  }): Observable<StrictHttpResponse<ThickenerGraphResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, ThickenerExplorerApiService.GetGraphDataPath, 'get');
    if (params) {
      rb.path('questionnaireLeafId', params.questionnaireLeafId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ThickenerGraphResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getGraphData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGraphData(params: {
    questionnaireLeafId: string;
  }): Observable<ThickenerGraphResponseDto> {

    return this.getGraphData$Response(params).pipe(
      map((r: StrictHttpResponse<ThickenerGraphResponseDto>) => r.body as ThickenerGraphResponseDto)
    );
  }

}
