/* tslint:disable */
/* eslint-disable */

/**
 * Indication if the cart is in a dirty state and requires recalculation
 */
export enum CartCalculationStatusEnum {
  Calculated = 'CALCULATED',
  CalculationRequired = 'CALCULATION_REQUIRED',
  CalculationInProgress = 'CALCULATION_IN_PROGRESS',
  CalculationFailed = 'CALCULATION_FAILED'
}
