import { NavigatorCategoryDto, NavigatorFilterTreeDto } from '../../api';

export const mockCategoryOptions: NavigatorCategoryDto = {
  id: '/1',
  name: 'Industries & Applications',
  parent: null,
  children: [
    {
      id: '/1/12',
      name: 'Plastic Additives',
      parent: {
        id: '/1',
        name: 'Industries & Applications',
      },
      children: [],
    },
    {
      id: '/1/13',
      name: 'Fuel and Lubricants',
      parent: {
        id: '/1',
        name: 'Industries & Applications',
      },
      children: [],
    },
  ],
};

export const mockRootCategoryInfoResponse: NavigatorFilterTreeDto = {
  industryNavigatorFilterTree: mockCategoryOptions,
};
