/* eslint-disable @typescript-eslint/no-unused-vars */
import { delay, Observable, of } from 'rxjs';

import {
  UserBasicInfoResponseDto,
  UserExtraInfoResponseDto,
  UserInformationApiService,
} from '../../api';
import { PatchUserDto } from '../../api/models/patch-user-dto';
import { mockUserBasicInfo } from '../data';

const serverDelay = 500; // ms

export const mockUserInfoApiService: Partial<UserInformationApiService> = {
  getBasicUserInfo(): Observable<UserBasicInfoResponseDto> {
    // return of({ user: null }).pipe(delay(serverDelay));
    return of({ user: mockUserBasicInfo }).pipe(delay(serverDelay));
  },
  getUserExtraInfo(): Observable<UserExtraInfoResponseDto> {
    return of({
      salesforceAccountName: 'mock salesforce account name',
      consentManagementLink: 'https://example.com',
    }).pipe(delay(serverDelay));
  },
  patchUser(params: { body: PatchUserDto }): Observable<UserBasicInfoResponseDto> {
    // Just for testing
    localStorage.setItem('productSearchQueryString', params.body.productSearchQueryString ?? '');

    return of({ user: mockUserBasicInfo }).pipe(delay(serverDelay));
  },
};
