import { delay, Observable, of } from 'rxjs';

import { BusinessEventApiService, BusinessEventResponseDto } from '../../api';
import { mockEventsResponse } from '../data/mock-events.data';

const localDB = {
  mockEventsResponse: mockEventsResponse,
};

const serverDelay = 500; // ms

export const mockBusinessEventApiService: Partial<BusinessEventApiService> = {
  getEvents(): Observable<BusinessEventResponseDto> {
    return of(localDB.mockEventsResponse).pipe(delay(serverDelay));
  },
};
