import { AllDocumentCategoryNameEnum, DocumentListResponseDto } from '../../api/models';

export const mockSimplifiedDocumentList: DocumentListResponseDto = {
  documentsMap: {
    '8a8081597a388da1017a5bb3a23019ee': {
      id: '8a8081597a388da1017a5bb3a23019ee',
      name: 'Baso NT 600 UP PS',
      categoryName: AllDocumentCategoryNameEnum.TechnicalInformation,
      documentType: 'Product Specification',
      securityClassification: 'Restricted - Incentivize prospects to share their data',
      languages: ['en'],
      downloadUrl:
        'http://localhost:3000/api/documents/download/pim?productId=30600366&documentId=8a8081597a388da1017a5bb3a23019ee',
      accessibility: 'AVAILABLE',
    },
    '8a8081597a388da1017a5bb4216719f2': {
      id: '8a8081597a388da1017a5bb4216719f2',
      name: 'Baso NT 600 UP TDS',
      categoryName: AllDocumentCategoryNameEnum.TechnicalInformation,
      documentType: 'Technical Data Sheet',
      securityClassification: 'Restricted - Incentivize prospects to share their data',
      languages: ['en'],
      downloadUrl:
        'http://localhost:3000/api/documents/download/pim?productId=30600366&documentId=8a8081597a388da1017a5bb4216719f2',
      accessibility: 'AVAILABLE',
    },
    '8a8081a587c269810187e651e58137f8': {
      id: '8a8081a587c269810187e651e58137f8',
      name: 'PCF Statement - 30600366',
      categoryName: AllDocumentCategoryNameEnum.Sustainability,
      documentType: 'Product Carbon Footprint – Statement',
      securityClassification: 'Restricted - Contract needed',
      languages: ['en'],
      downloadUrl:
        'http://localhost:3000/api/documents/download/pim?productId=30600366&documentId=8a8081a587c269810187e651e58137f8',
      accessibility: 'LOCKED',
    },
    '8a8081be83f99e2001840a6135ce5e35': {
      id: '8a8081be83f99e2001840a6135ce5e35',
      name: 'Irgafos 168 Absence of POP Statement',
      categoryName: AllDocumentCategoryNameEnum.RegulatoryStatements,
      documentType: 'Absence of Substances - Statement',
      securityClassification: 'Restricted - Critical product information',
      languages: ['de'],
      downloadUrl:
        'http://localhost:3000/api/documents/download/pim?productId=30497213&documentId=8a8081be83f99e2001840a6135ce5e35',
      accessibility: 'AVAILABLE',
    },
  },
  languages: [
    {
      code: 'en',
      name: 'English',
      count: 3,
    },
    {
      code: 'de',
      name: 'German',
      count: 1,
    },
  ],
  categories: [
    {
      id: 'all',
      name: AllDocumentCategoryNameEnum.All,
      documentIds: [
        '8a8081597a388da1017a5bb3a23019ee',
        '8a8081597a388da1017a5bb4216719f2',
        '8a8081a587c269810187e651e58137f8',
      ],
    },
    {
      id: 'sustainability',
      name: AllDocumentCategoryNameEnum.Sustainability,
      documentIds: ['8a8081a587c269810187e651e58137f8'],
    },
    {
      id: 'technical-information',
      name: AllDocumentCategoryNameEnum.TechnicalInformation,
      documentIds: ['8a8081597a388da1017a5bb3a23019ee', '8a8081597a388da1017a5bb4216719f2'],
    },
    {
      id: 'regulatory-statements',
      name: AllDocumentCategoryNameEnum.RegulatoryStatements,
      documentIds: ['8a8081be83f99e2001840a6135ce5e35'],
    },
  ],
};

export const mockDocumentList: DocumentListResponseDto = {
  documentsMap: {
    '8a8081be83f99e2001840a5ed41d5e2f': {
      id: '8a8081be83f99e2001840a5ed41d5e2f',
      name: 'Chimassorb 2020 FDL Absence of POP Statement',
      categoryName: AllDocumentCategoryNameEnum.RegulatoryStatements,
      documentType: 'Absence of Substances - Statement',
      securityClassification: 'Restricted - Critical product information',
      languages: ['en'],
      downloadUrl:
        'http://localhost:3000/api/documents/download/pim?productId=30486994&documentId=8a8081be83f99e2001840a5ed41d5e2f',
      accessibility: 'AVAILABLE',
    },
    '8a8082b88336d11d01833c2221156c3b': {
      id: '8a8082b88336d11d01833c2221156c3b',
      name: 'Chimassorb 2020 FDL Conflict Minerals Statement',
      categoryName: AllDocumentCategoryNameEnum.RegulatoryStatements,
      documentType: 'Absence of Substances - Statement',
      securityClassification: 'Restricted - Incentivize prospects to share their data',
      languages: ['en'],
      downloadUrl:
        'http://localhost:3000/api/documents/download/pim?productId=30486994&documentId=8a8082b88336d11d01833c2221156c3b',
      accessibility: 'AVAILABLE',
    },
    '8a8082a3855dcf190185c996dd851f21': {
      id: '8a8082a3855dcf190185c996dd851f21',
      name: 'Chimassorb 2020 FDL SVHC Statement',
      categoryName: AllDocumentCategoryNameEnum.RegulatoryStatements,
      documentType: 'Absence of Substances - Statement',
      securityClassification: 'Restricted - Critical product information',
      languages: ['en'],
      downloadUrl:
        'http://localhost:3000/api/documents/download/pim?productId=30486994&documentId=8a8082a3855dcf190185c996dd851f21',
      accessibility: 'AVAILABLE',
    },
    '8a8081be811e95ef018166f69f7e7886': {
      id: '8a8081be811e95ef018166f69f7e7886',
      name: 'Chimassorb 2020 FDLAbsence of Substances',
      categoryName: AllDocumentCategoryNameEnum.RegulatoryStatements,
      documentType: 'Absence of Substances - Statement',
      securityClassification: 'Restricted - Critical product information',
      languages: ['en'],
      downloadUrl:
        'http://localhost:3000/api/documents/download/pim?productId=30486994&documentId=8a8081be811e95ef018166f69f7e7886',
      accessibility: 'AVAILABLE',
    },
    '8a8081eb811e95d20181249089207124': {
      id: '8a8081eb811e95d20181249089207124',
      name: 'Chimassorb 2020 FDL GADSL Statement',
      categoryName: AllDocumentCategoryNameEnum.RegulatoryStatements,
      documentType: 'Branch Specific Statement',
      securityClassification: 'Restricted - Critical product information',
      languages: ['en'],
      downloadUrl:
        'http://localhost:3000/api/documents/download/pim?productId=30486994&documentId=8a8081eb811e95d20181249089207124',
      accessibility: 'AVAILABLE',
    },
    '8a80820d7c024ca5017c077a8e7d6f57': {
      id: '8a80820d7c024ca5017c077a8e7d6f57',
      name: 'Chimassorb 2020 FDL Statement on Branch Specific Information',
      categoryName: AllDocumentCategoryNameEnum.RegulatoryStatements,
      documentType: 'Branch Specific Statement',
      securityClassification: 'Restricted - Critical product information',
      languages: ['en'],
      downloadUrl:
        'http://localhost:3000/api/documents/download/pim?productId=30486994&documentId=8a80820d7c024ca5017c077a8e7d6f57',
      accessibility: 'AVAILABLE',
    },
    '8a8081f88050164501808ea6d9387e95': {
      id: '8a8081f88050164501808ea6d9387e95',
      name: 'Chimassorb 2020 FDL Recommendation Food Contact Material',
      categoryName: AllDocumentCategoryNameEnum.RegulatoryStatements,
      documentType: 'Food Contact Compliance Statement',
      securityClassification: 'Restricted - Critical product information',
      languages: ['en'],
      downloadUrl:
        'http://localhost:3000/api/documents/download/pim?productId=30486994&documentId=8a8081f88050164501808ea6d9387e95',
      accessibility: 'AVAILABLE',
    },
    '8a8082047ca1c958017cefb9ea912ccd': {
      id: '8a8082047ca1c958017cefb9ea912ccd',
      name: 'BREXIT UK-REACH Customer Information',
      categoryName: AllDocumentCategoryNameEnum.RegulatoryStatements,
      documentType: 'Regulatory Statement',
      securityClassification: 'Restricted - Critical product information',
      languages: ['en'],
      downloadUrl:
        'http://localhost:3000/api/documents/download/pim?productId=30486994&documentId=8a8082047ca1c958017cefb9ea912ccd',
      accessibility: 'AVAILABLE',
    },
    '8a8081c68461274b01847ab8110f6bdc': {
      id: '8a8081c68461274b01847ab8110f6bdc',
      name: 'Chimassorb 2020 FDL REACH Compliance Statement',
      categoryName: AllDocumentCategoryNameEnum.RegulatoryStatements,
      documentType: 'Regulatory Statement',
      securityClassification: 'Restricted - Critical product information',
      languages: ['en'],
      downloadUrl:
        'http://localhost:3000/api/documents/download/pim?productId=30486994&documentId=8a8081c68461274b01847ab8110f6bdc',
      accessibility: 'AVAILABLE',
    },
    '8a8081e484ab1b290184c2d8045126ba': {
      id: '8a8081e484ab1b290184c2d8045126ba',
      name: 'Chimassorb 2020 FDL Status of Registration',
      categoryName: AllDocumentCategoryNameEnum.RegulatoryStatements,
      documentType: 'Regulatory Statement',
      securityClassification: 'Restricted - Critical product information',
      languages: ['en'],
      downloadUrl:
        'http://localhost:3000/api/documents/download/pim?productId=30486994&documentId=8a8081e484ab1b290184c2d8045126ba',
      accessibility: 'AVAILABLE',
    },
    '8a8082e382259b2d0182edd3f5254b4d': {
      id: '8a8082e382259b2d0182edd3f5254b4d',
      name: 'Korea-REACH Customer Information',
      categoryName: AllDocumentCategoryNameEnum.RegulatoryStatements,
      documentType: 'Regulatory Statement',
      securityClassification: 'Restricted - Critical product information',
      languages: ['en'],
      downloadUrl:
        'http://localhost:3000/api/documents/download/pim?productId=30486994&documentId=8a8082e382259b2d0182edd3f5254b4d',
      accessibility: 'AVAILABLE',
    },
    '00000000000097442336_000000410361_000000000030486994': {
      id: '00000000000097442336_000000410361_000000000030486994',
      name: 'Chimassorb 2020 FDL',
      categoryName: AllDocumentCategoryNameEnum.ProductSafetyInformation,
      documentType: 'Safety Data Sheet',
      securityClassification: 'Public',
      languages: ['fr'],
      downloadUrl:
        'http://localhost:3000/api/documents/download/sds?bi=&supplier=&material=000000000030486994&language=fr&salesOrg=D051&sbgv=SDS_GEN_CH',
      regionCode: 'CH',
      region: 'Switzerland',
      flagIconUrl: 'https://flagcdn.com/ch.svg',
      accessibility: 'AVAILABLE',
    },
    '00000000000097442360_000000410361_000000000030486994': {
      id: '00000000000097442360_000000410361_000000000030486994',
      name: 'Chimassorb 2020 FDL',
      categoryName: AllDocumentCategoryNameEnum.ProductSafetyInformation,
      documentType: 'Safety Data Sheet',
      securityClassification: 'Public',
      languages: ['it'],
      downloadUrl:
        'http://localhost:3000/api/documents/download/sds?bi=&supplier=&material=000000000030486994&language=it&salesOrg=D051&sbgv=SDS_GEN_CH',
      regionCode: 'CH',
      region: 'Switzerland',
      flagIconUrl: 'https://flagcdn.com/ch.svg',
      accessibility: 'AVAILABLE',
    },
    '00000000000132664116_000000410361_000000000030486994': {
      id: '00000000000132664116_000000410361_000000000030486994',
      name: 'Chimassorb 2020 FDL',
      categoryName: AllDocumentCategoryNameEnum.ProductSafetyInformation,
      documentType: 'Safety Data Sheet',
      securityClassification: 'Public',
      languages: ['es'],
      downloadUrl:
        'http://localhost:3000/api/documents/download/sds?bi=&supplier=&material=000000000030486994&language=es&salesOrg=null&sbgv=SDS_GEN_CO',
      regionCode: 'CO',
      region: 'Colombia',
      flagIconUrl: 'https://flagcdn.com/co.svg',
      accessibility: 'AVAILABLE',
    },
    '00000000000101308135_000000410361_000000000030486994': {
      id: '00000000000101308135_000000410361_000000000030486994',
      name: 'Chimassorb 2020 FDL',
      categoryName: AllDocumentCategoryNameEnum.ProductSafetyInformation,
      documentType: 'Safety Data Sheet',
      securityClassification: 'Public',
      languages: ['cs'],
      downloadUrl:
        'http://localhost:3000/api/documents/download/sds?bi=&supplier=&material=000000000030486994&language=cs&salesOrg=D051&sbgv=SDS_GEN_CZ',
      regionCode: 'CZ',
      region: 'Czechia',
      flagIconUrl: 'https://flagcdn.com/cz.svg',
      accessibility: 'AVAILABLE',
    },
    '00000000000181004908_000000410361_000000000030486994': {
      id: '00000000000181004908_000000410361_000000000030486994',
      name: 'Chimassorb 2020 FDL',
      categoryName: AllDocumentCategoryNameEnum.ProductSafetyInformation,
      documentType: 'Safety Data Sheet',
      securityClassification: 'Public',
      languages: ['da'],
      downloadUrl:
        'http://localhost:3000/api/documents/download/sds?bi=&supplier=&material=000000000030486994&language=da&salesOrg=D051&sbgv=SDS_GEN_DK',
      regionCode: 'DK',
      region: 'Denmark',
      flagIconUrl: 'https://flagcdn.com/dk.svg',
      accessibility: 'AVAILABLE',
    },
    '00000000000079234982_000000410361_000000000030486994': {
      id: '00000000000079234982_000000410361_000000000030486994',
      name: 'Chimassorb 2020 FDL',
      categoryName: AllDocumentCategoryNameEnum.ProductSafetyInformation,
      documentType: 'Safety Data Sheet',
      securityClassification: 'Public',
      languages: ['en'],
      downloadUrl:
        'http://localhost:3000/api/documents/download/sds?bi=&supplier=&material=000000000030486994&language=en&salesOrg=D051&sbgv=SDS_GEN_GB',
      regionCode: 'GB',
      region: 'United Kingdom',
      flagIconUrl: 'https://flagcdn.com/gb.svg',
      accessibility: 'AVAILABLE',
    },
    '00000000000092697179_000000410361_000000000030486994': {
      id: '00000000000092697179_000000410361_000000000030486994',
      name: 'Chimassorb 2020 FDL',
      categoryName: AllDocumentCategoryNameEnum.ProductSafetyInformation,
      documentType: 'Safety Data Sheet',
      securityClassification: 'Public',
      languages: ['en'],
      downloadUrl:
        'http://localhost:3000/api/documents/download/sds?bi=&supplier=&material=000000000030486994&language=en&salesOrg=D051&sbgv=SDS_GEN_IE',
      regionCode: 'IE',
      region: 'Ireland',
      flagIconUrl: 'https://flagcdn.com/ie.svg',
      accessibility: 'AVAILABLE',
    },
    '00000000000112657483_000000410361_000000000030486994': {
      id: '00000000000112657483_000000410361_000000000030486994',
      name: 'Chimassorb 2020 FDL',
      categoryName: AllDocumentCategoryNameEnum.ProductSafetyInformation,
      documentType: 'Safety Data Sheet',
      securityClassification: 'Public',
      languages: ['en'],
      downloadUrl:
        'http://localhost:3000/api/documents/download/sds?bi=&supplier=&material=000000000030486994&language=en&salesOrg=MX01&sbgv=SDS_GEN_MX',
      regionCode: 'MX',
      region: 'Mexico',
      flagIconUrl: 'https://flagcdn.com/mx.svg',
      accessibility: 'AVAILABLE',
    },
    '00000000000098807205_000000410361_000000000030486994': {
      id: '00000000000098807205_000000410361_000000000030486994',
      name: 'Chimassorb 2020 FDL',
      categoryName: AllDocumentCategoryNameEnum.ProductSafetyInformation,
      documentType: 'Safety Data Sheet',
      securityClassification: 'Public',
      languages: ['sh'],
      downloadUrl:
        'http://localhost:3000/api/documents/download/sds?bi=&supplier=&material=000000000030486994&language=sh&salesOrg=D051&sbgv=SDS_GEN_RS',
      regionCode: 'RS',
      region: 'Serbia',
      flagIconUrl: 'https://flagcdn.com/rs.svg',
      accessibility: 'AVAILABLE',
    },
    '00000000000084954744_000000410361_000000000030486994': {
      id: '00000000000084954744_000000410361_000000000030486994',
      name: 'Chimassorb 2020 FDL',
      categoryName: AllDocumentCategoryNameEnum.ProductSafetyInformation,
      documentType: 'Safety Data Sheet',
      securityClassification: 'Public',
      languages: ['vi'],
      downloadUrl:
        'http://localhost:3000/api/documents/download/sds?bi=&supplier=&material=000000000030486994&language=vi&salesOrg=VN01&sbgv=SDS_GEN_VN',
      regionCode: 'VN',
      region: 'Viet Nam',
      flagIconUrl: 'https://flagcdn.com/vn.svg',
      accessibility: 'UNLOCKED',
    },
    '8a8081f88050164501807105535c4e5f': {
      id: '8a8081f88050164501807105535c4e5f',
      name: 'Chimassorb 2020 TI',
      categoryName: AllDocumentCategoryNameEnum.TechnicalInformation,
      documentType: 'Technical Information',
      securityClassification: 'Restricted - Incentivize prospects to share their data',
      languages: ['en'],
      downloadUrl:
        'http://localhost:3000/api/documents/download/pim?productId=30486994&documentId=8a8081f88050164501807105535c4e5f',
      accessibility: 'PENDING',
    },
    '8a80821b7bde01a6017bdf84bf9e234f': {
      id: '8a80821b7bde01a6017bdf84bf9e234f',
      name: 'Chimassorb 2020 FDL Statement on Origin',
      categoryName: AllDocumentCategoryNameEnum.RegulatoryStatements,
      documentType: 'Absence of Substances - Statement',
      securityClassification: 'Restricted - Competitor sensitive information',
      languages: ['en'],
      downloadUrl:
        'http://localhost:3000/api/documents/download/pim?productId=30486994&documentId=8a80821b7bde01a6017bdf84bf9e234f',
      accessibility: 'LOCKED',
    },
    '8a8082e783f99de901841d5dbd317ad8': {
      id: '8a8082e783f99de901841d5dbd317ad8',
      name: 'Chimassorb 2020 FDL Food Contact Compliance Statement',
      categoryName: AllDocumentCategoryNameEnum.RegulatoryStatements,
      documentType: 'Food Contact Compliance Statement',
      securityClassification: 'Restricted - Competitor sensitive information',
      languages: ['en'],
      downloadUrl:
        'http://localhost:3000/api/documents/download/pim?productId=30486994&documentId=8a8082e783f99de901841d5dbd317ad8',
      accessibility: 'LOCKED',
    },
    '8a8082e883656fb20183d599311d3563': {
      id: '8a8082e883656fb20183d599311d3563',
      name: 'Chimassorb 2020 FDL Nano EU Statement',
      categoryName: AllDocumentCategoryNameEnum.RegulatoryStatements,
      documentType: 'Nanomaterial - Statement',
      securityClassification: 'Restricted - Competitor sensitive information',
      languages: ['en'],
      downloadUrl:
        'http://localhost:3000/api/documents/download/pim?productId=30486994&documentId=8a8082e883656fb20183d599311d3563',
      accessibility: 'LOCKED',
    },
    '8a8082a3855dcf190185f2448e2b5546': {
      id: '8a8082a3855dcf190185f2448e2b5546',
      name: 'Chimassorb 2020 FDL USP Statement',
      categoryName: AllDocumentCategoryNameEnum.RegulatoryStatements,
      documentType: 'Pharma Regulation Statement',
      securityClassification: 'Restricted - Manual controlled visibility',
      languages: ['en'],
      downloadUrl:
        'http://localhost:3000/api/documents/download/pim?productId=30486994&documentId=8a8082a3855dcf190185f2448e2b5546',
      accessibility: 'LOCKED',
    },
    '8a80812c836570040183d6a0803b02ac': {
      id: '8a80812c836570040183d6a0803b02ac',
      name: 'Product Carbon Footprint Statement',
      categoryName: AllDocumentCategoryNameEnum.RegulatoryStatements,
      documentType: 'Product Carbon Footprint – Statement',
      securityClassification: 'Restricted - Paid content',
      languages: ['en'],
      downloadUrl:
        'http://localhost:3000/api/documents/download/pim?productId=30486994&documentId=8a80812c836570040183d6a0803b02ac',
      accessibility: 'LOCKED',
    },
    '8a8082cf84f03e4d01852a612c6e432c': {
      id: '8a8082cf84f03e4d01852a612c6e432c',
      name: 'Chimassorb 2020 FDL Dust Explosion Statement',
      categoryName: AllDocumentCategoryNameEnum.RegulatoryStatements,
      documentType: 'Safety - Statement',
      securityClassification: 'Restricted - Competitor sensitive information',
      languages: ['en'],
      downloadUrl:
        'http://localhost:3000/api/documents/download/pim?productId=30486994&documentId=8a8082cf84f03e4d01852a612c6e432c',
      accessibility: 'LOCKED',
    },
    '8a808135855dcf600185e9a671522a8e': {
      id: '8a808135855dcf600185e9a671522a8e',
      name: 'BASF EcoVadis CSR Scorecard',
      categoryName: AllDocumentCategoryNameEnum.Other,
      documentType: 'Sustainability activities',
      securityClassification: 'Restricted - Customer specific information',
      languages: ['en'],
      downloadUrl:
        'http://localhost:3000/api/documents/download/pim?productId=30486994&documentId=8a808135855dcf600185e9a671522a8e',
      accessibility: 'LOCKED',
    },
  },
  languages: [
    {
      code: 'de',
      name: 'German',
      count: 5,
    },
    {
      code: 'no',
      name: 'Norwegian',
      count: 1,
    },
    {
      code: 'fi',
      name: 'Finnish',
      count: 2,
    },
    {
      code: 'ru',
      name: 'Russian',
      count: 1,
    },
    {
      code: 'pt',
      name: 'Portuguese',
      count: 1,
    },
    {
      code: 'bg',
      name: 'Bulgarian',
      count: 2,
    },
    {
      code: 'lt',
      name: 'Lithuanian',
      count: 2,
    },
    {
      code: 'lv',
      name: 'Latvian',
      count: 1,
    },
    {
      code: 'fr',
      name: 'French',
      count: 7,
    },
    {
      code: 'hu',
      name: 'Hungarian',
      count: 1,
    },
    {
      code: 'sh',
      name: 'Serbo-Croatian',
      count: 2,
    },
    {
      code: 'sl',
      name: 'Slovenian',
      count: 1,
    },
    {
      code: 'id',
      name: 'Indonesian',
      count: 1,
    },
    {
      code: 'sv',
      name: 'Swedish',
      count: 1,
    },
    {
      code: 'ko',
      name: 'Korean',
      count: 1,
    },
    {
      code: 'ms',
      name: 'Malay',
      count: 1,
    },
    {
      code: 'el',
      name: 'Greek',
      count: 1,
    },
    {
      code: 'en',
      name: 'English',
      count: 38,
    },
    {
      code: 'it',
      name: 'Italian',
      count: 3,
    },
    {
      code: 'zf',
      name: 'Chinese (Traditional)',
      count: 1,
    },
    {
      code: 'es',
      name: 'Spanish',
      count: 9,
    },
    {
      code: 'zh',
      name: 'Chinese',
      count: 2,
    },
    {
      code: 'cs',
      name: 'Czech',
      count: 2,
    },
    {
      code: 'vi',
      name: 'Vietnamese',
      count: 2,
    },
    {
      code: 'th',
      name: 'Thai',
      count: 1,
    },
    {
      code: 'ja',
      name: 'Japanese',
      count: 2,
    },
    {
      code: 'pl',
      name: 'Polish',
      count: 1,
    },
    {
      code: 'da',
      name: 'Danish',
      count: 2,
    },
    {
      code: 'ro',
      name: 'Romanian',
      count: 2,
    },
    {
      code: 'nl',
      name: 'Dutch',
      count: 2,
    },
  ],
  categories: [
    {
      id: 'all',
      name: AllDocumentCategoryNameEnum.All,
      documentIds: [
        '8a8081be83f99e2001840a5ed41d5e2f',
        '8a8082b88336d11d01833c2221156c3b',
        '8a8082a3855dcf190185c996dd851f21',
        '8a8081be811e95ef018166f69f7e7886',
        '8a8081eb811e95d20181249089207124',
        '8a80820d7c024ca5017c077a8e7d6f57',
        '8a8081f88050164501808ea6d9387e95',
        '8a8082047ca1c958017cefb9ea912ccd',
        '8a8081c68461274b01847ab8110f6bdc',
        '8a8081e484ab1b290184c2d8045126ba',
        '8a8082e382259b2d0182edd3f5254b4d',
        '00000000000097442336_000000410361_000000000030486994',
        '00000000000097442360_000000410361_000000000030486994',
        '00000000000132664116_000000410361_000000000030486994',
        '00000000000101308135_000000410361_000000000030486994',
        '00000000000181004908_000000410361_000000000030486994',
        '00000000000079234982_000000410361_000000000030486994',
        '00000000000092697179_000000410361_000000000030486994',
        '00000000000112657483_000000410361_000000000030486994',
        '00000000000098807205_000000410361_000000000030486994',
        '00000000000084954744_000000410361_000000000030486994',
        '8a8081f88050164501807105535c4e5f',
        '8a80821b7bde01a6017bdf84bf9e234f',
        '8a8082e783f99de901841d5dbd317ad8',
        '8a8082e883656fb20183d599311d3563',
        '8a8082a3855dcf190185f2448e2b5546',
        '8a80812c836570040183d6a0803b02ac',
        '8a8082cf84f03e4d01852a612c6e432c',
        '8a808135855dcf600185e9a671522a8e',
      ],
    },
    {
      id: 'technical-information',
      name: AllDocumentCategoryNameEnum.TechnicalInformation,
      documentIds: ['8a8081f88050164501807105535c4e5f'],
    },
    {
      id: 'regulatory-statements',
      name: AllDocumentCategoryNameEnum.RegulatoryStatements,
      documentIds: [
        '8a8081be83f99e2001840a5ed41d5e2f',
        '8a8082b88336d11d01833c2221156c3b',
        '8a8082a3855dcf190185c996dd851f21',
        '8a8081be811e95ef018166f69f7e7886',
        '8a8081eb811e95d20181249089207124',
        '8a80820d7c024ca5017c077a8e7d6f57',
        '8a8081f88050164501808ea6d9387e95',
        '8a8082047ca1c958017cefb9ea912ccd',
        '8a8081c68461274b01847ab8110f6bdc',
        '8a8081e484ab1b290184c2d8045126ba',
        '8a8082e382259b2d0182edd3f5254b4d',
        '8a80821b7bde01a6017bdf84bf9e234f',
        '8a8082e783f99de901841d5dbd317ad8',
        '8a8082e883656fb20183d599311d3563',
        '8a8082a3855dcf190185f2448e2b5546',
        '8a80812c836570040183d6a0803b02ac',
        '8a8082cf84f03e4d01852a612c6e432c',
      ],
    },
    {
      id: 'other',
      name: AllDocumentCategoryNameEnum.Other,
      documentIds: ['8a808135855dcf600185e9a671522a8e'],
    },
    {
      id: 'product-safety-information',
      name: AllDocumentCategoryNameEnum.ProductSafetyInformation,
      documentIds: [
        '00000000000097442336_000000410361_000000000030486994',
        '00000000000097442360_000000410361_000000000030486994',
        '00000000000132664116_000000410361_000000000030486994',
        '00000000000101308135_000000410361_000000000030486994',
        '00000000000181004908_000000410361_000000000030486994',
        '00000000000079234982_000000410361_000000000030486994',
        '00000000000092697179_000000410361_000000000030486994',
        '00000000000112657483_000000410361_000000000030486994',
        '00000000000098807205_000000410361_000000000030486994',
        '00000000000084954744_000000410361_000000000030486994',
      ],
    },
  ],
};
