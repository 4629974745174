import { OrdersResponse } from '../../api';

export const mockOrderHistory: OrdersResponse = {
  orders: [
    {
      countOfItems: 3,
      lineItems: [
        {
          articleName: 'articleName',
        },
        {
          articleName: 'articleName1',
        },
        {
          articleName: 'articleName2',
        },
      ],
      orderCreationDate: new Date().toISOString(),
      requestedDeliveryDate: new Date().toISOString(),
      orderNo: 'orderNo1',
      orderStatus: { text: 'Shipped', code: '06', openOrder: false },
      poNumber: 'poNumber',
    },
    {
      countOfItems: 1,
      lineItems: [
        {
          articleName: 'articleName',
        },
      ],
      orderCreationDate: new Date().toISOString(),
      requestedDeliveryDate: new Date().toISOString(),
      orderNo: 'orderNo2',
      orderStatus: { text: 'Shipped', code: '06', openOrder: false },
      poNumber: '',
    },
    {
      countOfItems: 1,
      lineItems: [
        {
          articleName: 'articleName',
        },
      ],
      orderCreationDate: new Date().toISOString(),
      requestedDeliveryDate: new Date().toISOString(),
      orderNo: 'orderNo2',
      orderStatus: { text: 'Shipped', code: '06', openOrder: false },
      poNumber: '',
    },
    {
      countOfItems: 1,
      lineItems: [
        {
          articleName: 'articleName',
        },
      ],
      orderCreationDate: new Date().toISOString(),
      requestedDeliveryDate: new Date().toISOString(),
      orderNo: 'orderNo2',
      orderStatus: { text: 'Shipped', code: '06', openOrder: false },
      poNumber: '',
    },
    {
      countOfItems: 1,
      lineItems: [
        {
          articleName: 'articleName',
        },
      ],
      orderCreationDate: new Date().toISOString(),
      requestedDeliveryDate: new Date().toISOString(),
      orderNo: 'orderNo2',
      orderStatus: { text: 'Shipped', code: '06', openOrder: false },
      poNumber: '',
    },
    {
      countOfItems: 1,
      lineItems: [
        {
          articleName: 'articleName',
        },
      ],
      orderCreationDate: new Date().toISOString(),
      requestedDeliveryDate: new Date().toISOString(),
      orderNo: 'orderNo2',
      orderStatus: { text: 'Shipped', code: '06', openOrder: false },
      poNumber: '',
    },
    {
      countOfItems: 1,
      lineItems: [
        {
          articleName: 'articleName',
        },
      ],
      orderCreationDate: new Date().toISOString(),
      requestedDeliveryDate: new Date().toISOString(),
      orderNo: 'orderNo2',
      orderStatus: { text: 'Shipped', code: '06', openOrder: false },
      poNumber: '',
    },
    {
      countOfItems: 1,
      lineItems: [
        {
          articleName: 'articleName',
        },
      ],
      orderCreationDate: new Date().toISOString(),
      requestedDeliveryDate: new Date().toISOString(),
      orderNo: 'orderNo2',
      orderStatus: { text: 'Shipped', code: '06', openOrder: false },
      poNumber: '',
    },
    {
      countOfItems: 1,
      lineItems: [
        {
          articleName: 'articleName',
        },
      ],
      orderCreationDate: new Date().toISOString(),
      requestedDeliveryDate: new Date().toISOString(),
      orderNo: 'orderNo2',
      orderStatus: { text: 'Shipped', code: '06', openOrder: false },
      poNumber: '',
    },
    {
      countOfItems: 1,
      lineItems: [
        {
          articleName: 'articleName',
        },
      ],
      orderCreationDate: new Date().toISOString(),
      requestedDeliveryDate: new Date().toISOString(),
      orderNo: 'orderNo2',
      orderStatus: { text: 'Shipped', code: '06', openOrder: false },
      poNumber: '',
    },
    {
      countOfItems: 3,
      lineItems: [
        {
          articleName: 'articleName',
        },
        {
          articleName: 'articleName1',
        },
        {
          articleName: 'articleName2',
        },
      ],
      orderCreationDate: new Date().toISOString(),
      requestedDeliveryDate: new Date().toISOString(),
      orderNo: 'orderNo3',
      orderStatus: { text: 'Shipped', code: '06', openOrder: false },
      poNumber: 'poNumber',
    },
    {
      countOfItems: 3,
      lineItems: [
        {
          articleName: 'articleName',
        },
        {
          articleName: 'articleName1',
        },
        {
          articleName: 'articleName2',
        },
      ],
      orderCreationDate: new Date().toISOString(),
      requestedDeliveryDate: new Date().toISOString(),
      orderNo: 'orderNo4',
      orderStatus: { text: 'Shipped', code: '06', openOrder: false },
      poNumber: 'poNumber',
    },
  ],
  pagination: {
    count: 12,
    limit: 20,
    offset: 0,
    totalCount: 12,
  },
};
