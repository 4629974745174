import { delay, Observable, of, tap } from 'rxjs';

import { AccountsApiService, SelectedLocationDto } from '../../api';
import { mockSelectedLocation } from '../data/mock-accounts.data';

const localDB = {
  selectedLocations: mockSelectedLocation,
};

const serverDelay = 500; // ms

export const mockAccountsApiService: Partial<AccountsApiService> = {
  getSelectedLocation(): Observable<SelectedLocationDto> {
    return of(localDB.selectedLocations).pipe(delay(serverDelay));
  },

  patchSelectedLocation(params: { body: SelectedLocationDto }): Observable<void> {
    return of().pipe(
      delay(serverDelay),
      tap(() => console.log(`[mockAccountsApiService::patchSelectedLocation]`, params.body)),
    );
  },
};
