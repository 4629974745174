/* eslint-disable @typescript-eslint/no-unused-vars */
import { delay, Observable, of } from 'rxjs';

import {
  ContactUsInquiryRequestBodyDto,
  ContactUsInquiryResponseDto,
  DocumentInquiryRequestBodyDto,
  DocumentInquiryResponseDto,
  InquiryHistoryItemDetailsDto,
  InquiryHistoryResponseDto,
  RequestSupportApiService,
  SupportInquiryRequestBodyDto,
  SupportInquiryResponseDto,
} from '../../api';
import { mockRequestHistory, mockRequestHistoryItem } from '../data/mock-request-history.data';

const localDB = {
  requestHistory: mockRequestHistory,
  requestHistoryItem: mockRequestHistoryItem,
};

const serverDelay = 500; // ms

export const mockRequestSupportApiService: Partial<RequestSupportApiService> = {
  getInquiryHistory(params: any): Observable<InquiryHistoryResponseDto> {
    return of(localDB.requestHistory).pipe(delay(serverDelay));
  },

  getInquiryHistoryItemDetailsById(params: {
    id: string;
  }): Observable<InquiryHistoryItemDetailsDto> {
    return of(localDB.requestHistoryItem).pipe(delay(serverDelay));
  },

  documentAccessRequest(params: {
    body: DocumentInquiryRequestBodyDto;
  }): Observable<DocumentInquiryResponseDto> {
    return of();
  },

  salesforceRequest(params: {
    body: SupportInquiryRequestBodyDto;
  }): Observable<SupportInquiryResponseDto> {
    console.log(`[MockRequestApiService::commercialRequest] body: `, params.body);
    return of({ caseIds: ['123', '1234'] });
  },

  contactUsForm(params: {
    body: ContactUsInquiryRequestBodyDto;
  }): Observable<ContactUsInquiryResponseDto> {
    console.log(`[SERVER REQUEST] /contact-us`, { params });
    return of({ caseId: '123' }).pipe(delay(serverDelay));
  },
};
