import { delay, Observable, of } from 'rxjs';

import { VersionApiService, VersionResponseDto } from '../../api';

const serverDelay = 500; // ms

export const mockVersionApiService: Partial<VersionApiService> = {
  getVersion(): Observable<VersionResponseDto> {
    return of({ hash: 'mock-1.0.0' }).pipe(delay(serverDelay));
  },
};
