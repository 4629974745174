/* tslint:disable */
/* eslint-disable */
export enum DeliveryDocumentTypeCode {
  Coa = 'COA',
  Inv = 'INV',
  Nf = 'NF',
  Sn = 'SN',
  Dl = 'DL',
  Bol = 'BOL'
}
