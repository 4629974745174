import { BusinessEventResponseDto, SbuInfoEnum } from '../../api';

export const mockEventsResponse: BusinessEventResponseDto = {
  events: [
    {
      id: '1',
      date: '2024-09-30T14:00+02:00',
      description:
        "__Join us at the Chemical Innovations Expo__, where industry *leaders, scientists, and experts* from the chemical sector gather to showcase cutting-edge technologies, research breakthroughs, and sustainable practices. This event serves as a platform for networking, collaboration, and knowledge exchange, highlighting the transformative impact of chemistry in various industries. Discover the latest advancements in chemical processes, materials, and applications, and gain valuable insights into optimizing production, ensuring safety, and minimizing environmental impact. Engage in thought-provoking discussions, attend interactive workshops, and explore exhibits featuring state-of-the-art equipment and solutions. Whether you're a professional in the chemical industry or simply curious about the advancements shaping our world, this expo offers a unique opportunity to stay at the forefront of chemical innovation. Join us and be part of the chemical revolution!",
      imageLink:
        'https://images.ctfassets.net/6v1j568pjbmy/7GWHFJmEbkqm17kOAScdJ4/b1e389e739b30d6b8b40a84410109c91/BASF_DSC_9758.jpg',
      link: 'https://www.soci.org/events/lipids-group/2023/lipids-group-agm-and-young-lipid-scientist-award-2023',
      presenter: 'Max Mustermann',
      sbu: SbuInfoEnum.Evk,
      subject: 'Far future event',
      files: [
        {
          name: 'event sample pdf1',
          link: 'https://assets.ctfassets.net/6v1j568pjbmy/4PLpyW2v6BtjLagQzc92lC/7dba98149f54574fa24fd8333d511022/000010-6533843315-INV.pdf',
        },
        {
          name: 'event sample pdf2',
          link: 'https://assets.ctfassets.net/6v1j568pjbmy/4PLpyW2v6BtjLagQzc92lC/7dba98149f54574fa24fd8333d511022/000010-6533843315-INV.pdf',
        },
        {
          name: 'event sample pdf3',
          link: 'https://assets.ctfassets.net/6v1j568pjbmy/4PLpyW2v6BtjLagQzc92lC/7dba98149f54574fa24fd8333d511022/000010-6533843315-INV.pdf',
        },
      ],
      videoLink:
        'https://videos.ctfassets.net/6v1j568pjbmy/6g7phdBlj5r9YonaxxVCv9/7e3cebfc7c1bb50e32241f69929fa1c9/Screen_Recording_2023-06-07_at_11.58.13.mov',
    },
    {
      id: '2',
      date: '2024-06-30T14:00+02:00',
      description:
        "Join us at the Chemical Innovations Expo, where industry leaders, scientists, and experts from the chemical sector gather to showcase cutting-edge technologies, research breakthroughs, and sustainable practices. This event serves as a platform for networking, collaboration, and knowledge exchange, highlighting the transformative impact of chemistry in various industries. Discover the latest advancements in chemical processes, materials, and applications, and gain valuable insights into optimizing production, ensuring safety, and minimizing environmental impact. Engage in thought-provoking discussions, attend interactive workshops, and explore exhibits featuring state-of-the-art equipment and solutions. Whether you're a professional in the chemical industry or simply curious about the advancements shaping our world, this expo offers a unique opportunity to stay at the forefront of chemical innovation. Join us and be part of the chemical revolution!",
      imageLink:
        'https://images.ctfassets.net/6v1j568pjbmy/7GWHFJmEbkqm17kOAScdJ4/b1e389e739b30d6b8b40a84410109c91/BASF_DSC_9758.jpg',
      link: 'https://www.soci.org/events/lipids-group/2023/lipids-group-agm-and-young-lipid-scientist-award-2023',
      presenter: 'Max Mustermann',
      sbu: SbuInfoEnum.Evk,
      subject: 'Chemical Innovations Expo: Exploring the Frontiers of Industrial Chemistry',
      files: [
        {
          name: 'event sample pdf1',
          link: 'https://assets.ctfassets.net/6v1j568pjbmy/4PLpyW2v6BtjLagQzc92lC/7dba98149f54574fa24fd8333d511022/000010-6533843315-INV.pdf',
        },
      ],
      videoLink:
        'https://videos.ctfassets.net/6v1j568pjbmy/6g7phdBlj5r9YonaxxVCv9/7e3cebfc7c1bb50e32241f69929fa1c9/Screen_Recording_2023-06-07_at_11.58.13.mov',
    },
    {
      id: '3',
      date: '2023-06-30T14:00+02:00',
      description:
        "Join us at the Chemical Innovations Expo, where industry leaders, scientists, and experts from the chemical sector gather to showcase cutting-edge technologies, research breakthroughs, and sustainable practices. This event serves as a platform for networking, collaboration, and knowledge exchange, highlighting the transformative impact of chemistry in various industries. Discover the latest advancements in chemical processes, materials, and applications, and gain valuable insights into optimizing production, ensuring safety, and minimizing environmental impact. Engage in thought-provoking discussions, attend interactive workshops, and explore exhibits featuring state-of-the-art equipment and solutions. Whether you're a professional in the chemical industry or simply curious about the advancements shaping our world, this expo offers a unique opportunity to stay at the forefront of chemical innovation. Join us and be part of the chemical revolution!",
      imageLink:
        'https://images.ctfassets.net/6v1j568pjbmy/7GWHFJmEbkqm17kOAScdJ4/b1e389e739b30d6b8b40a84410109c91/BASF_DSC_9758.jpg',
      link: 'https://www.soci.org/events/lipids-group/2023/lipids-group-agm-and-young-lipid-scientist-award-2023',
      presenter: 'Max Mustermann',
      sbu: SbuInfoEnum.Evk,
      subject: 'Some very special event at Ludwigshafen',
      files: [
        {
          name: 'event sample pdf1',
          link: 'https://assets.ctfassets.net/6v1j568pjbmy/4PLpyW2v6BtjLagQzc92lC/7dba98149f54574fa24fd8333d511022/000010-6533843315-INV.pdf',
        },
      ],
      videoLink:
        'https://videos.ctfassets.net/6v1j568pjbmy/6g7phdBlj5r9YonaxxVCv9/7e3cebfc7c1bb50e32241f69929fa1c9/Screen_Recording_2023-06-07_at_11.58.13.mov',
    },
    {
      id: '4',
      date: '2022-06-30T14:00+02:00',
      description:
        "Join us at the Chemical Innovations Expo, where industry leaders, scientists, and experts from the chemical sector gather to showcase cutting-edge technologies, research breakthroughs, and sustainable practices. This event serves as a platform for networking, collaboration, and knowledge exchange, highlighting the transformative impact of chemistry in various industries. Discover the latest advancements in chemical processes, materials, and applications, and gain valuable insights into optimizing production, ensuring safety, and minimizing environmental impact. Engage in thought-provoking discussions, attend interactive workshops, and explore exhibits featuring state-of-the-art equipment and solutions. Whether you're a professional in the chemical industry or simply curious about the advancements shaping our world, this expo offers a unique opportunity to stay at the forefront of chemical innovation. Join us and be part of the chemical revolution!",
      imageLink:
        'https://images.ctfassets.net/6v1j568pjbmy/7GWHFJmEbkqm17kOAScdJ4/b1e389e739b30d6b8b40a84410109c91/BASF_DSC_9758.jpg',
      link: 'https://www.soci.org/events/lipids-group/2023/lipids-group-agm-and-young-lipid-scientist-award-2023',
      presenter: 'Max Mustermann',
      sbu: SbuInfoEnum.Evk,
      subject: `BASF moves to a new office in Berlin, let's celebrate`,
      files: [
        {
          name: 'event sample pdf1',
          link: 'https://assets.ctfassets.net/6v1j568pjbmy/4PLpyW2v6BtjLagQzc92lC/7dba98149f54574fa24fd8333d511022/000010-6533843315-INV.pdf',
        },
      ],
      videoLink:
        'https://videos.ctfassets.net/6v1j568pjbmy/6g7phdBlj5r9YonaxxVCv9/7e3cebfc7c1bb50e32241f69929fa1c9/Screen_Recording_2023-06-07_at_11.58.13.mov',
    },
    {
      id: '5',
      date: '2022-01-30T14:00+02:00',
      description:
        "Join us at the Chemical Innovations Expo, where industry leaders, scientists, and experts from the chemical sector gather to showcase cutting-edge technologies, research breakthroughs, and sustainable practices. This event serves as a platform for networking, collaboration, and knowledge exchange, highlighting the transformative impact of chemistry in various industries. Discover the latest advancements in chemical processes, materials, and applications, and gain valuable insights into optimizing production, ensuring safety, and minimizing environmental impact. Engage in thought-provoking discussions, attend interactive workshops, and explore exhibits featuring state-of-the-art equipment and solutions. Whether you're a professional in the chemical industry or simply curious about the advancements shaping our world, this expo offers a unique opportunity to stay at the forefront of chemical innovation. Join us and be part of the chemical revolution!",
      imageLink:
        'https://images.ctfassets.net/6v1j568pjbmy/7GWHFJmEbkqm17kOAScdJ4/b1e389e739b30d6b8b40a84410109c91/BASF_DSC_9758.jpg',
      link: 'https://www.soci.org/events/lipids-group/2023/lipids-group-agm-and-young-lipid-scientist-award-2023',
      presenter: 'Max Mustermann',
      sbu: SbuInfoEnum.Evk,
      subject: `Very last event`,
      files: [
        {
          name: 'event sample pdf1',
          link: 'https://assets.ctfassets.net/6v1j568pjbmy/4PLpyW2v6BtjLagQzc92lC/7dba98149f54574fa24fd8333d511022/000010-6533843315-INV.pdf',
        },
      ],
      videoLink:
        'https://videos.ctfassets.net/6v1j568pjbmy/6g7phdBlj5r9YonaxxVCv9/7e3cebfc7c1bb50e32241f69929fa1c9/Screen_Recording_2023-06-07_at_11.58.13.mov',
    },
  ],
};
