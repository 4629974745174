import { DeliveryDocumentsSearchResponseDto, DeliveryDocumentTypeCode } from '../../api';

export const mockDeliveryDocuments: DeliveryDocumentsSearchResponseDto = {
  deliveryDocumentsByShipTo: [
    {
      deliveryDocuments: [
        {
          deliveryNo: '5002053521',
          deliveryItemNo: '000010',
          orderNo: '3387266769',
          orderType: 'ZTE',
          netWeight: 20300,
          weightUoM: 'KG',
          shipToId: '0000236655',
          orderHref: 'https://api.commerce.basf.com/orders/3387266769',
          deliveryHref: 'https://api.commerce.basf.com/deliveries/5002053521',
          poNumber: '4500225128',
          materialNo: '000000000050075968',
          materialShortText: 'Glissopal 2300 Bulk',
          customerArticleNo: 'PIB 23',
          packagingName: null,
          deliveryDate: '2024-03-13',
          orderDate: '2024-03-01',
          requestedDeliveryDate: '2024-03-13',
          shipmentDate: '2024-03-07',
          documentType: DeliveryDocumentTypeCode.Coa,
          language: 'IT',
          batchNos: ['00131431E0'],
          materialName: 'Glissopal® 2300 Bulk',
        },
        {
          deliveryNo: '5002041244',
          deliveryItemNo: '000010',
          orderNo: '3387167441',
          orderType: 'ZTE',
          netWeight: 27400,
          weightUoM: 'KG',
          shipToId: '0000236655',
          orderHref: 'https://api.commerce.basf.com/orders/3387167441',
          deliveryHref: 'https://api.commerce.basf.com/deliveries/5002041244',
          poNumber: '4500224230',
          materialNo: '000000000050075968',
          materialShortText: 'Glissopal 2300 Bulk',
          customerArticleNo: 'PIB 23',
          packagingName: null,
          deliveryDate: '2024-02-29',
          orderDate: '2024-01-25',
          requestedDeliveryDate: '2024-02-29',
          shipmentDate: '2024-02-22',
          documentType: DeliveryDocumentTypeCode.Coa,
          language: 'IT',
          batchNos: ['00274870J0'],
          materialName: 'Glissopal® 2300 Bulk',
        },
        {
          deliveryNo: '5002029027',
          deliveryItemNo: '000010',
          orderNo: '3387108295',
          orderType: 'ZTE',
          netWeight: 27200,
          weightUoM: 'KG',
          shipToId: '0000236655',
          orderHref: 'https://api.commerce.basf.com/orders/3387108295',
          deliveryHref: 'https://api.commerce.basf.com/deliveries/5002029027',
          poNumber: '4500222873',
          materialNo: '000000000050075968',
          materialShortText: 'Glissopal 2300 Bulk',
          customerArticleNo: 'PIB 23',
          packagingName: null,
          deliveryDate: '2024-01-12',
          orderDate: '2023-12-22',
          requestedDeliveryDate: '2024-01-09',
          shipmentDate: '2024-01-02',
          documentType: DeliveryDocumentTypeCode.Coa,
          language: 'IT',
          batchNos: ['00214940R0'],
          materialName: 'Glissopal® 2300 Bulk',
        },
        {
          deliveryNo: '5002004430',
          deliveryItemNo: '000010',
          orderNo: '3386928090',
          orderType: 'ZTE',
          netWeight: 26320,
          weightUoM: 'KG',
          shipToId: '0000236655',
          orderHref: 'https://api.commerce.basf.com/orders/3386928090',
          deliveryHref: 'https://api.commerce.basf.com/deliveries/5002004430',
          poNumber: '4500220946',
          materialNo: '000000000050075968',
          materialShortText: 'Glissopal 2300 Bulk',
          customerArticleNo: 'PIB 23',
          packagingName: null,
          deliveryDate: '2023-10-30',
          orderDate: '2023-10-12',
          requestedDeliveryDate: '2023-10-30',
          shipmentDate: '2023-10-23',
          documentType: DeliveryDocumentTypeCode.Coa,
          language: 'IT',
          batchNos: ['00379240R0'],
          materialName: 'Glissopal® 2300 Bulk',
        },
        {
          deliveryNo: '5002065282',
          deliveryItemNo: '000010',
          orderNo: '3387334102',
          orderType: 'ZTE',
          netWeight: 27940,
          weightUoM: 'KG',
          shipToId: '0000236655',
          orderHref: 'https://api.commerce.basf.com/orders/3387334102',
          deliveryHref: 'https://api.commerce.basf.com/deliveries/5002065282',
          poNumber: '4500225760',
          materialNo: '000000000050075968',
          materialShortText: 'Glissopal 2300 Bulk',
          customerArticleNo: 'PIB 23',
          packagingName: null,
          deliveryDate: '2024-04-15',
          orderDate: '2024-03-29',
          requestedDeliveryDate: '2024-04-12',
          shipmentDate: '2024-04-05',
          documentType: DeliveryDocumentTypeCode.Coa,
          language: 'IT',
          batchNos: ['00365479X0'],
          materialName: 'Glissopal® 2300 Bulk',
        },
        {
          deliveryNo: '5002020454',
          deliveryItemNo: '000010',
          orderNo: '3387041241',
          orderType: 'ZTE',
          netWeight: 27160,
          weightUoM: 'KG',
          shipToId: '0000236655',
          orderHref: 'https://api.commerce.basf.com/orders/3387041241',
          deliveryHref: 'https://api.commerce.basf.com/deliveries/5002020454',
          poNumber: '4500222138',
          materialNo: '000000000050075968',
          materialShortText: 'Glissopal 2300 Bulk',
          customerArticleNo: 'PIB 23',
          packagingName: null,
          deliveryDate: '2023-12-13',
          orderDate: '2023-11-28',
          requestedDeliveryDate: '2023-12-07',
          shipmentDate: '2023-12-01',
          documentType: DeliveryDocumentTypeCode.Coa,
          language: 'IT',
          batchNos: ['00543811U0'],
          materialName: 'Glissopal® 2300 Bulk',
        },
        {
          deliveryNo: '5002087341',
          deliveryItemNo: '000010',
          orderNo: '3387437266',
          orderType: 'ZTE',
          netWeight: 26400,
          weightUoM: 'KG',
          shipToId: '0000236655',
          orderHref: 'https://api.commerce.basf.com/orders/3387437266',
          deliveryHref: 'https://api.commerce.basf.com/deliveries/5002087341',
          poNumber: '4500226801',
          materialNo: '000000000050075968',
          materialShortText: 'Glissopal 2300 Bulk',
          customerArticleNo: 'PIB 23',
          packagingName: null,
          deliveryDate: '2024-06-21',
          orderDate: '2024-05-20',
          requestedDeliveryDate: '2024-06-21',
          shipmentDate: '2024-06-14',
          documentType: DeliveryDocumentTypeCode.Coa,
          language: 'IT',
          batchNos: ['00270536V0'],
          materialName: 'Glissopal® 2300 Bulk',
        },
        {
          deliveryNo: '5002020456',
          deliveryItemNo: '000010',
          orderNo: '3387041241',
          orderType: 'ZTE',
          netWeight: 27880,
          weightUoM: 'KG',
          shipToId: '0000236655',
          orderHref: 'https://api.commerce.basf.com/orders/3387041241',
          deliveryHref: 'https://api.commerce.basf.com/deliveries/5002020456',
          poNumber: '4500222138',
          materialNo: '000000000050075968',
          materialShortText: 'Glissopal 2300 Bulk',
          customerArticleNo: 'PIB 23',
          packagingName: null,
          deliveryDate: '2023-12-19',
          orderDate: '2023-11-28',
          requestedDeliveryDate: '2023-12-19',
          shipmentDate: '2023-12-12',
          documentType: DeliveryDocumentTypeCode.Coa,
          language: 'IT',
          batchNos: ['00895431E0'],
          materialName: 'Glissopal® 2300 Bulk',
        },
        {
          deliveryNo: '5002100715',
          deliveryItemNo: '000010',
          orderNo: '3387530976',
          orderType: 'ZTE',
          netWeight: 26420,
          weightUoM: 'KG',
          shipToId: '0000236655',
          orderHref: 'https://api.commerce.basf.com/orders/3387530976',
          deliveryHref: 'https://api.commerce.basf.com/deliveries/5002100715',
          poNumber: '4500227576',
          materialNo: '000000000050075968',
          materialShortText: 'Glissopal 2300 Bulk',
          customerArticleNo: 'PIB 23',
          packagingName: null,
          deliveryDate: '2024-07-29',
          orderDate: '2024-07-01',
          requestedDeliveryDate: '2024-07-23',
          shipmentDate: '2024-07-15',
          documentType: DeliveryDocumentTypeCode.Coa,
          language: 'IT',
          batchNos: ['00690907L0'],
          materialName: 'Glissopal® 2300 Bulk',
        },
        {
          deliveryNo: '5002097275',
          deliveryItemNo: '000010',
          orderNo: '3387514117',
          orderType: 'ZTE',
          netWeight: 28020,
          weightUoM: 'KG',
          shipToId: '0000236655',
          orderHref: 'https://api.commerce.basf.com/orders/3387514117',
          deliveryHref: 'https://api.commerce.basf.com/deliveries/5002097275',
          poNumber: '4500227475',
          materialNo: '000000000050075968',
          materialShortText: 'Glissopal 2300 Bulk',
          customerArticleNo: 'PIB 23',
          packagingName: null,
          deliveryDate: '2024-07-16',
          orderDate: '2024-06-25',
          requestedDeliveryDate: '2024-07-10',
          shipmentDate: '2024-07-03',
          documentType: DeliveryDocumentTypeCode.Coa,
          language: 'IT',
          batchNos: ['00388979X0'],
          materialName: 'Glissopal® 2300 Bulk',
        },
        {
          deliveryNo: '5002022264',
          deliveryItemNo: '000010',
          orderNo: '3387051939',
          orderType: 'ZTE',
          netWeight: 27660,
          weightUoM: 'KG',
          shipToId: '0000236655',
          orderHref: 'https://api.commerce.basf.com/orders/3387051939',
          deliveryHref: 'https://api.commerce.basf.com/deliveries/5002022264',
          poNumber: '4500222138',
          materialNo: '000000000050075756',
          materialShortText: 'Glissopal 1000 Sfuso',
          customerArticleNo: 'PIB 10',
          packagingName: null,
          deliveryDate: '2023-12-20',
          orderDate: '2023-11-30',
          requestedDeliveryDate: '2023-12-15',
          shipmentDate: '2023-12-08',
          documentType: DeliveryDocumentTypeCode.Coa,
          language: 'IT',
          batchNos: ['00797731E0'],
          materialName: 'Glissopal® 1000 Bulk',
        },
        {
          deliveryNo: '5002020455',
          deliveryItemNo: '000010',
          orderNo: '3387041241',
          orderType: 'ZTE',
          netWeight: 27980,
          weightUoM: 'KG',
          shipToId: '0000236655',
          orderHref: 'https://api.commerce.basf.com/orders/3387041241',
          deliveryHref: 'https://api.commerce.basf.com/deliveries/5002020455',
          poNumber: '4500222138',
          materialNo: '000000000050075968',
          materialShortText: 'Glissopal 2300 Bulk',
          customerArticleNo: 'PIB 23',
          packagingName: null,
          deliveryDate: '2023-12-18',
          orderDate: '2023-11-28',
          requestedDeliveryDate: '2023-12-12',
          shipmentDate: '2023-12-05',
          documentType: DeliveryDocumentTypeCode.Coa,
          language: 'IT',
          batchNos: ['00221919Y0'],
          materialName: 'Glissopal® 2300 Bulk',
        },
        {
          deliveryNo: '5002084096',
          deliveryItemNo: '000010',
          orderNo: '3387436326',
          orderType: 'ZTE',
          netWeight: 25040,
          weightUoM: 'KG',
          shipToId: '0000236655',
          orderHref: 'https://api.commerce.basf.com/orders/3387436326',
          deliveryHref: 'https://api.commerce.basf.com/deliveries/5002084096',
          poNumber: '4500226716',
          materialNo: '000000000050075968',
          materialShortText: 'Glissopal 2300 Bulk',
          customerArticleNo: 'PIB 23',
          packagingName: null,
          deliveryDate: '2024-06-03',
          orderDate: '2024-05-20',
          requestedDeliveryDate: '2024-05-30',
          shipmentDate: '2024-05-29',
          documentType: DeliveryDocumentTypeCode.Coa,
          language: 'IT',
          batchNos: ['00531049B0'],
          materialName: 'Glissopal® 2300 Bulk',
        },
        {
          deliveryNo: '5002070494',
          deliveryItemNo: '000010',
          orderNo: '3387334102',
          orderType: 'ZTE',
          netWeight: 27980,
          weightUoM: 'KG',
          shipToId: '0000236655',
          orderHref: 'https://api.commerce.basf.com/orders/3387334102',
          deliveryHref: 'https://api.commerce.basf.com/deliveries/5002070494',
          poNumber: '4500225760',
          materialNo: '000000000050075968',
          materialShortText: 'Glissopal 2300 Bulk',
          customerArticleNo: 'PIB 23',
          packagingName: null,
          deliveryDate: '2024-05-23',
          orderDate: '2024-03-29',
          requestedDeliveryDate: '2024-05-23',
          shipmentDate: '2024-05-16',
          documentType: DeliveryDocumentTypeCode.Coa,
          language: 'IT',
          batchNos: ['00904515Q0'],
          materialName: 'Glissopal® 2300 Bulk',
        },
        {
          deliveryNo: '5002053928',
          deliveryItemNo: '000010',
          orderNo: '3387266769',
          orderType: 'ZTE',
          netWeight: 27680,
          weightUoM: 'KG',
          shipToId: '0000236655',
          orderHref: 'https://api.commerce.basf.com/orders/3387266769',
          deliveryHref: 'https://api.commerce.basf.com/deliveries/5002053928',
          poNumber: '4500225128',
          materialNo: '000000000050075968',
          materialShortText: 'Glissopal 2300 Bulk',
          customerArticleNo: 'PIB 23',
          packagingName: null,
          deliveryDate: '2024-04-04',
          orderDate: '2024-03-01',
          requestedDeliveryDate: '2024-04-04',
          shipmentDate: '2024-03-21',
          documentType: DeliveryDocumentTypeCode.Coa,
          language: 'IT',
          batchNos: ['00615415Q0'],
          materialName: 'Glissopal® 2300 Bulk',
        },
        {
          deliveryNo: '5002097274',
          deliveryItemNo: '000010',
          orderNo: '3387514117',
          orderType: 'ZTE',
          netWeight: 27900,
          weightUoM: 'KG',
          shipToId: '0000236655',
          orderHref: 'https://api.commerce.basf.com/orders/3387514117',
          deliveryHref: 'https://api.commerce.basf.com/deliveries/5002097274',
          poNumber: '4500227475',
          materialNo: '000000000050075968',
          materialShortText: 'Glissopal 2300 Bulk',
          customerArticleNo: 'PIB 23',
          packagingName: null,
          deliveryDate: '2024-07-05',
          orderDate: '2024-06-25',
          requestedDeliveryDate: '2024-07-05',
          shipmentDate: '2024-06-28',
          documentType: DeliveryDocumentTypeCode.Coa,
          language: 'IT',
          batchNos: ['00593057P0'],
          materialName: 'Glissopal® 2300 Bulk',
        },
        {
          deliveryNo: '5002053927',
          deliveryItemNo: '000010',
          orderNo: '3387266769',
          orderType: 'ZTE',
          netWeight: 27120,
          weightUoM: 'KG',
          shipToId: '0000236655',
          orderHref: 'https://api.commerce.basf.com/orders/3387266769',
          deliveryHref: 'https://api.commerce.basf.com/deliveries/5002053927',
          poNumber: '4500225128',
          materialNo: '000000000050075968',
          materialShortText: 'Glissopal 2300 Bulk',
          customerArticleNo: 'PIB 23',
          packagingName: null,
          deliveryDate: '2024-03-25',
          orderDate: '2024-03-01',
          requestedDeliveryDate: '2024-03-25',
          shipmentDate: '2024-03-18',
          documentType: DeliveryDocumentTypeCode.Coa,
          language: 'IT',
          batchNos: ['00939811U0'],
          materialName: 'Glissopal® 2300 Bulk',
        },
        {
          deliveryNo: '5002041243',
          deliveryItemNo: '000010',
          orderNo: '3387167441',
          orderType: 'ZTE',
          netWeight: 26900,
          weightUoM: 'KG',
          shipToId: '0000236655',
          orderHref: 'https://api.commerce.basf.com/orders/3387167441',
          deliveryHref: 'https://api.commerce.basf.com/deliveries/5002041243',
          poNumber: '4500224230',
          materialNo: '000000000050075968',
          materialShortText: 'Glissopal 2300 Bulk',
          customerArticleNo: 'PIB 23',
          packagingName: null,
          deliveryDate: '2024-02-23',
          orderDate: '2024-01-25',
          requestedDeliveryDate: '2024-02-23',
          shipmentDate: '2024-02-16',
          documentType: DeliveryDocumentTypeCode.Coa,
          language: 'IT',
          batchNos: ['00928145D0'],
          materialName: 'Glissopal® 2300 Bulk',
        },
        {
          deliveryNo: '5002102659',
          deliveryItemNo: '000010',
          orderNo: '3387530976',
          orderType: 'ZTE',
          netWeight: 27860,
          weightUoM: 'KG',
          shipToId: '0000236655',
          orderHref: 'https://api.commerce.basf.com/orders/3387530976',
          deliveryHref: 'https://api.commerce.basf.com/deliveries/5002102659',
          poNumber: '4500227576',
          materialNo: '000000000050075756',
          materialShortText: 'Glissopal 1000 Sfuso',
          customerArticleNo: 'PIB 10',
          packagingName: null,
          deliveryDate: '2024-08-02',
          orderDate: '2024-07-01',
          requestedDeliveryDate: '2024-07-31',
          shipmentDate: '2024-07-24',
          documentType: DeliveryDocumentTypeCode.Coa,
          language: 'IT',
          batchNos: ['00813245D0'],
          materialName: 'Glissopal® 1000 Bulk',
        },
        {
          deliveryNo: '5002004428',
          deliveryItemNo: '000010',
          orderNo: '3386928090',
          orderType: 'ZTE',
          netWeight: 27660,
          weightUoM: 'KG',
          shipToId: '0000236655',
          orderHref: 'https://api.commerce.basf.com/orders/3386928090',
          deliveryHref: 'https://api.commerce.basf.com/deliveries/5002004428',
          poNumber: '4500220946',
          materialNo: '000000000050075968',
          materialShortText: 'Glissopal 2300 Bulk',
          customerArticleNo: 'PIB 23',
          packagingName: null,
          deliveryDate: '2023-10-23',
          orderDate: '2023-10-12',
          requestedDeliveryDate: '2023-10-23',
          shipmentDate: '2023-10-17',
          documentType: DeliveryDocumentTypeCode.Coa,
          language: 'IT',
          batchNos: ['00006327T0'],
          materialName: 'Glissopal® 2300 Bulk',
        },
        {
          deliveryNo: '5002100113',
          deliveryItemNo: '000010',
          orderNo: '3387530976',
          orderType: 'ZTE',
          netWeight: 26260,
          weightUoM: 'KG',
          shipToId: '0000236655',
          orderHref: 'https://api.commerce.basf.com/orders/3387530976',
          deliveryHref: 'https://api.commerce.basf.com/deliveries/5002100113',
          poNumber: '4500227576',
          materialNo: '000000000050075968',
          materialShortText: 'Glissopal 2300 Bulk',
          customerArticleNo: 'PIB 23',
          packagingName: null,
          deliveryDate: '2024-07-11',
          orderDate: '2024-07-01',
          requestedDeliveryDate: '2024-07-12',
          shipmentDate: '2024-07-05',
          documentType: DeliveryDocumentTypeCode.Coa,
          language: 'IT',
          batchNos: ['00860911U0'],
          materialName: 'Glissopal® 2300 Bulk',
        },
        {
          deliveryNo: '5002041295',
          deliveryItemNo: '000010',
          orderNo: '3387167441',
          orderType: 'ZTE',
          netWeight: 27080,
          weightUoM: 'KG',
          shipToId: '0000236655',
          orderHref: 'https://api.commerce.basf.com/orders/3387167441',
          deliveryHref: 'https://api.commerce.basf.com/deliveries/5002041295',
          poNumber: '4500224230',
          materialNo: '000000000050075756',
          materialShortText: 'Glissopal 1000 Sfuso',
          customerArticleNo: 'PIB 10',
          packagingName: null,
          deliveryDate: '2024-02-19',
          orderDate: '2024-01-25',
          requestedDeliveryDate: '2024-02-19',
          shipmentDate: '2024-02-12',
          documentType: DeliveryDocumentTypeCode.Coa,
          language: 'IT',
          batchNos: ['00362407L0'],
          materialName: 'Glissopal® 1000 Bulk',
        },
        {
          deliveryNo: '5002029026',
          deliveryItemNo: '000010',
          orderNo: '3387108295',
          orderType: 'ZTE',
          netWeight: 27140,
          weightUoM: 'KG',
          shipToId: '0000236655',
          orderHref: 'https://api.commerce.basf.com/orders/3387108295',
          deliveryHref: 'https://api.commerce.basf.com/deliveries/5002029026',
          poNumber: '4500222873',
          materialNo: '000000000050075968',
          materialShortText: 'Glissopal 2300 Bulk',
          customerArticleNo: 'PIB 23',
          packagingName: null,
          deliveryDate: '2024-01-10',
          orderDate: '2023-12-22',
          requestedDeliveryDate: '2024-01-05',
          shipmentDate: '2024-01-02',
          documentType: DeliveryDocumentTypeCode.Coa,
          language: 'IT',
          batchNos: ['00158879X0'],
          materialName: 'Glissopal® 2300 Bulk',
        },
      ],
      shipTo: {
        codeType: 'WE',
        id: '0000236655',
        name: 'DOMUS CHEMICALS S.P.A.',
        street: 'VIA MAZZINI',
        houseNo: '1',
        houseNo2: '',
        city: 'PEDRENGO',
        postal: '24066',
        regionCode: 'BG',
        countryCode: 'IT',
        countryCodeText: 'Italy',
      },
    },
    {
      deliveryDocuments: [
        {
          deliveryNo: '3538876190',
          deliveryItemNo: '000010',
          orderNo: '3387576924',
          orderType: 'ZTE',
          netWeight: 1600,
          weightUoM: 'KG',
          shipToId: '0002479192',
          orderHref: 'https://api.commerce.basf.com/orders/3387576924',
          deliveryHref: 'https://api.commerce.basf.com/deliveries/3538876190',
          poNumber: '4500228068',
          materialNo: '000000000056232724',
          materialShortText: 'Irgalube 211 200KG 1A1',
          customerArticleNo: '42100 PTP-2',
          packagingName: '200KG Steel drums',
          deliveryDate: '2024-08-06',
          orderDate: '2024-07-25',
          requestedDeliveryDate: '2024-08-06',
          shipmentDate: '2024-07-31',
          documentType: DeliveryDocumentTypeCode.Inv,
          language: null,
          batchNos: ['0028236872'],
          materialName: 'Irgalube® 211',
        },
        {
          deliveryNo: '3538876190',
          deliveryItemNo: '000010',
          orderNo: '3387576924',
          orderType: 'ZTE',
          netWeight: 1600,
          weightUoM: 'KG',
          shipToId: '0002479192',
          orderHref: 'https://api.commerce.basf.com/orders/3387576924',
          deliveryHref: 'https://api.commerce.basf.com/deliveries/3538876190',
          poNumber: '4500228068',
          materialNo: '000000000056232724',
          materialShortText: 'Irgalube 211 200KG 1A1',
          customerArticleNo: '42100 PTP-2',
          packagingName: '200KG Steel drums',
          deliveryDate: '2024-08-06',
          orderDate: '2024-07-25',
          requestedDeliveryDate: '2024-08-06',
          shipmentDate: '2024-07-31',
          documentType: DeliveryDocumentTypeCode.Coa,
          language: 'IT',
          batchNos: ['0028236872'],
          materialName: 'Irgalube® 211',
        },
        {
          deliveryNo: '3198358629',
          deliveryItemNo: '000010',
          orderNo: '3387576924',
          orderType: 'ZTE',
          netWeight: 3600,
          weightUoM: 'KG',
          shipToId: '0002479192',
          orderHref: 'https://api.commerce.basf.com/orders/3387576924',
          deliveryHref: 'https://api.commerce.basf.com/deliveries/3198358629',
          poNumber: '4500228068',
          materialNo: '000000000056388491',
          materialShortText: 'OX BF 180KG 1A1',
          customerArticleNo: 'OX BF',
          packagingName: '180kg Metal-Drum, non remov. head',
          deliveryDate: '2024-08-07',
          orderDate: '2024-07-25',
          requestedDeliveryDate: '2024-08-06',
          shipmentDate: '2024-08-05',
          documentType: DeliveryDocumentTypeCode.Coa,
          language: 'IT',
          batchNos: ['0027936156'],
          materialName: 'OX BF',
        },
        {
          deliveryNo: '3198358629',
          deliveryItemNo: '000010',
          orderNo: '3387576924',
          orderType: 'ZTE',
          netWeight: 3600,
          weightUoM: 'KG',
          shipToId: '0002479192',
          orderHref: 'https://api.commerce.basf.com/orders/3387576924',
          deliveryHref: 'https://api.commerce.basf.com/deliveries/3198358629',
          poNumber: '4500228068',
          materialNo: '000000000056388491',
          materialShortText: 'OX BF 180KG 1A1',
          customerArticleNo: 'OX BF',
          packagingName: '180kg Metal-Drum, non remov. head',
          deliveryDate: '2024-08-07',
          orderDate: '2024-07-25',
          requestedDeliveryDate: '2024-08-06',
          shipmentDate: '2024-08-05',
          documentType: DeliveryDocumentTypeCode.Inv,
          language: null,
          batchNos: ['0027936156'],
          materialName: 'OX BF',
        },
      ],
      shipTo: {
        codeType: 'WE',
        id: '0002479192',
        name: 'Dep. ENI c/o IGLOM ITALIA S.p.a.',
        street: 'VIA NOCE SUD',
        houseNo: '1',
        houseNo2: '',
        city: 'MASSA',
        postal: '54100',
        regionCode: 'MS',
        countryCode: 'IT',
        countryCodeText: 'Italy',
      },
    },
    {
      deliveryDocuments: [
        {
          deliveryNo: '3538351878',
          deliveryItemNo: '000010',
          orderNo: '3387043029',
          orderType: 'ZTE',
          netWeight: 1600,
          weightUoM: 'KG',
          shipToId: '0001614733',
          orderHref: 'https://api.commerce.basf.com/orders/3387043029',
          deliveryHref: 'https://api.commerce.basf.com/deliveries/3538351878',
          poNumber: '5500013234',
          materialNo: '000000000056248730',
          materialShortText: 'Irgalube 353 200KG 1A1',
          customerArticleNo: '50570 - BC 353',
          packagingName: '200KG Metal-Drum, non rem.hd.',
          deliveryDate: '2023-12-18',
          orderDate: '2023-11-28',
          requestedDeliveryDate: '2023-12-18',
          shipmentDate: '2023-12-12',
          documentType: DeliveryDocumentTypeCode.Inv,
          language: null,
          batchNos: ['54626916K0'],
          materialName: 'Irgalube® 353',
        },
        {
          deliveryNo: '3198203732',
          deliveryItemNo: '000010',
          orderNo: '3387413608',
          orderType: 'ZTE',
          netWeight: 20030,
          weightUoM: 'KG',
          shipToId: '0001614733',
          orderHref: 'https://api.commerce.basf.com/orders/3387413608',
          deliveryHref: 'https://api.commerce.basf.com/deliveries/3198203732',
          poNumber: '5500013360',
          materialNo: '000000000056388544',
          materialShortText: 'OX BF',
          customerArticleNo: '45870',
          packagingName: null,
          deliveryDate: '2024-06-10',
          orderDate: '2024-05-07',
          requestedDeliveryDate: '2024-06-04',
          shipmentDate: '2024-06-06',
          documentType: DeliveryDocumentTypeCode.Coa,
          language: 'IT',
          batchNos: ['0028372748'],
          materialName: 'OX BF',
        },
        {
          deliveryNo: '3538351862',
          deliveryItemNo: '000010',
          orderNo: '3387043029',
          orderType: 'ZTE',
          netWeight: 5040,
          weightUoM: 'KG',
          shipToId: '0001614733',
          orderHref: 'https://api.commerce.basf.com/orders/3387043029',
          deliveryHref: 'https://api.commerce.basf.com/deliveries/3538351862',
          poNumber: '5500013234',
          materialNo: '000000000056389180',
          materialShortText: 'Irgamet 39 180KG 1A1',
          customerArticleNo: '44880 - OC 39',
          packagingName: '180KG Steel drums',
          deliveryDate: '2023-12-19',
          orderDate: '2023-11-28',
          requestedDeliveryDate: '2023-12-19',
          shipmentDate: '2023-12-13',
          documentType: DeliveryDocumentTypeCode.Inv,
          language: null,
          batchNos: ['0027078323', '0027078325'],
          materialName: 'Irgamet® 39',
        },
        {
          deliveryNo: '3538753582',
          deliveryItemNo: '000010',
          orderNo: '3387413847',
          orderType: 'ZTE',
          netWeight: 4320,
          weightUoM: 'KG',
          shipToId: '0001614733',
          orderHref: 'https://api.commerce.basf.com/orders/3387413847',
          deliveryHref: 'https://api.commerce.basf.com/deliveries/3538753582',
          poNumber: '5500013360',
          materialNo: '000000000056389180',
          materialShortText: 'Irgamet 39 180KG 1A1',
          customerArticleNo: '44880 - OC 39',
          packagingName: '180KG Steel drums',
          deliveryDate: '2024-06-06',
          orderDate: '2024-05-07',
          requestedDeliveryDate: '2024-06-06',
          shipmentDate: '2024-06-04',
          documentType: DeliveryDocumentTypeCode.Coa,
          language: 'IT',
          batchNos: ['0028202780', '0028202781'],
          materialName: 'Irgamet® 39',
        },
        {
          deliveryNo: '3198203753',
          deliveryItemNo: '000010',
          orderNo: '3387452159',
          orderType: 'ZTE',
          netWeight: 19910,
          weightUoM: 'KG',
          shipToId: '0001614733',
          orderHref: 'https://api.commerce.basf.com/orders/3387452159',
          deliveryHref: 'https://api.commerce.basf.com/deliveries/3198203753',
          poNumber: '5500013360',
          materialNo: '000000000056388544',
          materialShortText: 'OX BF',
          customerArticleNo: '45870',
          packagingName: null,
          deliveryDate: '2024-06-20',
          orderDate: '2024-05-27',
          requestedDeliveryDate: '2024-06-19',
          shipmentDate: '2024-06-12',
          documentType: DeliveryDocumentTypeCode.Coa,
          language: 'IT',
          batchNos: ['0028372463'],
          materialName: 'OX BF',
        },
        {
          deliveryNo: '3538523718',
          deliveryItemNo: '000010',
          orderNo: '3387206791',
          orderType: 'ZTE',
          netWeight: 20010,
          weightUoM: 'KG',
          shipToId: '0001614733',
          orderHref: 'https://api.commerce.basf.com/orders/3387206791',
          deliveryHref: 'https://api.commerce.basf.com/deliveries/3538523718',
          poNumber: '5500013360',
          materialNo: '000000000056388544',
          materialShortText: 'OX BF',
          customerArticleNo: '45870',
          packagingName: null,
          deliveryDate: '2024-02-27',
          orderDate: '2024-02-08',
          requestedDeliveryDate: '2024-02-26',
          shipmentDate: '2024-02-22',
          documentType: DeliveryDocumentTypeCode.Inv,
          language: null,
          batchNos: ['0028042199'],
          materialName: 'OX BF',
        },
        {
          deliveryNo: '3538351925',
          deliveryItemNo: '000010',
          orderNo: '3387043029',
          orderType: 'ZTE',
          netWeight: 760,
          weightUoM: 'KG',
          shipToId: '0001614733',
          orderHref: 'https://api.commerce.basf.com/orders/3387043029',
          deliveryHref: 'https://api.commerce.basf.com/deliveries/3538351925',
          poNumber: '5500013234',
          materialNo: '000000000050269804',
          materialShortText: 'Synative PE 10100 190KG 1A1',
          customerArticleNo: 'FE 10',
          packagingName: '190KG Steel Drums',
          deliveryDate: '2023-12-19',
          orderDate: '2023-11-28',
          requestedDeliveryDate: '2023-12-20',
          shipmentDate: '2023-12-13',
          documentType: DeliveryDocumentTypeCode.Coa,
          language: 'IT',
          batchNos: ['24545547G0'],
          materialName: 'Synative® PE 10100',
        },
        {
          deliveryNo: '3538710146',
          deliveryItemNo: '000010',
          orderNo: '3387413847',
          orderType: 'ZTE',
          netWeight: 4800,
          weightUoM: 'KG',
          shipToId: '0001614733',
          orderHref: 'https://api.commerce.basf.com/orders/3387413847',
          deliveryHref: 'https://api.commerce.basf.com/deliveries/3538710146',
          poNumber: '5500013360',
          materialNo: '000000000056248730',
          materialShortText: 'Irgalube 353 200KG 1A1',
          customerArticleNo: '50570 - BC 353',
          packagingName: '200KG Metal-Drum, non rem.hd.',
          deliveryDate: '2024-06-06',
          orderDate: '2024-05-07',
          requestedDeliveryDate: '2024-06-06',
          shipmentDate: '2024-06-04',
          documentType: DeliveryDocumentTypeCode.Inv,
          language: null,
          batchNos: ['54786988Q0'],
          materialName: 'Irgalube® 353',
        },
        {
          deliveryNo: '3538351878',
          deliveryItemNo: '000010',
          orderNo: '3387043029',
          orderType: 'ZTE',
          netWeight: 1600,
          weightUoM: 'KG',
          shipToId: '0001614733',
          orderHref: 'https://api.commerce.basf.com/orders/3387043029',
          deliveryHref: 'https://api.commerce.basf.com/deliveries/3538351878',
          poNumber: '5500013234',
          materialNo: '000000000056248730',
          materialShortText: 'Irgalube 353 200KG 1A1',
          customerArticleNo: '50570 - BC 353',
          packagingName: '200KG Metal-Drum, non rem.hd.',
          deliveryDate: '2023-12-18',
          orderDate: '2023-11-28',
          requestedDeliveryDate: '2023-12-18',
          shipmentDate: '2023-12-12',
          documentType: DeliveryDocumentTypeCode.Coa,
          language: 'IT',
          batchNos: ['54626916K0'],
          materialName: 'Irgalube® 353',
        },
        {
          deliveryNo: '3538351862',
          deliveryItemNo: '000010',
          orderNo: '3387043029',
          orderType: 'ZTE',
          netWeight: 5040,
          weightUoM: 'KG',
          shipToId: '0001614733',
          orderHref: 'https://api.commerce.basf.com/orders/3387043029',
          deliveryHref: 'https://api.commerce.basf.com/deliveries/3538351862',
          poNumber: '5500013234',
          materialNo: '000000000056389180',
          materialShortText: 'Irgamet 39 180KG 1A1',
          customerArticleNo: '44880 - OC 39',
          packagingName: '180KG Steel drums',
          deliveryDate: '2023-12-19',
          orderDate: '2023-11-28',
          requestedDeliveryDate: '2023-12-19',
          shipmentDate: '2023-12-13',
          documentType: DeliveryDocumentTypeCode.Coa,
          language: 'IT',
          batchNos: ['0027078323', '0027078325'],
          materialName: 'Irgamet® 39',
        },
        {
          deliveryNo: '3198203753',
          deliveryItemNo: '000010',
          orderNo: '3387452159',
          orderType: 'ZTE',
          netWeight: 19910,
          weightUoM: 'KG',
          shipToId: '0001614733',
          orderHref: 'https://api.commerce.basf.com/orders/3387452159',
          deliveryHref: 'https://api.commerce.basf.com/deliveries/3198203753',
          poNumber: '5500013360',
          materialNo: '000000000056388544',
          materialShortText: 'OX BF',
          customerArticleNo: '45870',
          packagingName: null,
          deliveryDate: '2024-06-20',
          orderDate: '2024-05-27',
          requestedDeliveryDate: '2024-06-19',
          shipmentDate: '2024-06-12',
          documentType: DeliveryDocumentTypeCode.Inv,
          language: null,
          batchNos: ['0028372463'],
          materialName: 'OX BF',
        },
        {
          deliveryNo: '3538710146',
          deliveryItemNo: '000010',
          orderNo: '3387413847',
          orderType: 'ZTE',
          netWeight: 4800,
          weightUoM: 'KG',
          shipToId: '0001614733',
          orderHref: 'https://api.commerce.basf.com/orders/3387413847',
          deliveryHref: 'https://api.commerce.basf.com/deliveries/3538710146',
          poNumber: '5500013360',
          materialNo: '000000000056248730',
          materialShortText: 'Irgalube 353 200KG 1A1',
          customerArticleNo: '50570 - BC 353',
          packagingName: '200KG Metal-Drum, non rem.hd.',
          deliveryDate: '2024-06-06',
          orderDate: '2024-05-07',
          requestedDeliveryDate: '2024-06-06',
          shipmentDate: '2024-06-04',
          documentType: DeliveryDocumentTypeCode.Coa,
          language: 'IT',
          batchNos: ['54786988Q0'],
          materialName: 'Irgalube® 353',
        },
        {
          deliveryNo: '3538523718',
          deliveryItemNo: '000010',
          orderNo: '3387206791',
          orderType: 'ZTE',
          netWeight: 20010,
          weightUoM: 'KG',
          shipToId: '0001614733',
          orderHref: 'https://api.commerce.basf.com/orders/3387206791',
          deliveryHref: 'https://api.commerce.basf.com/deliveries/3538523718',
          poNumber: '5500013360',
          materialNo: '000000000056388544',
          materialShortText: 'OX BF',
          customerArticleNo: '45870',
          packagingName: null,
          deliveryDate: '2024-02-27',
          orderDate: '2024-02-08',
          requestedDeliveryDate: '2024-02-26',
          shipmentDate: '2024-02-22',
          documentType: DeliveryDocumentTypeCode.Coa,
          language: 'IT',
          batchNos: ['0028042199'],
          materialName: 'OX BF',
        },
        {
          deliveryNo: '3198203732',
          deliveryItemNo: '000010',
          orderNo: '3387413608',
          orderType: 'ZTE',
          netWeight: 20030,
          weightUoM: 'KG',
          shipToId: '0001614733',
          orderHref: 'https://api.commerce.basf.com/orders/3387413608',
          deliveryHref: 'https://api.commerce.basf.com/deliveries/3198203732',
          poNumber: '5500013360',
          materialNo: '000000000056388544',
          materialShortText: 'OX BF',
          customerArticleNo: '45870',
          packagingName: null,
          deliveryDate: '2024-06-10',
          orderDate: '2024-05-07',
          requestedDeliveryDate: '2024-06-04',
          shipmentDate: '2024-06-06',
          documentType: DeliveryDocumentTypeCode.Inv,
          language: null,
          batchNos: ['0028372748'],
          materialName: 'OX BF',
        },
        {
          deliveryNo: '3538351925',
          deliveryItemNo: '000010',
          orderNo: '3387043029',
          orderType: 'ZTE',
          netWeight: 760,
          weightUoM: 'KG',
          shipToId: '0001614733',
          orderHref: 'https://api.commerce.basf.com/orders/3387043029',
          deliveryHref: 'https://api.commerce.basf.com/deliveries/3538351925',
          poNumber: '5500013234',
          materialNo: '000000000050269804',
          materialShortText: 'Synative PE 10100 190KG 1A1',
          customerArticleNo: 'FE 10',
          packagingName: '190KG Steel Drums',
          deliveryDate: '2023-12-19',
          orderDate: '2023-11-28',
          requestedDeliveryDate: '2023-12-20',
          shipmentDate: '2023-12-13',
          documentType: DeliveryDocumentTypeCode.Inv,
          language: null,
          batchNos: ['24545547G0'],
          materialName: 'Synative® PE 10100',
        },
        {
          deliveryNo: '3538753582',
          deliveryItemNo: '000010',
          orderNo: '3387413847',
          orderType: 'ZTE',
          netWeight: 4320,
          weightUoM: 'KG',
          shipToId: '0001614733',
          orderHref: 'https://api.commerce.basf.com/orders/3387413847',
          deliveryHref: 'https://api.commerce.basf.com/deliveries/3538753582',
          poNumber: '5500013360',
          materialNo: '000000000056389180',
          materialShortText: 'Irgamet 39 180KG 1A1',
          customerArticleNo: '44880 - OC 39',
          packagingName: '180KG Steel drums',
          deliveryDate: '2024-06-06',
          orderDate: '2024-05-07',
          requestedDeliveryDate: '2024-06-06',
          shipmentDate: '2024-06-04',
          documentType: DeliveryDocumentTypeCode.Inv,
          language: null,
          batchNos: ['0028202780', '0028202781'],
          materialName: 'Irgamet® 39',
        },
      ],
      shipTo: {
        codeType: 'WE',
        id: '0001614733',
        name: 'ENI SPA - DIV REFINING & MARK. STABILIMENTO DI ROBASSOMERO',
        street: 'VIA BRUNO BUOZZI',
        houseNo: '3',
        houseNo2: '',
        city: 'ROBASSOMERO',
        postal: '10070',
        regionCode: 'TO',
        countryCode: 'IT',
        countryCodeText: 'Italy',
      },
    },
    {
      deliveryDocuments: [
        {
          deliveryNo: '3198248989',
          deliveryItemNo: '000010',
          orderNo: '3387501352',
          orderType: 'ZTE',
          netWeight: 9360,
          weightUoM: 'KG',
          shipToId: '0000143809',
          orderHref: 'https://api.commerce.basf.com/orders/3387501352',
          deliveryHref: 'https://api.commerce.basf.com/deliveries/3198248989',
          poNumber: '4500227350',
          materialNo: '000000000056388491',
          materialShortText: 'OX BF 180KG 1A1',
          customerArticleNo: '45870',
          packagingName: '180kg Metal-Drum, non remov. head',
          deliveryDate: '2024-07-02',
          orderDate: '2024-06-18',
          requestedDeliveryDate: '2024-07-02',
          shipmentDate: '2024-07-01',
          documentType: DeliveryDocumentTypeCode.Coa,
          language: 'IT',
          batchNos: ['0027936155', '0027936156'],
          materialName: 'OX BF',
        },
        {
          deliveryNo: '3538337855',
          deliveryItemNo: '000010',
          orderNo: '3387021453',
          orderType: 'ZTE',
          netWeight: 10080,
          weightUoM: 'KG',
          shipToId: '0000143809',
          orderHref: 'https://api.commerce.basf.com/orders/3387021453',
          deliveryHref: 'https://api.commerce.basf.com/deliveries/3538337855',
          poNumber: '4500221893',
          materialNo: '000000000056388491',
          materialShortText: 'OX BF 180KG 1A1',
          customerArticleNo: '45870',
          packagingName: '180kg Metal-Drum, non remov. head',
          deliveryDate: '2023-12-11',
          orderDate: '2023-11-21',
          requestedDeliveryDate: '2023-12-11',
          shipmentDate: '2023-12-07',
          documentType: DeliveryDocumentTypeCode.Inv,
          language: null,
          batchNos: ['0027492165', '0027492166'],
          materialName: 'OX BF',
        },
        {
          deliveryNo: '3198033495',
          deliveryItemNo: '000010',
          orderNo: '3387342778',
          orderType: 'ZTE',
          netWeight: 10080,
          weightUoM: 'KG',
          shipToId: '0000143809',
          orderHref: 'https://api.commerce.basf.com/orders/3387342778',
          deliveryHref: 'https://api.commerce.basf.com/deliveries/3198033495',
          poNumber: '4500225868',
          materialNo: '000000000056388491',
          materialShortText: 'OX BF 180KG 1A1',
          customerArticleNo: '45870',
          packagingName: '180kg Metal-Drum, non remov. head',
          deliveryDate: '2024-04-22',
          orderDate: '2024-04-04',
          requestedDeliveryDate: '2024-04-22',
          shipmentDate: '2024-04-19',
          documentType: DeliveryDocumentTypeCode.Inv,
          language: null,
          batchNos: ['0027891186', '0027936155'],
          materialName: 'OX BF',
        },
        {
          deliveryNo: '3538668962',
          deliveryItemNo: '000010',
          orderNo: '3387369261',
          orderType: 'ZTE',
          netWeight: 1440,
          weightUoM: 'KG',
          shipToId: '0000143809',
          orderHref: 'https://api.commerce.basf.com/orders/3387369261',
          deliveryHref: 'https://api.commerce.basf.com/deliveries/3538668962',
          poNumber: '4500226104',
          materialNo: '000000000056389180',
          materialShortText: 'Irgamet 39 180KG 1A1',
          customerArticleNo: '44880 - OC 39',
          packagingName: '180KG Steel drums',
          deliveryDate: '2024-05-02',
          orderDate: '2024-04-17',
          requestedDeliveryDate: '2024-05-02',
          shipmentDate: '2024-04-23',
          documentType: DeliveryDocumentTypeCode.Inv,
          language: null,
          batchNos: ['0027529257'],
          materialName: 'Irgamet® 39',
        },
        {
          deliveryNo: '3198248989',
          deliveryItemNo: '000010',
          orderNo: '3387501352',
          orderType: 'ZTE',
          netWeight: 9360,
          weightUoM: 'KG',
          shipToId: '0000143809',
          orderHref: 'https://api.commerce.basf.com/orders/3387501352',
          deliveryHref: 'https://api.commerce.basf.com/deliveries/3198248989',
          poNumber: '4500227350',
          materialNo: '000000000056388491',
          materialShortText: 'OX BF 180KG 1A1',
          customerArticleNo: '45870',
          packagingName: '180kg Metal-Drum, non remov. head',
          deliveryDate: '2024-07-02',
          orderDate: '2024-06-18',
          requestedDeliveryDate: '2024-07-02',
          shipmentDate: '2024-07-01',
          documentType: DeliveryDocumentTypeCode.Inv,
          language: null,
          batchNos: ['0027936155', '0027936156'],
          materialName: 'OX BF',
        },
        {
          deliveryNo: '3538668962',
          deliveryItemNo: '000010',
          orderNo: '3387369261',
          orderType: 'ZTE',
          netWeight: 1440,
          weightUoM: 'KG',
          shipToId: '0000143809',
          orderHref: 'https://api.commerce.basf.com/orders/3387369261',
          deliveryHref: 'https://api.commerce.basf.com/deliveries/3538668962',
          poNumber: '4500226104',
          materialNo: '000000000056389180',
          materialShortText: 'Irgamet 39 180KG 1A1',
          customerArticleNo: '44880 - OC 39',
          packagingName: '180KG Steel drums',
          deliveryDate: '2024-05-02',
          orderDate: '2024-04-17',
          requestedDeliveryDate: '2024-05-02',
          shipmentDate: '2024-04-23',
          documentType: DeliveryDocumentTypeCode.Coa,
          language: 'IT',
          batchNos: ['0027529257'],
          materialName: 'Irgamet® 39',
        },
        {
          deliveryNo: '3538337855',
          deliveryItemNo: '000010',
          orderNo: '3387021453',
          orderType: 'ZTE',
          netWeight: 10080,
          weightUoM: 'KG',
          shipToId: '0000143809',
          orderHref: 'https://api.commerce.basf.com/orders/3387021453',
          deliveryHref: 'https://api.commerce.basf.com/deliveries/3538337855',
          poNumber: '4500221893',
          materialNo: '000000000056388491',
          materialShortText: 'OX BF 180KG 1A1',
          customerArticleNo: '45870',
          packagingName: '180kg Metal-Drum, non remov. head',
          deliveryDate: '2023-12-11',
          orderDate: '2023-11-21',
          requestedDeliveryDate: '2023-12-11',
          shipmentDate: '2023-12-07',
          documentType: DeliveryDocumentTypeCode.Coa,
          language: 'IT',
          batchNos: ['0027492165', '0027492166'],
          materialName: 'OX BF',
        },
        {
          deliveryNo: '3198033495',
          deliveryItemNo: '000010',
          orderNo: '3387342778',
          orderType: 'ZTE',
          netWeight: 10080,
          weightUoM: 'KG',
          shipToId: '0000143809',
          orderHref: 'https://api.commerce.basf.com/orders/3387342778',
          deliveryHref: 'https://api.commerce.basf.com/deliveries/3198033495',
          poNumber: '4500225868',
          materialNo: '000000000056388491',
          materialShortText: 'OX BF 180KG 1A1',
          customerArticleNo: '45870',
          packagingName: '180kg Metal-Drum, non remov. head',
          deliveryDate: '2024-04-22',
          orderDate: '2024-04-04',
          requestedDeliveryDate: '2024-04-22',
          shipmentDate: '2024-04-19',
          documentType: DeliveryDocumentTypeCode.Coa,
          language: 'IT',
          batchNos: ['0027891186', '0027936155'],
          materialName: 'OX BF',
        },
      ],
      shipTo: {
        codeType: 'WE',
        id: '0000143809',
        name: 'ENI SPA - DIV.REFINING & MARKETING RAFFINERIA LIVORNO',
        street: 'VIA AURELIA',
        houseNo: '74',
        houseNo2: '',
        city: 'STAGNO',
        postal: '57017',
        regionCode: 'LI',
        countryCode: 'IT',
        countryCodeText: 'Italy',
      },
    },
    {
      deliveryDocuments: [
        {
          deliveryNo: '3538302558',
          deliveryItemNo: '000010',
          orderNo: '3386956344',
          orderType: 'ZTE',
          netWeight: 24780,
          weightUoM: 'KG',
          shipToId: '0000141905',
          orderHref: 'https://api.commerce.basf.com/orders/3386956344',
          deliveryHref: 'https://api.commerce.basf.com/deliveries/3538302558',
          poNumber: '5000057245',
          materialNo: '000000000050449962',
          materialShortText: 'Keroflux 6160',
          customerArticleNo: '',
          packagingName: null,
          deliveryDate: '2023-11-15',
          orderDate: '2023-10-25',
          requestedDeliveryDate: '2023-11-15',
          shipmentDate: '2023-11-13',
          documentType: DeliveryDocumentTypeCode.Coa,
          language: 'IT',
          batchNos: ['01515229U0'],
          materialName: 'Keroflux® 6160',
        },
        {
          deliveryNo: '3538302558',
          deliveryItemNo: '000010',
          orderNo: '3386956344',
          orderType: 'ZTE',
          netWeight: 24780,
          weightUoM: 'KG',
          shipToId: '0000141905',
          orderHref: 'https://api.commerce.basf.com/orders/3386956344',
          deliveryHref: 'https://api.commerce.basf.com/deliveries/3538302558',
          poNumber: '5000057245',
          materialNo: '000000000050449962',
          materialShortText: 'Keroflux 6160',
          customerArticleNo: '',
          packagingName: null,
          deliveryDate: '2023-11-15',
          orderDate: '2023-10-25',
          requestedDeliveryDate: '2023-11-15',
          shipmentDate: '2023-11-13',
          documentType: DeliveryDocumentTypeCode.Inv,
          language: null,
          batchNos: ['01515229U0'],
          materialName: 'Keroflux® 6160',
        },
      ],
      shipTo: {
        codeType: 'WE',
        id: '0000141905',
        name: 'Eni Sustainable Mobility Raffineria di Venezia',
        street: 'VIA DEI PETROLI',
        houseNo: '4',
        houseNo2: '',
        city: 'PORTO MARGHERA (VENEZIA)',
        postal: '30175',
        regionCode: 'VE',
        countryCode: 'IT',
        countryCodeText: 'Italy',
      },
    },
  ],
};
