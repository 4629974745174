import { delay, Observable, of } from 'rxjs';

import { AccountBalanceResponseDto, FinancialServicesApiService } from '../../api';
import { mockAccountBalanceResponse } from '../data';

const localDB = {
  accountBalance: mockAccountBalanceResponse,
};

const serverDelay = 500; // ms

export const mockFinancialServicesApiService: Partial<FinancialServicesApiService> = {
  getAccountBalance(params: {
    status: 'OPEN' | 'PAID' | 'ALL';

    /**
     * The period since when the account balance is requested. Only full months are supported.
     */
    periodSince: string;
  }): Observable<AccountBalanceResponseDto> {
    return of(localDB.accountBalance).pipe(delay(serverDelay));
  },

  downloadAccountBalanceExcel(params: {
    status: 'OPEN' | 'PAID' | 'ALL';

    /**
     * The period since when the account balance is requested. Only full months are supported.
     */
    periodSince: string;
  }): Observable<void> {
    return of().pipe(delay(serverDelay));
  },
};
