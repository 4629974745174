/* tslint:disable */
/* eslint-disable */

/**
 * Document Category name
 */
export enum AllDocumentCategoryNameEnum {
  TechnicalInformation = 'Technical Information',
  Certificates = 'Certificates',
  MarketingInformation = 'Marketing Information',
  Formulations = 'Formulations',
  RegulatoryStatements = 'Regulatory Statements',
  ProductSafetyInformation = 'Product Safety Information',
  Sustainability = 'Sustainability',
  Other = 'Other',
  All = 'All'
}
