/* tslint:disable */
/* eslint-disable */
export enum ShipmentDocumentName {
  DeliveryInvoice = 'Delivery Invoice',
  CertificateOfAnalysis = 'Certificate of Analysis',
  NotaFiscal = 'Nota Fiscal',
  InternationalBillOfLoading = 'International Bill of Loading',
  DeliveryNote = 'Delivery Note',
  DangerousGoodsDeclaration = 'Dangerous Goods Declaration'
}
