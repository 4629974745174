import { PermissionsEnum, SbuInfoEnum, TourState, UserBasicInfoDto } from '../../api';

export const mockUserBasicInfo: UserBasicInfoDto = {
  email: 'johndoe@mailinator.com',
  firstName: 'John',
  lastName: 'Doe',
  roles: ['SALES_USER'],
  permissions: [
    PermissionsEnum.CheckoutDeliveryTime,
    PermissionsEnum.CheckoutHeaderAdditionalNotes,
    PermissionsEnum.CheckoutHeaderInvoiceText,
    PermissionsEnum.CheckoutHeaderRemarksOrderProcessInstructions,
    PermissionsEnum.CheckoutHeaderShipmentHandlingInstructions,
    PermissionsEnum.CheckoutHeaderShipmentInstructions,
    PermissionsEnum.CheckoutUnloadingPoint,
    PermissionsEnum.Checkout,
    PermissionsEnum.FinancialOverview,
    PermissionsEnum.ManagePlantTest,
    PermissionsEnum.OrderHistory,
    PermissionsEnum.PriceDisplay,
    PermissionsEnum.TrackAndTrace,
  ],
  sbuInformation: [SbuInfoEnum.Evk],
  isInternalUser: false,
  productGroups: ['OP', '5C'],
  productSearchQueryString:
    // Just for testing:
    localStorage.getItem('productSearchQueryString') ?? '',
  tours: {
    introductionTour: TourState.Completed,
    cartTour: TourState.Completed,
  },
  salesAreaPermissions: {
    'AU-M6-01': [
      PermissionsEnum.CheckoutDeliveryTime,
      PermissionsEnum.CheckoutHeaderAdditionalNotes,
      PermissionsEnum.CheckoutHeaderInvoiceText,
      PermissionsEnum.CheckoutHeaderRemarksOrderProcessInstructions,
      PermissionsEnum.CheckoutHeaderShipmentHandlingInstructions,
      PermissionsEnum.CheckoutHeaderShipmentInstructions,
      PermissionsEnum.CheckoutUnloadingPoint,
      PermissionsEnum.Checkout,
      PermissionsEnum.FinancialOverview,
      PermissionsEnum.ManagePlantTest,
      PermissionsEnum.OrderHistory,
      PermissionsEnum.PriceDisplay,
      PermissionsEnum.TrackAndTrace,
    ],
    'AU-YH-01': [
      PermissionsEnum.CheckoutDeliveryTime,
      PermissionsEnum.CheckoutHeaderAdditionalNotes,
      PermissionsEnum.CheckoutHeaderInvoiceText,
      PermissionsEnum.CheckoutHeaderRemarksOrderProcessInstructions,
      PermissionsEnum.CheckoutHeaderShipmentHandlingInstructions,
      PermissionsEnum.CheckoutHeaderShipmentInstructions,
      PermissionsEnum.CheckoutUnloadingPoint,
      PermissionsEnum.Checkout,
      PermissionsEnum.FinancialOverview,
      PermissionsEnum.ManagePlantTest,
      PermissionsEnum.OrderHistory,
      PermissionsEnum.PriceDisplay,
      PermissionsEnum.TrackAndTrace,
    ],
  },
};
