import { ThickenerGraphResponseDto } from '../../api';

export const mockThickenerGraphResponseDto: ThickenerGraphResponseDto = {
  products: [
    {
      productId: '30535150',
      productName: 'Breox® 75 W 18000',
      thickeningEfficiencyAt40c: 0.1156619023061406,
      permanentShearStabilityIndex: null,
      thickenerPropertyData: [
        {
          baseStock: 'Water',
          baseOilGroup: '5',
          treatRate: [
            3.06122448979591, 4.08163265306122, 5.10204081632653, 6.12244897959183,
            7.14285714285714, 8.16326530612245, 9.18367346938775, 10.204081632653, 0,
            1.0204081632653, 2.04081632653061, 11.2244897959183, 12.2448979591836, 13.2653061224489,
            14.2857142857142, 15.3061224489795, 16.3265306122449, 17.3469387755102,
            18.3673469387755, 19.3877551020408, 20.4081632653061, 21.4285714285714,
            22.4489795918367, 23.469387755102, 24.4897959183673, 25.5102040816326, 26.5306122448979,
            27.5510204081632, 28.5714285714285, 29.5918367346938, 30.6122448979591,
            31.6326530612244, 32.6530612244898, 33.6734693877551, 34.6938775510204,
            35.7142857142857, 36.734693877551, 37.7551020408163, 38.7755102040816, 39.7959183673469,
            40.8163265306122, 41.8367346938775, 42.8571428571428, 43.8775510204081,
            44.8979591836734, 45.9183673469387, 46.938775510204, 47.9591836734693, 48.9795918367347,
            50,
          ],
          kinematicViscosityAt40c: [
            2.25249867826783, 2.53466752097508, 2.85218344581956, 3.20947435562581,
            3.61152283332878, 4.06393562634087, 4.57302183517416, 5.1458808966935, 1.58086643898701,
            1.77890040804875, 2.00174194588111, 5.79050158896665, 6.51587343837864,
            7.33211208263306, 8.25060033788363, 9.28414693724099, 10.4471651543348,
            11.7558738029175, 13.2285234155391, 14.8856507554578, 16.7503652110693,
            18.8486710667539, 21.2098301443546, 23.8667698724845, 26.8565424743747,
            30.2208416778442, 34.0065841531445, 38.2665637870941, 43.0601879176489,
            48.4543057960324, 54.5241408297043, 61.3543396067158, 69.0401523305695,
            77.6887611272813, 87.4207747484846, 98.3719105148861, 110.694886955534, 124.56155353661,
            140.165287179761, 157.723688990526, 177.481618803918, 199.714609865304,
            224.732711265815, 252.884811716814, 284.563504960376, 320.210564666148,
            360.323105164491, 405.460513930091, 456.252252492672, 513.406634562026,
          ],
          kinematicViscosityAt100c: [],
          viscosityIndex: [],
        },
      ],
    },
    {
      productId: '30479682',
      productName: 'Irgaflo® 1100 V',
      thickeningEfficiencyAt40c: 0.05623554393154946,
      permanentShearStabilityIndex: 31,
      thickenerPropertyData: [
        {
          baseStock: 'ExxonMobil CORE Americas 150',
          baseOilGroup: '1',
          treatRate: [
            0, 0.612244897959183, 1.22448979591836, 1.83673469387755, 2.44897959183673,
            3.06122448979591, 3.6734693877551, 4.28571428571428, 4.89795918367346, 5.51020408163265,
            6.12244897959183, 6.73469387755102, 7.3469387755102, 7.95918367346938, 8.57142857142857,
            9.18367346938775, 9.79591836734693, 10.4081632653061, 11.0204081632653,
            11.6326530612244, 12.2448979591836, 12.8571428571428, 13.469387755102, 14.0816326530612,
            14.6938775510204, 15.3061224489795, 15.9183673469387, 16.5306122448979,
            17.1428571428571, 17.7551020408163, 18.3673469387755, 18.9795918367346,
            19.5918367346938, 20.204081632653, 20.8163265306122, 21.4285714285714, 22.0408163265306,
            22.6530612244897, 23.2653061224489, 23.8775510204081, 24.4897959183673,
            25.1020408163265, 25.7142857142857, 26.3265306122449, 26.938775510204, 27.5510204081632,
            28.1632653061224, 28.7755102040816, 29.3877551020408, 30,
          ],
          kinematicViscosityAt40c: [
            29.4683251827544, 30.518848012236, 31.6068211619655, 32.7335797066763, 33.900506315476,
            35.1090329485482, 36.3606426143414, 37.6568711893993, 38.999309303068, 40.3896042893898,
            41.8294622085824, 43.3206499405805, 44.8649973532116, 46.4643995476655,
            48.1208191840126, 49.8362888896254, 51.6129137534581, 53.4528739092461,
            55.3584272107932, 57.3319120026322, 59.3757499894562, 61.4924492078433,
            63.6846071039215, 65.9549137207492, 68.3061549993232, 70.7412161972663,
            73.2630854293871, 75.8748573344586, 78.5797368727146, 81.3810432587237,
            84.2822140344674, 87.2868092876199, 90.3985160202072, 93.6211526730042,
            96.9586738112238, 100.415174977247, 103.99489771635, 107.702234781592, 111.541735524255,
            115.518111476452, 119.636242132742, 123.901180937862, 128.318161487918,
            132.892603952638, 137.630121726577, 142.536528317431, 147.617844479916,
            152.880305603963, 158.330369366293, 163.974723654771,
          ],
          kinematicViscosityAt100c: [
            5.18270989408334, 5.35235632507739, 5.5275558184919, 5.70849014356344, 5.8953470194086,
            6.0883203097824, 6.28761022421158, 6.49342352571181, 6.70597374530395, 6.92548140355227,
            7.15217423935416, 7.386287446219, 7.62806391628106, 7.87775449229978, 8.13561822790877,
            8.40192265638362, 8.67694406820733, 8.96096779772127, 9.2542885191592, 9.55721055237137,
            9.87004817855596, 10.1931259663254, 10.5267791084459, 10.8713537695993,
            11.2272074455289, 11.5947093339403, 11.9742407175437, 12.3661953596343,
            12.7709799126211, 13.1890143399284, 13.6207323517069, 14.0665818548076,
            14.5270254174847, 15.0025407493094, 15.4936211967928, 16.0007762552325,
            16.5245320973129, 17.0654321190093, 17.6240375033597, 18.200927802692, 18.7967015399086,
            19.4119768294528, 20.0473920186023, 20.703606349753, 21.3813006443828, 22.081178009402,
            22.8039645666268, 23.5504102061289, 24.3212893642459, 25.1174018270577,
          ],
          viscosityIndex: [
            105.301749332615, 108.810631608573, 112.067290980967, 115.093193786763,
            117.833933363829, 120.341311424139, 122.740328151072, 125.33753618026, 128.21691890383,
            130.954621984078, 133.178452121633, 135.520638341561, 137.769682051951,
            139.931603357393, 142.10881169878, 144.087960496724, 145.92865743093, 147.586514909555,
            149.113470397524, 150.545179521542, 152.004912077521, 153.43749929106, 154.776063295983,
            156.116111561344, 157.463578703089, 158.722162888772, 160.011384512991,
            161.315684011384, 162.62006812398, 163.91158844624, 165.207960054222, 166.369279884263,
            167.42386903873, 168.638698869481, 169.723317207222, 170.984764071732, 172.201826435513,
            173.458123946858, 174.666107975827, 175.844988681713, 177.037497207923,
            178.246051789459, 179.327849522898, 180.587410336033, 181.763256349093,
            182.871599019461, 184.035697856893, 185.08527199832, 186.136905845958, 187.251762594892,
          ],
        },
        {
          baseStock: 'SK Lubricants Yubase 4',
          baseOilGroup: '3',
          treatRate: [
            0, 0.612244897959183, 1.22448979591836, 1.83673469387755, 2.44897959183673,
            3.06122448979591, 3.6734693877551, 4.28571428571428, 4.89795918367346, 5.51020408163265,
            6.12244897959183, 6.73469387755102, 7.3469387755102, 7.95918367346938, 8.57142857142857,
            9.18367346938775, 9.79591836734693, 10.4081632653061, 11.0204081632653,
            11.6326530612244, 12.2448979591836, 12.8571428571428, 13.469387755102, 14.0816326530612,
            14.6938775510204, 15.3061224489795, 15.9183673469387, 16.5306122448979,
            17.1428571428571, 17.7551020408163, 18.3673469387755, 18.9795918367346,
            19.5918367346938, 20.204081632653, 20.8163265306122, 21.4285714285714, 22.0408163265306,
            22.6530612244897, 23.2653061224489, 23.8775510204081, 24.4897959183673,
            25.1020408163265, 25.7142857142857, 26.3265306122449, 26.938775510204, 27.5510204081632,
            28.1632653061224, 28.7755102040816, 29.3877551020408, 30,
          ],
          kinematicViscosityAt40c: [
            18.8070134804033, 19.4613747346837, 20.1385034874588, 20.8391918989983,
            21.5642596915762, 22.3145551084489, 23.0909559061987, 23.8943703816048,
            24.7257384342433, 25.5860326660575, 26.4762595191869, 27.3974604533853,
            28.3507131644049, 29.3371328447724, 30.3578734884318, 31.4141292407806, 32.507135795678,
            33.6381718410602, 34.8085605548533, 36.0196711529344, 37.272920490952, 38.5697747218786,
            39.9117510112359, 41.300419311998, 42.7374042012505, 44.2243867807526, 45.7631066436267,
            47.3553639094758, 49.0030213303098, 50.7080064697444, 52.4723139580215, 54.29800782549,
            56.1872239172762, 58.1421723919692, 60.1651403072442, 62.2584942954481,
            64.4246833322791, 66.666241601797, 68.9857914611179, 71.386046508261, 73.8698147567361,
            76.4400019205859, 79.0996148137268, 81.8517648675641, 84.6996717709977,
            87.6466672370755, 90.6961989007028, 93.8518343519653, 97.1172653097868,
            100.496311940801,
          ],
          kinematicViscosityAt100c: [
            4.26542721857268, 4.39819623314829, 4.53509791962945, 4.67626091478532,
            4.82181785943346, 4.97190552307299, 5.12666493239713, 5.28624150380595,
            5.45078518004381, 5.62045057108989, 5.79539709943418, 5.97578914987542,
            6.16179622398183, 6.35359309935965, 6.55135999387922, 6.75528273501286,
            6.96555293444376, 7.1823681681098, 7.40593216185161, 7.63645498283925, 7.87415323695735,
            8.11925027233426, 8.37197638920638, 8.63256905631491, 8.9012731340383, 9.17834110447012,
            9.46403330865853, 9.75861819123021, 10.0623725526286, 10.3755818092039, 10.698540261398,
            11.0315513702778, 11.3749280426765, 11.7289929252089, 12.0940787074404,
            12.4705284344912, 12.8586958293714, 13.2589456253495, 13.6716539086661,
            14.0972084719157, 14.5360091784272, 14.9884683379876, 15.4550110942601,
            15.9360758242607, 16.4321145502707, 16.9435933645694, 17.4709928673879,
            18.0148086184949, 18.5755516028392, 19.1537487106854,
          ],
          viscosityIndex: [
            136.428808553587, 140.303942253746, 144.295339213682, 148.200189422947,
            152.173738030843, 156.068225671634, 159.701915158605, 162.996144313208,
            165.946819936824, 168.657756973158, 170.985964040636, 172.983185939455,
            174.876279974503, 176.661684508101, 178.813939117195, 181.294227559729,
            183.334951349844, 184.925698308065, 186.683867329934, 188.333573688803,
            189.895245694045, 191.495561282969, 192.904487709478, 194.110982173643,
            195.155504736772, 196.094323328981, 196.854667226841, 197.648347182999,
            198.470900808694, 199.208503513464, 199.891765238284, 200.724188769616,
            201.321977420716, 202.09566587012, 202.943862213772, 203.591348452084, 204.295060192227,
            205.035384330156, 205.795252741285, 206.40319880117, 206.924553655691, 207.616029217898,
            208.230797445182, 208.97846597422, 209.668331545556, 210.527525043305, 211.234451916679,
            212.004783003061, 212.623789734771, 213.519689550691,
          ],
        },
        {
          baseStock: 'ExxonMobil EHC 45',
          baseOilGroup: '2',
          treatRate: [
            0, 0.612244897959183, 1.22448979591836, 1.83673469387755, 2.44897959183673,
            3.06122448979591, 3.6734693877551, 4.28571428571428, 4.89795918367346, 5.51020408163265,
            6.12244897959183, 6.73469387755102, 7.3469387755102, 7.95918367346938, 8.57142857142857,
            9.18367346938775, 9.79591836734693, 10.4081632653061, 11.0204081632653,
            11.6326530612244, 12.2448979591836, 12.8571428571428, 13.469387755102, 14.0816326530612,
            14.6938775510204, 15.3061224489795, 15.9183673469387, 16.5306122448979,
            17.1428571428571, 17.7551020408163, 18.3673469387755, 18.9795918367346,
            19.5918367346938, 20.204081632653, 20.8163265306122, 21.4285714285714, 22.0408163265306,
            22.6530612244897, 23.2653061224489, 23.8775510204081, 24.4897959183673,
            25.1020408163265, 25.7142857142857, 26.3265306122449, 26.938775510204, 27.5510204081632,
            28.1632653061224, 28.7755102040816, 29.3877551020408, 30,
          ],
          kinematicViscosityAt40c: [
            21.6228464663698, 22.3802838800394, 23.1642539445568, 23.9756860853084,
            24.8155422849838, 25.6848182240412, 26.5845444611233, 27.5157876548229,
            28.4796518282454, 29.4772796778696, 30.5098539282554, 31.5785987342086,
            32.6847811320603, 33.8297125417861, 35.0147503217421, 36.2412993778636,
            37.5108138292328, 38.8247987319905, 40.1848118636352, 41.5924655698265,
            43.0494286758796, 44.5574284652194, 46.1182527271386, 47.7337518762878,
            49.4058411464102, 51.1365028609217, 52.9277887830285, 54.7818225481676,
            56.7008021816551, 58.6870027045261, 60.7427788306561, 62.8705677583606,
            65.0728920597839, 67.3523626715011, 69.7116819898796, 72.1536470748694,
            74.6811529660211, 77.2971961146613, 80.0048779362966, 82.8074084874555,
            85.7081102713289, 88.7104221767199, 91.8179035549731, 95.0342384397169,
            98.3632399144206, 101.808854632946, 105.37516749845, 109.06640650619, 112.886947755963,
            116.84132064014,
          ],
          kinematicViscosityAt100c: [
            4.53734822335202, 4.68077699714892, 4.82873965552779, 4.98137951777428,
            5.13884443359764, 5.30128692634055, 5.46886434071605, 5.64173899521436,
            5.82007833932755, 6.0040551157441, 6.19384752767055, 6.38963941144236, 6.59162041459104,
            6.7999861795402, 7.01493853310825, 7.23668568200154, 7.46544241448708, 7.70143030844031,
            7.9448779459694, 8.19602113482402, 8.45510313680289, 8.72237490338158, 8.99809531878859,
            9.28253145076527, 9.57595880925239, 9.87866161325407, 10.1909330661373,
            10.5130756396343, 10.8454013668214, 11.1882321443607, 11.5419000442944,
            11.9067476356959, 12.2831283164882, 12.6714066557518, 13.0719587468527,
            13.4851725717329, 13.9114483767171, 14.3511990601984, 14.8048505725786,
            15.2728423288522, 15.755627634231, 16.2536741232238, 16.7674642125959, 17.2974955686463,
            17.8442815892562, 18.4083519011758, 18.9902528730295, 19.5905481445394,
            20.2098191724757, 20.8486657938673,
          ],
          viscosityIndex: [
            125.593365975309, 129.593652773808, 133.668514072367, 137.645219884998,
            141.387642964641, 144.804704196368, 147.900178306578, 150.76731721766, 153.27588768467,
            155.458934597443, 157.581947313496, 159.627326203593, 162.048176425033,
            164.769529710269, 166.828060776009, 168.682583870354, 170.633809194786,
            172.469282288119, 174.291279294476, 176.04901270946, 177.571357646553, 179.002499813264,
            180.221078619814, 181.294170527223, 182.301982051982, 183.356609049208,
            184.379133547346, 185.304651923828, 186.235613307543, 187.214405679181,
            188.059377007979, 188.993816922813, 189.96439243645, 190.901957289276, 191.89681434462,
            192.740307792224, 193.595820030137, 194.305742237782, 195.154616492296,
            195.945284209957, 196.843408888419, 197.710466150043, 198.613848525873,
            199.530966360014, 200.445957062064, 201.230204361766, 202.24675301876, 203.110834605284,
            203.913682183263, 204.842071857504,
          ],
        },
      ],
    },
  ],
};
