/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { OrderDetailsResponse } from '../models/order-details-response';
import { OrdersRequestBody } from '../models/orders-request-body';
import { OrdersResponse } from '../models/orders-response';
import { SortByFieldEnum } from '../models/sort-by-field-enum';
import { SortOrderEnum } from '../models/sort-order-enum';

@Injectable({
  providedIn: 'root',
})
export class OrdersApiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getOrdersList
   */
  static readonly GetOrdersListPath = '/api/orders';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrdersList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getOrdersList$Response(params?: {

    /**
     * Number of items to skip in pagination
     */
    offset?: number;

    /**
     * Maximum number of items to return in pagination
     */
    limit?: number;

    /**
     * Field name to be sorted
     */
    sortByField?: SortByFieldEnum;

    /**
     * Sort order; ASC - ascending, DESC - descending
     */
    sortOrder?: SortOrderEnum;
    body?: OrdersRequestBody
  }): Observable<StrictHttpResponse<OrdersResponse>> {

    const rb = new RequestBuilder(this.rootUrl, OrdersApiService.GetOrdersListPath, 'post');
    if (params) {
      rb.query('offset', params.offset, {});
      rb.query('limit', params.limit, {});
      rb.query('sortByField', params.sortByField, {});
      rb.query('sortOrder', params.sortOrder, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OrdersResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOrdersList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getOrdersList(params?: {

    /**
     * Number of items to skip in pagination
     */
    offset?: number;

    /**
     * Maximum number of items to return in pagination
     */
    limit?: number;

    /**
     * Field name to be sorted
     */
    sortByField?: SortByFieldEnum;

    /**
     * Sort order; ASC - ascending, DESC - descending
     */
    sortOrder?: SortOrderEnum;
    body?: OrdersRequestBody
  }): Observable<OrdersResponse> {

    return this.getOrdersList$Response(params).pipe(
      map((r: StrictHttpResponse<OrdersResponse>) => r.body as OrdersResponse)
    );
  }

  /**
   * Path part for operation getOrderDetails
   */
  static readonly GetOrderDetailsPath = '/api/orders/{orderNo}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrderDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrderDetails$Response(params: {
    orderNo: string;
  }): Observable<StrictHttpResponse<OrderDetailsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, OrdersApiService.GetOrderDetailsPath, 'get');
    if (params) {
      rb.path('orderNo', params.orderNo, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OrderDetailsResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOrderDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrderDetails(params: {
    orderNo: string;
  }): Observable<OrderDetailsResponse> {

    return this.getOrderDetails$Response(params).pipe(
      map((r: StrictHttpResponse<OrderDetailsResponse>) => r.body as OrderDetailsResponse)
    );
  }

  /**
   * Path part for operation downlaodAllOrderDocuments
   */
  static readonly DownlaodAllOrderDocumentsPath = '/api/orders/{orderNo}/documents';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downlaodAllOrderDocuments()` instead.
   *
   * This method doesn't expect any request body.
   */
  downlaodAllOrderDocuments$Response(params: {
    orderNo: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, OrdersApiService.DownlaodAllOrderDocumentsPath, 'get');
    if (params) {
      rb.path('orderNo', params.orderNo, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `downlaodAllOrderDocuments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downlaodAllOrderDocuments(params: {
    orderNo: string;
  }): Observable<void> {

    return this.downlaodAllOrderDocuments$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
