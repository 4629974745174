/* eslint-disable @typescript-eslint/no-unused-vars */
import { delay, Observable, of } from 'rxjs';

import { DocumentListResponseDto, DocumentsApiService } from '../../api';
import { mockCategoryOptions } from '../data';
import { mockProductDetails } from '../data/mock-product-details.data';
import { mockDocumentList } from '../data/mock-product-documents.data';

const localDB = {
  documentList: mockDocumentList,
  productDetails: mockProductDetails,
  categoryOptions: mockCategoryOptions,
};

const serverDelay = 500; // ms

export const mockDocumentsApiService: Partial<DocumentsApiService> = {
  getDocumentList(params: { productId: string }): Observable<DocumentListResponseDto> {
    return of(localDB.documentList).pipe(delay(serverDelay));
  },
};
