import { ArticlePricesDto } from '../../api';

export const mockArticlePrices = {
  defaultCurrency: 'EUR',
  prices: [
    {
      pricePerPackaging: 50.25,
      formattedPricePerPackaging: '50.25',
      unitPrice: 10.05,
      formattedUnitPrice: '10.05',
      unitQuantity: 1,
      unitOfMeasure: 'KGM',
      currency: 'EUR',
    },
    {
      pricePerPackaging: 51.4,
      formattedPricePerPackaging: '51.4',
      unitPrice: 10.28,
      formattedUnitPrice: '10.28',
      unitQuantity: 1,
      unitOfMeasure: 'KGM',
      currency: 'USD',
    },
  ],
};

export const mockArticlePricesMap = {
  '50023213': mockArticlePrices,
  '50023214': { ...mockArticlePrices, defaultCurrency: 'USD' },
} as { [articleId: string]: ArticlePricesDto };
