/* tslint:disable */
/* eslint-disable */
export enum SbuInfoEnum {
  Evo = 'EVO',
  Evg = 'EVG',
  Evh = 'EVH',
  Evj = 'EVJ',
  Evk = 'EVK',
  Eva = 'EVA',
  Evf = 'EVF'
}
