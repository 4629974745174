import { Provider } from '@nestjs/common';

import { ApiConfiguration } from './api/api-configuration';
import {
  AccountsApiService,
  AnnouncementApiService,
  AuthenticationApiService,
  BusinessEventApiService,
  DeliveriesApiService,
  DocumentsApiService,
  FinancialServicesApiService,
  OrdersApiService,
  ProductsApiService,
  PurchaseApiService,
  RequestSupportApiService,
  ThickenerExplorerApiService,
  UserInformationApiService,
  VersionApiService,
} from './api/services';
import {
  mockAccountsApiService,
  mockAnnouncementApiService,
  mockAuthenticationApiService,
  mockBusinessEventApiService,
  mockDeliveriesApiService,
  mockDocumentsApiService,
  mockFinancialServicesApiService,
  mockOrderHistoryApiService,
  mockProductsApiService,
  mockPurchaseApiService,
  mockRequestSupportApiService,
  mockThickenerExplorerApiService,
  mockUserInfoApiService,
  mockVersionApiService,
} from './mock';

export function getDefaultApiProviders(rootUrl = ''): Provider[] {
  return [
    {
      provide: ApiConfiguration,
      useValue: { rootUrl },
    },
  ];
}

export function getMockApiProviders(): Provider[] {
  return [
    {
      provide: FinancialServicesApiService,
      useValue: mockFinancialServicesApiService,
    },
    {
      provide: AuthenticationApiService,
      useValue: mockAuthenticationApiService,
    },
    {
      provide: OrdersApiService,
      useValue: mockOrderHistoryApiService,
    },
    {
      provide: DeliveriesApiService,
      useValue: mockDeliveriesApiService,
    },
    {
      provide: AnnouncementApiService,
      useValue: mockAnnouncementApiService,
    },
    {
      provide: VersionApiService,
      useValue: mockVersionApiService,
    },
    {
      provide: BusinessEventApiService,
      useValue: mockBusinessEventApiService,
    },
    {
      provide: RequestSupportApiService,
      useValue: mockRequestSupportApiService,
    },
    {
      provide: ProductsApiService,
      useValue: mockProductsApiService,
    },
    {
      provide: UserInformationApiService,
      useValue: mockUserInfoApiService,
    },
    {
      provide: AccountsApiService,
      useValue: mockAccountsApiService,
    },
    {
      provide: PurchaseApiService,
      useValue: mockPurchaseApiService,
    },
    {
      provide: DocumentsApiService,
      useValue: mockDocumentsApiService,
    },
    {
      provide: ThickenerExplorerApiService,
      useValue: mockThickenerExplorerApiService,
    },
  ];
}

export function getApiServiceProviders(apiSettings: ApiSettings): Provider[] {
  const { apiBaseUrl, useMockData } = apiSettings;

  let apiSetupProvider = getDefaultApiProviders(apiBaseUrl);

  if (useMockData) {
    apiSetupProvider = getMockApiProviders();
  }

  return apiSetupProvider;
}

interface ApiSettings {
  apiBaseUrl?: string;
  useMockData?: boolean;
}
