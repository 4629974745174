/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Contract } from '../models/contract';
import { ContractsResponse } from '../models/contracts-response';

@Injectable({
  providedIn: 'root',
})
export class ContractsApiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getContractById
   */
  static readonly GetContractByIdPath = '/api/contracts/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getContractById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getContractById$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<Contract>> {

    const rb = new RequestBuilder(this.rootUrl, ContractsApiService.GetContractByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Contract>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getContractById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getContractById(params: {
    id: string;
  }): Observable<Contract> {

    return this.getContractById$Response(params).pipe(
      map((r: StrictHttpResponse<Contract>) => r.body as Contract)
    );
  }

  /**
   * Path part for operation getActiveContracts
   */
  static readonly GetActiveContractsPath = '/api/contracts/active';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getActiveContracts()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActiveContracts$Response(params?: {
  }): Observable<StrictHttpResponse<ContractsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ContractsApiService.GetActiveContractsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ContractsResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getActiveContracts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActiveContracts(params?: {
  }): Observable<ContractsResponse> {

    return this.getActiveContracts$Response(params).pipe(
      map((r: StrictHttpResponse<ContractsResponse>) => r.body as ContractsResponse)
    );
  }

  /**
   * Path part for operation getContractsHistory
   */
  static readonly GetContractsHistoryPath = '/api/contracts/history';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getContractsHistory()` instead.
   *
   * This method doesn't expect any request body.
   */
  getContractsHistory$Response(params?: {
  }): Observable<StrictHttpResponse<ContractsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ContractsApiService.GetContractsHistoryPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ContractsResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getContractsHistory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getContractsHistory(params?: {
  }): Observable<ContractsResponse> {

    return this.getContractsHistory$Response(params).pipe(
      map((r: StrictHttpResponse<ContractsResponse>) => r.body as ContractsResponse)
    );
  }

}
