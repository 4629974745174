import { QuestionnaireDto } from '../../api/models';

export const mockQuestionnaireData: QuestionnaireDto = {
  id: '334fb2c1-7dff-47c7-bbad-942be1c2d701',
  label: 'What lubricant application are you interested in?',
  options: [
    {
      id: '745adc0d-e39e-4dae-9955-c924648d89ff',
      value: 'Gear Oil',
      label: 'What type of gear oil?',
      options: [
        {
          id: 'dfda0c6c-5b0e-4b77-8345-03b368d34127',
          value: 'Transportation',
          description:
            'Based on your choices, we recommend our GLISSOPAL® V 190, V 230, V 500, V 640, V 700, or V 1500 for their superior shear stability, chemical purity, and thickening efficiency. To learn more about our products, contact our representatives today or browse our data below.',
          products: [
            { id: '30060711', name: 'Glissopal® V 190' },
            { id: '30060715', name: 'Glissopal® V 1500' },
            { id: '30613982', name: 'Glissopal® V 230' },
            { id: '30613981', name: 'Glissopal® V 640' },
            { id: '30613984', name: 'Glissopal® V 500' },
            { id: '30613983', name: 'Glissopal® V 700' },
          ],
        },
        {
          id: '94c1e5de-779c-44b7-a69f-6b117e5b519b',
          value: 'Industrial',
          label: 'What is your low viscosity base stock?',
          options: [
            {
              id: 'abf69d44-48ce-47f0-8a57-6c115e0f4df1',
              value: 'Mineral Oil or PAO',
              description:
                'Based on your choices, we recommend our IRGAFLO® 1100V or GLISSOPAL® V 190, V 230, V 500, V 640, V 700, V 1500 products for their high shear stability, chemical purity, and thickening efficiency. To learn more about our products, contact our representatives today or browse our data below.',
              products: [
                { id: '30060711', name: 'Glissopal® V 190' },
                { id: '30060715', name: 'Glissopal® V 1500' },
                { id: '30613982', name: 'Glissopal® V 230' },
                { id: '30613981', name: 'Glissopal® V 640' },
                { id: '30613984', name: 'Glissopal® V 500' },
                { id: '30613983', name: 'Glissopal® V 700' },
                { id: '30479682', name: 'Irgaflo® 1100 V' },
              ],
            },
            {
              id: '719081ab-c57e-4c12-ac8c-339b6414e79f',
              value: 'Ester',
              description:
                'Based on your choices, we recommend our Synative® ES esters. To learn more about our products, contact our representatives today or browse our data below.',
              products: [
                { id: '30535735', name: 'Synative® ES 1200' },
                { id: '30731578', name: 'Synative® ES 3200' },
                { id: '30530081', name: 'Synative® ES 3345' },
                { id: '30726456', name: 'Synative® ES 3357' },
              ],
            },
          ],
        },
      ],
    },
    {
      id: '610d84f6-d368-4382-a38a-a865899c27dd',
      value: 'Hydraulic Fluid',
      label: 'What is your low viscosity base stock?',
      options: [
        {
          id: '69273564-cda6-403c-9e8e-f15e3034b7e2',
          value: 'Mineral Oil or PAO',
          description:
            'Based on your choices, we recommend our GLISSOPAL® and IRGAFLO® V products for their thickening efficiency and solubility in mineral oil/PAO. Additionally, GLISSOPAL® products are favored for their superior shear stability, while IRGAFLO® V products have great VI improvement. To learn more about our products, contact our representatives today or browse our data below.',
          products: [
            { id: '30060711', name: 'Glissopal® V 190' },
            { id: '30060715', name: 'Glissopal® V 1500' },
            { id: '30613982', name: 'Glissopal® V 230' },
            { id: '30613981', name: 'Glissopal® V 640' },
            { id: '30613984', name: 'Glissopal® V 500' },
            { id: '30613983', name: 'Glissopal® V 700' },
            { id: '30479682', name: 'Irgaflo® 1100 V' },
            { id: '30782126', name: 'Irgaflo® 3500 V' },
            { id: '30644937', name: 'Irgaflo® 6000 V' },
            { id: '30480069', name: 'Irgaflo® 6100 V' },
            { id: '30485389', name: 'Irgaflo® 6300 V' },
            { id: '30686416', name: 'Irgaflo® 6305 V' },
          ],
        },
        {
          id: '02dca370-f011-4d69-b5a2-35c67ea979e6',
          value: 'Ester',
          description:
            'Based on your choices, we recommend our IRGAFLO® V or SYNATIVE® ES 1200, 3200, 3345, 3357 for their solubility in ester base stocks and viscosity range. IRGAFLO® V products also have superior thickening efficiency and VI Improvement. To learn more about our products, contact our representatives today or browse our data below.',
          products: [
            { id: '30686416', name: 'Irgaflo® 6305 V' },
            { id: '30485389', name: 'Irgaflo® 6300 V' },
            { id: '30480069', name: 'Irgaflo® 6100 V' },
            { id: '30644937', name: 'Irgaflo® 6000 V' },
            { id: '30782126', name: 'Irgaflo® 3500 V' },
            { id: '30479682', name: 'Irgaflo® 1100 V' },
            { id: '30535735', name: 'Synative® ES 1200' },
            { id: '30731578', name: 'Synative® ES 3200' },
            { id: '30530081', name: 'Synative® ES 3345' },
            { id: '30726456', name: 'Synative® ES 3357' },
            { id: '30530035', name: 'Synative® ES TMP05/1000' },
          ],
        },
        {
          id: '801dd5f5-6ce1-4e53-b24f-91f339ae0623',
          value: 'PAG',
          description:
            'Based on your choices, we recommend our water-insoluble base stocks based on polyalkylene glycol (PAG) chemistry – BASF Breox B or Plurasafe WI series products. There are many viscosity grades of these BASF PAG base stocks available and they can be directly utilized without the need for thickening. Product information is available on our PAG webpage or upon request',
          products: [],
        },
        {
          id: '1b34fe2a-7667-4b22-8afb-4cf7775a3758',
          value: 'Water',
          description:
            'Based on your choices, we recommend some of our polyalkylene glycol (PAG) productsIds: Pluracol V-1075 (75% Pluracol V-10 in water) and Breox TB 120 (60% Breox 75W 55000 in water) for their excellent thickening efficiency, water solubility, and fire resistance. To learn more about our products, contact our representatives today or browse our data below.',
          products: [
            { id: '30088787', name: 'Pluracol® V 10' },
            { id: '30535146', name: 'Breox® 75 W 55000' },
          ],
        },
      ],
    },
    {
      id: 'b960fd0c-0838-4258-b6d6-cbff7f4b2b40',
      value: 'Engine Oil',
      label: 'What engine type?',
      options: [
        {
          id: '2705c14d-7704-49d3-8d98-a9fa81b954e3',
          value: '2 Stroke',
          description:
            'Based on your choices, we recommend our GLISSOPAL® V 190 for its oxidative stability, shear stability, and cold flow. To learn more about our products, contact our representatives today or browse our data below.',
          products: [{ id: '30060711', name: 'Glissopal® V 190' }],
        },
        {
          id: 'e75549b0-209c-49b8-b94c-9b50c77e3a0e',
          value: '4 Stroke',
          description:
            'Based on your choices, we recommend our Irgaflo 3500V for its thermal and oxidative stability and VI Improvement. Product information is available upon request.',
          products: [{ id: '30782126', name: 'Irgaflo® 3500 V' }],
        },
      ],
    },
    {
      id: '85486427-447f-4a48-8c80-9e0329d1ea34',
      value: 'Other',
      description:
        'To learn more about how our products can meet your application needs, feel free to browse our available data or contact us below.',
      products: [
        { id: '30535150', name: 'Breox® 75 W 18000' },
        { id: '30535146', name: 'Breox® 75 W 55000' },
        { id: '30060715', name: 'Glissopal® V 1500' },
        { id: '30060711', name: 'Glissopal® V 190' },
        { id: '30613982', name: 'Glissopal® V 230' },
        { id: '30613984', name: 'Glissopal® V 500' },
        { id: '30613981', name: 'Glissopal® V 640' },
        { id: '30613983', name: 'Glissopal® V 700' },
        { id: '30479682', name: 'Irgaflo® 1100 V' },
        { id: '30782126', name: 'Irgaflo® 3500 V' },
        { id: '30644937', name: 'Irgaflo® 6000 V' },
        { id: '30480069', name: 'Irgaflo® 6100 V' },
        { id: '30485389', name: 'Irgaflo® 6300 V' },
        { id: '30686416', name: 'Irgaflo® 6305 V' },
        { id: '30088787', name: 'Pluracol® V 10' },
        { id: '30535735', name: 'Synative® ES 1200' },
        { id: '30731578', name: 'Synative® ES 3200' },
        { id: '30530081', name: 'Synative® ES 3345' },
        { id: '30726456', name: 'Synative® ES 3357' },
        { id: '30530035', name: 'Synative® ES TMP05/1000' },
      ],
    },
  ],
};
