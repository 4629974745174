/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CartMetadataDto } from '../models/cart-metadata-dto';
import { CartPatchRequestBodyDto } from '../models/cart-patch-request-body-dto';
import { CartResponseDto } from '../models/cart-response-dto';
import { CartToSaveBodyDto } from '../models/cart-to-save-body-dto';
import { CartUploadRequestBodyDto } from '../models/cart-upload-request-body-dto';
import { SavedCartListResponseDto } from '../models/saved-cart-list-response-dto';
import { SavedCartPatchBodyDto } from '../models/saved-cart-patch-body-dto';
import { SavedCartResponseDto } from '../models/saved-cart-response-dto';

@Injectable({
  providedIn: 'root',
})
export class PurchaseApiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getCartMetadata
   */
  static readonly GetCartMetadataPath = '/api/purchase/cart/metadata';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCartMetadata()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCartMetadata$Response(params?: {
  }): Observable<StrictHttpResponse<CartMetadataDto>> {

    const rb = new RequestBuilder(this.rootUrl, PurchaseApiService.GetCartMetadataPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CartMetadataDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCartMetadata$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCartMetadata(params?: {
  }): Observable<CartMetadataDto> {

    return this.getCartMetadata$Response(params).pipe(
      map((r: StrictHttpResponse<CartMetadataDto>) => r.body as CartMetadataDto)
    );
  }

  /**
   * Path part for operation getCurrentCart
   */
  static readonly GetCurrentCartPath = '/api/purchase/cart';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCurrentCart()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentCart$Response(params: {
    pollUntilCalculateIsDone: boolean;
  }): Observable<StrictHttpResponse<CartResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, PurchaseApiService.GetCurrentCartPath, 'get');
    if (params) {
      rb.query('pollUntilCalculateIsDone', params.pollUntilCalculateIsDone, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CartResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCurrentCart$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentCart(params: {
    pollUntilCalculateIsDone: boolean;
  }): Observable<CartResponseDto> {

    return this.getCurrentCart$Response(params).pipe(
      map((r: StrictHttpResponse<CartResponseDto>) => r.body as CartResponseDto)
    );
  }

  /**
   * Path part for operation deleteCurrentCart
   */
  static readonly DeleteCurrentCartPath = '/api/purchase/cart';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCurrentCart()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCurrentCart$Response(params?: {
  }): Observable<StrictHttpResponse<CartResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, PurchaseApiService.DeleteCurrentCartPath, 'delete');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CartResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteCurrentCart$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCurrentCart(params?: {
  }): Observable<CartResponseDto> {

    return this.deleteCurrentCart$Response(params).pipe(
      map((r: StrictHttpResponse<CartResponseDto>) => r.body as CartResponseDto)
    );
  }

  /**
   * Path part for operation patchCurrentCart
   */
  static readonly PatchCurrentCartPath = '/api/purchase/cart';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchCurrentCart()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchCurrentCart$Response(params: {
    body: CartPatchRequestBodyDto
  }): Observable<StrictHttpResponse<CartResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, PurchaseApiService.PatchCurrentCartPath, 'patch');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CartResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchCurrentCart$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchCurrentCart(params: {
    body: CartPatchRequestBodyDto
  }): Observable<CartResponseDto> {

    return this.patchCurrentCart$Response(params).pipe(
      map((r: StrictHttpResponse<CartResponseDto>) => r.body as CartResponseDto)
    );
  }

  /**
   * Path part for operation getSavedCartsList
   */
  static readonly GetSavedCartsListPath = '/api/purchase/saved-carts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSavedCartsList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSavedCartsList$Response(params?: {
  }): Observable<StrictHttpResponse<SavedCartListResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, PurchaseApiService.GetSavedCartsListPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SavedCartListResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSavedCartsList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSavedCartsList(params?: {
  }): Observable<SavedCartListResponseDto> {

    return this.getSavedCartsList$Response(params).pipe(
      map((r: StrictHttpResponse<SavedCartListResponseDto>) => r.body as SavedCartListResponseDto)
    );
  }

  /**
   * Path part for operation createNewSavedCart
   */
  static readonly CreateNewSavedCartPath = '/api/purchase/saved-carts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createNewSavedCart()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createNewSavedCart$Response(params: {
    body: CartToSaveBodyDto
  }): Observable<StrictHttpResponse<SavedCartResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, PurchaseApiService.CreateNewSavedCartPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SavedCartResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createNewSavedCart$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createNewSavedCart(params: {
    body: CartToSaveBodyDto
  }): Observable<SavedCartResponseDto> {

    return this.createNewSavedCart$Response(params).pipe(
      map((r: StrictHttpResponse<SavedCartResponseDto>) => r.body as SavedCartResponseDto)
    );
  }

  /**
   * Path part for operation getSavedCartById
   */
  static readonly GetSavedCartByIdPath = '/api/purchase/saved-carts/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSavedCartById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSavedCartById$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<SavedCartResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, PurchaseApiService.GetSavedCartByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SavedCartResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSavedCartById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSavedCartById(params: {
    id: string;
  }): Observable<SavedCartResponseDto> {

    return this.getSavedCartById$Response(params).pipe(
      map((r: StrictHttpResponse<SavedCartResponseDto>) => r.body as SavedCartResponseDto)
    );
  }

  /**
   * Path part for operation deleteSavedCart
   */
  static readonly DeleteSavedCartPath = '/api/purchase/saved-carts/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteSavedCart()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteSavedCart$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PurchaseApiService.DeleteSavedCartPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteSavedCart$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteSavedCart(params: {
    id: string;
  }): Observable<void> {

    return this.deleteSavedCart$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation patchSavedCart
   */
  static readonly PatchSavedCartPath = '/api/purchase/saved-carts/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchSavedCart()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchSavedCart$Response(params: {
    id: string;
    body: SavedCartPatchBodyDto
  }): Observable<StrictHttpResponse<SavedCartResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, PurchaseApiService.PatchSavedCartPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SavedCartResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchSavedCart$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchSavedCart(params: {
    id: string;
    body: SavedCartPatchBodyDto
  }): Observable<SavedCartResponseDto> {

    return this.patchSavedCart$Response(params).pipe(
      map((r: StrictHttpResponse<SavedCartResponseDto>) => r.body as SavedCartResponseDto)
    );
  }

  /**
   * Path part for operation downloadCartTemplate
   */
  static readonly DownloadCartTemplatePath = '/api/purchase/cart/download';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadCartTemplate()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadCartTemplate$Response(params?: {
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PurchaseApiService.DownloadCartTemplatePath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `downloadCartTemplate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadCartTemplate(params?: {
  }): Observable<void> {

    return this.downloadCartTemplate$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation uploadCartTemplate
   */
  static readonly UploadCartTemplatePath = '/api/purchase/cart/upload/template';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadCartTemplate()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadCartTemplate$Response(params: {
    body: CartUploadRequestBodyDto
  }): Observable<StrictHttpResponse<CartResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, PurchaseApiService.UploadCartTemplatePath, 'put');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CartResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `uploadCartTemplate$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadCartTemplate(params: {
    body: CartUploadRequestBodyDto
  }): Observable<CartResponseDto> {

    return this.uploadCartTemplate$Response(params).pipe(
      map((r: StrictHttpResponse<CartResponseDto>) => r.body as CartResponseDto)
    );
  }

  /**
   * Path part for operation deletePoDocumentOfLineItemGroup
   */
  static readonly DeletePoDocumentOfLineItemGroupPath = '/api/purchase/cart/upload/po-document/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletePoDocumentOfLineItemGroup()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePoDocumentOfLineItemGroup$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<CartResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, PurchaseApiService.DeletePoDocumentOfLineItemGroupPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CartResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deletePoDocumentOfLineItemGroup$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePoDocumentOfLineItemGroup(params: {
    id: string;
  }): Observable<CartResponseDto> {

    return this.deletePoDocumentOfLineItemGroup$Response(params).pipe(
      map((r: StrictHttpResponse<CartResponseDto>) => r.body as CartResponseDto)
    );
  }

  /**
   * Path part for operation uploadPoDocumentOfLineItemGroup
   */
  static readonly UploadPoDocumentOfLineItemGroupPath = '/api/purchase/cart/upload/po-document/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadPoDocumentOfLineItemGroup()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadPoDocumentOfLineItemGroup$Response(params: {
    id: string;

    /**
     * Contains the purchase order document file
     */
    body: {
'file'?: Blob;
}
  }): Observable<StrictHttpResponse<CartResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, PurchaseApiService.UploadPoDocumentOfLineItemGroupPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CartResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `uploadPoDocumentOfLineItemGroup$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadPoDocumentOfLineItemGroup(params: {
    id: string;

    /**
     * Contains the purchase order document file
     */
    body: {
'file'?: Blob;
}
  }): Observable<CartResponseDto> {

    return this.uploadPoDocumentOfLineItemGroup$Response(params).pipe(
      map((r: StrictHttpResponse<CartResponseDto>) => r.body as CartResponseDto)
    );
  }

}
