/* eslint-disable @typescript-eslint/no-unused-vars */

import { delay, of } from 'rxjs';

import { DeliveriesApiService, DeliveryDocumentsSearchRequestDto } from '../../api';
import { mockDeliveryDocuments } from '../data';

const localDB = {
  deliveryDocuments: mockDeliveryDocuments,
};

const serverDelay = 500; // ms

export const mockDeliveriesApiService: Partial<DeliveriesApiService> = {
  getDeliveryDocumentList(params: { body: DeliveryDocumentsSearchRequestDto }): any {
    return of(localDB.deliveryDocuments).pipe(delay(serverDelay));
  },
};
