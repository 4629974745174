/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DeliveryDocumentsSearchRequestDto } from '../models/delivery-documents-search-request-dto';
import { DeliveryDocumentsSearchResponseDto } from '../models/delivery-documents-search-response-dto';
import { DownloadMultipleDeliveryDocumentsRequestDto } from '../models/download-multiple-delivery-documents-request-dto';

@Injectable({
  providedIn: 'root',
})
export class DeliveriesApiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getDeliveryDocumentList
   */
  static readonly GetDeliveryDocumentListPath = '/api/deliveries/documents/search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDeliveryDocumentList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getDeliveryDocumentList$Response(params: {
    body: DeliveryDocumentsSearchRequestDto
  }): Observable<StrictHttpResponse<DeliveryDocumentsSearchResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, DeliveriesApiService.GetDeliveryDocumentListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DeliveryDocumentsSearchResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDeliveryDocumentList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getDeliveryDocumentList(params: {
    body: DeliveryDocumentsSearchRequestDto
  }): Observable<DeliveryDocumentsSearchResponseDto> {

    return this.getDeliveryDocumentList$Response(params).pipe(
      map((r: StrictHttpResponse<DeliveryDocumentsSearchResponseDto>) => r.body as DeliveryDocumentsSearchResponseDto)
    );
  }

  /**
   * Path part for operation downloadMultipleDeliveryDocuments
   */
  static readonly DownloadMultipleDeliveryDocumentsPath = '/api/deliveries/documents/download';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadMultipleDeliveryDocuments()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  downloadMultipleDeliveryDocuments$Response(params: {
    body: DownloadMultipleDeliveryDocumentsRequestDto
  }): Observable<StrictHttpResponse<any>> {

    const rb = new RequestBuilder(this.rootUrl, DeliveriesApiService.DownloadMultipleDeliveryDocumentsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `downloadMultipleDeliveryDocuments$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  downloadMultipleDeliveryDocuments(params: {
    body: DownloadMultipleDeliveryDocumentsRequestDto
  }): Observable<any> {

    return this.downloadMultipleDeliveryDocuments$Response(params).pipe(
      map((r: StrictHttpResponse<any>) => r.body as any)
    );
  }

  /**
   * Path part for operation downloadDeliveryItemDocument
   */
  static readonly DownloadDeliveryItemDocumentPath = '/api/deliveries/documents/{deliveryNo}/{itemNo}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadDeliveryItemDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadDeliveryItemDocument$Response(params: {
    documentType: 'COA' | 'INV' | 'NF' | 'SN' | 'DL' | 'BOL';
    deliveryNo: string;
    itemNo: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, DeliveriesApiService.DownloadDeliveryItemDocumentPath, 'get');
    if (params) {
      rb.query('documentType', params.documentType, {});
      rb.path('deliveryNo', params.deliveryNo, {});
      rb.path('itemNo', params.itemNo, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `downloadDeliveryItemDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadDeliveryItemDocument(params: {
    documentType: 'COA' | 'INV' | 'NF' | 'SN' | 'DL' | 'BOL';
    deliveryNo: string;
    itemNo: string;
  }): Observable<void> {

    return this.downloadDeliveryItemDocument$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation downloadShipmentDocument
   */
  static readonly DownloadShipmentDocumentPath = '/api/deliveries/documents/{deliveryNo}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadShipmentDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadShipmentDocument$Response(params: {
    documentType: 'COA' | 'INV' | 'NF' | 'SN' | 'DL' | 'BOL';
    deliveryNo: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, DeliveriesApiService.DownloadShipmentDocumentPath, 'get');
    if (params) {
      rb.query('documentType', params.documentType, {});
      rb.path('deliveryNo', params.deliveryNo, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `downloadShipmentDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadShipmentDocument(params: {
    documentType: 'COA' | 'INV' | 'NF' | 'SN' | 'DL' | 'BOL';
    deliveryNo: string;
  }): Observable<void> {

    return this.downloadShipmentDocument$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
