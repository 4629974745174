/* eslint-disable @typescript-eslint/no-unused-vars */
import { delay, Observable, of } from 'rxjs';

import {
  OrderDetailsResponse,
  OrdersApiService,
  OrdersRequestBody,
  OrdersResponse,
  SortByFieldEnum,
  SortOrderEnum,
} from '../../api';
import { mockOrderDetails, mockOrderHistory } from '../data';

const localDB = {
  orderHistory: mockOrderHistory,
  orderDetails: mockOrderDetails,
};

const serverDelay = 500; // ms

export const mockOrderHistoryApiService: Partial<OrdersApiService> = {
  getOrdersList(params: {
    locale?: string;
    offset?: number;
    limit?: number;
    sortByField?: SortByFieldEnum;
    sortOrder?: SortOrderEnum;
    body: OrdersRequestBody;
  }): Observable<OrdersResponse> {
    return of(localDB.orderHistory).pipe(delay(serverDelay));
  },

  getOrderDetails(params: { orderNo: string; locale?: string }): Observable<OrderDetailsResponse> {
    return of(localDB.orderDetails).pipe(delay(serverDelay));
  },
};
