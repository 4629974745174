/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ContactUsInquiryRequestBodyDto } from '../models/contact-us-inquiry-request-body-dto';
import { ContactUsInquiryResponseDto } from '../models/contact-us-inquiry-response-dto';
import { DebugInfoDto } from '../models/debug-info-dto';
import { DocumentInquiryRequestBodyDto } from '../models/document-inquiry-request-body-dto';
import { DocumentInquiryResponseDto } from '../models/document-inquiry-response-dto';
import { InquiryHistoryItemDetailsDto } from '../models/inquiry-history-item-details-dto';
import { InquiryHistoryResponseDto } from '../models/inquiry-history-response-dto';
import { SupportInquiryCategory } from '../models/support-inquiry-category';
import { SupportInquiryRequestBodyDto } from '../models/support-inquiry-request-body-dto';
import { SupportInquiryResponseDto } from '../models/support-inquiry-response-dto';

@Injectable({
  providedIn: 'root',
})
export class RequestSupportApiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getInquiryHistory
   */
  static readonly GetInquiryHistoryPath = '/api/requests';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getInquiryHistory()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInquiryHistory$Response(params?: {
  }): Observable<StrictHttpResponse<InquiryHistoryResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, RequestSupportApiService.GetInquiryHistoryPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InquiryHistoryResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getInquiryHistory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInquiryHistory(params?: {
  }): Observable<InquiryHistoryResponseDto> {

    return this.getInquiryHistory$Response(params).pipe(
      map((r: StrictHttpResponse<InquiryHistoryResponseDto>) => r.body as InquiryHistoryResponseDto)
    );
  }

  /**
   * Path part for operation getInquiryHistoryItemDetailsById
   */
  static readonly GetInquiryHistoryItemDetailsByIdPath = '/api/requests/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getInquiryHistoryItemDetailsById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInquiryHistoryItemDetailsById$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<InquiryHistoryItemDetailsDto>> {

    const rb = new RequestBuilder(this.rootUrl, RequestSupportApiService.GetInquiryHistoryItemDetailsByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InquiryHistoryItemDetailsDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getInquiryHistoryItemDetailsById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInquiryHistoryItemDetailsById(params: {
    id: string;
  }): Observable<InquiryHistoryItemDetailsDto> {

    return this.getInquiryHistoryItemDetailsById$Response(params).pipe(
      map((r: StrictHttpResponse<InquiryHistoryItemDetailsDto>) => r.body as InquiryHistoryItemDetailsDto)
    );
  }

  /**
   * Path part for operation documentAccessRequest
   */
  static readonly DocumentAccessRequestPath = '/api/requests/document-access';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentAccessRequest()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentAccessRequest$Response(params: {
    body: DocumentInquiryRequestBodyDto
  }): Observable<StrictHttpResponse<DocumentInquiryResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, RequestSupportApiService.DocumentAccessRequestPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DocumentInquiryResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `documentAccessRequest$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentAccessRequest(params: {
    body: DocumentInquiryRequestBodyDto
  }): Observable<DocumentInquiryResponseDto> {

    return this.documentAccessRequest$Response(params).pipe(
      map((r: StrictHttpResponse<DocumentInquiryResponseDto>) => r.body as DocumentInquiryResponseDto)
    );
  }

  /**
   * Path part for operation contactUsForm
   */
  static readonly ContactUsFormPath = '/api/requests/contact-us';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactUsForm()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  contactUsForm$Response(params: {
    body: ContactUsInquiryRequestBodyDto
  }): Observable<StrictHttpResponse<ContactUsInquiryResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, RequestSupportApiService.ContactUsFormPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ContactUsInquiryResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `contactUsForm$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  contactUsForm(params: {
    body: ContactUsInquiryRequestBodyDto
  }): Observable<ContactUsInquiryResponseDto> {

    return this.contactUsForm$Response(params).pipe(
      map((r: StrictHttpResponse<ContactUsInquiryResponseDto>) => r.body as ContactUsInquiryResponseDto)
    );
  }

  /**
   * Path part for operation debugInfo
   */
  static readonly DebugInfoPath = '/api/requests/debug';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `debugInfo()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  debugInfo$Response(params: {
    body: DebugInfoDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, RequestSupportApiService.DebugInfoPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `debugInfo$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  debugInfo(params: {
    body: DebugInfoDto
  }): Observable<void> {

    return this.debugInfo$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation salesforceRequest
   */
  static readonly SalesforceRequestPath = '/api/requests/{inquiryCategory}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `salesforceRequest()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  salesforceRequest$Response(params: {
    inquiryCategory: SupportInquiryCategory;
    body: SupportInquiryRequestBodyDto
  }): Observable<StrictHttpResponse<SupportInquiryResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, RequestSupportApiService.SalesforceRequestPath, 'post');
    if (params) {
      rb.path('inquiryCategory', params.inquiryCategory, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SupportInquiryResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `salesforceRequest$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  salesforceRequest(params: {
    inquiryCategory: SupportInquiryCategory;
    body: SupportInquiryRequestBodyDto
  }): Observable<SupportInquiryResponseDto> {

    return this.salesforceRequest$Response(params).pipe(
      map((r: StrictHttpResponse<SupportInquiryResponseDto>) => r.body as SupportInquiryResponseDto)
    );
  }

}
