import { SelectedLocationDto } from '../../api';

export const mockSelectedLocation: SelectedLocationDto = getDefaultSelectedLocations();

export const mockAddressKey = '0000396020';

export const mockAddressItem = {
  key: mockAddressKey,
  name: 'Chemiplas Australia Pty Ltd',
  address: {
    street: 'Jolimont Road Lvl 1, 128',
    city: 'East Melbourne',
    zip: '3002',
    countryCode: 'AU',
    countryName: 'Australia',
  },
};

export const mockIncludedAddresses = [mockAddressItem];

function getDefaultSelectedLocations(): SelectedLocationDto {
  return {
    soldToKey: mockAddressKey,
    shipToKey: mockAddressKey,
    billToKey: mockAddressKey,
    payerKey: mockAddressKey,
    salesAreas: ['AU'],
    includedAddresses: mockIncludedAddresses,
  };
}
