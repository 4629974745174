/* tslint:disable */
/* eslint-disable */

/**
 * The carrier shipment status
 */
export enum CarrierInfoStatus {
  Booked = 'Booked',
  InTransit = 'In transit',
  Delivered = 'Delivered'
}
