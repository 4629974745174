/* tslint:disable */
/* eslint-disable */

/**
 * The carrier shipment on time status
 */
export enum CarrierInfoOnTimeStatus {
  OnTime = 'On Time',
  Delayed = 'Delayed',
  Early = 'Early'
}
