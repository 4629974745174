import {
  InquiryApprovalStatus,
  InquiryCategoryText,
  InquiryHistoryItemDetailsDto,
  InquiryHistoryResponseDto,
} from '../../api';

export const mockRequestHistory: InquiryHistoryResponseDto = {
  requestHistory: [
    {
      id: '1234',
      caseNumber: '12443431',
      category: InquiryCategoryText.DocumentRequests,
      createdDate: '2021-05-05T00:00:00.000Z',
      status: InquiryApprovalStatus.Approved,
      subject: 'Request No. 1.',
      products: [
        { id: '1234', name: 'Product Name', slug: 'test-slug' },
        { id: '1234', name: 'Product Name', slug: 'test-slug' },
        { id: '1234', name: 'Product Name', slug: 'test-slug' },
      ],
      // downloadLink: 'https://www.google.com',
    },
    {
      id: '2222',
      caseNumber: '12443432',
      category: InquiryCategoryText.CustomerPortalService,
      createdDate: '2020-01-05T00:00:00.000Z',
      status: InquiryApprovalStatus.Open,
      products: [{ id: '1234', name: 'Product Name', slug: 'test-slug' }],
      subject: '-',
    },
    {
      id: '33333',
      caseNumber: '12443433',
      category: InquiryCategoryText.Other,
      createdDate: '2020-01-05T00:00:00.000Z',
      status: InquiryApprovalStatus.Approved,
      subject: '-',
    },
    {
      id: '4444',
      caseNumber: '12443434',
      category: InquiryCategoryText.SampleRequests,
      createdDate: '2020-01-05T00:00:00.000Z',
      status: InquiryApprovalStatus.Rejected,
      subject: '-',
    },
    {
      id: '5555',
      caseNumber: '12443435',
      category: InquiryCategoryText.Sustainability,
      createdDate: '2020-01-05T00:00:00.000Z',
      status: InquiryApprovalStatus.Closed,
      subject: '-',
    },
    {
      id: '6666',
      caseNumber: '12443436',
      category: InquiryCategoryText.TechnicalRequests,
      createdDate: '2020-01-05T00:00:00.000Z',
      status: InquiryApprovalStatus.Approved,
      subject: '-',
    },
    {
      id: '7777',
      caseNumber: '12443437',
      category: InquiryCategoryText.DocumentRequests,
      createdDate: '2020-01-05T00:00:00.000Z',
      status: InquiryApprovalStatus.Approved,
      subject: '-',
    },
  ],
};

export const mockRequestHistoryItem: InquiryHistoryItemDetailsDto = {
  id: '1234',
  caseNumber: '12443436',
  category: InquiryCategoryText.Sustainability,
  createdDate: '2021-05-05T00:00:00.000Z',
  products: [{ id: '1234', name: 'Product Name', slug: 'test-slug' }],
  status: InquiryApprovalStatus.Open,
  subject: 'Request No. 1.',
  description: 'Some sample description',
  sbu: null,
};
