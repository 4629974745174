/* tslint:disable */
/* eslint-disable */

/**
 * Request Type
 */
export enum InquiryCategoryText {
  CommercialRequests = 'Commercial Requests',
  CustomerPortalService = 'Customer Portal Service',
  DocumentRequests = 'Document Requests',
  Other = 'Other',
  SampleRequests = 'Sample Requests',
  Sustainability = 'Sustainability',
  TechnicalRequests = 'Technical Requests'
}
