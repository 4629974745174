/* eslint-disable @typescript-eslint/no-unused-vars */
import { HttpErrorResponse } from '@angular/common/http';
import { delay, Observable, of, throwError } from 'rxjs';

import { AuthenticationApiService, AuthLoginRequestDto, UserBasicInfoDto } from '../../api';

const localDB = {};

const serverDelay = 500; // ms

export const mockAuthenticationApiService: Partial<AuthenticationApiService> = {
  login(params: { body: AuthLoginRequestDto }): Observable<UserBasicInfoDto> {
    // You can customize your error object as needed
    const errorResponse = new HttpErrorResponse({
      error: 'Error message',
      status: 400, // You can set the HTTP status code as needed
      statusText: 'Test Error',
    });

    // Emit the error
    return throwError(() => errorResponse);
    // return of(mockUserBasicInfo).pipe(delay(serverDelay));
  },

  logout(params?: unknown): Observable<void> {
    return of(undefined).pipe(delay(serverDelay));
  },
};
