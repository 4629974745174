import { delay, Observable, of } from 'rxjs';

import {
  ArticlePricesDto,
  NavigatorFilterTreeDto,
  ProductDetailsDto,
  ProductListRequestBodyDto,
  ProductListResponseDto,
  ProductsApiService,
  SimpleProductsResponseDto,
} from '../../api';
import {
  mockArticlePrices,
  mockArticlePricesMap,
  mockCategoryOptions,
  mockProductList,
} from '../data';
import { mockProductDetails } from '../data/mock-product-details.data';
import { mockDocumentList } from '../data/mock-product-documents.data';

const localDB = {
  documentList: mockDocumentList,
  productDetails: mockProductDetails,
  categoryOptions: mockCategoryOptions,
};

const serverDelay = 500; // ms

export const mockProductsApiService: Partial<ProductsApiService> = {
  getProductDetails(params: { id: string }): Observable<ProductDetailsDto> {
    console.log('mockProductsApiService.getProductDetails', params);
    return of(localDB.productDetails).pipe(delay(serverDelay));
  },

  getIndustriesFilterOptions: function (params?: {
    locale?: string;
  }): Observable<NavigatorFilterTreeDto> {
    console.log('mockProductsApiService.getIndustriesFilterOptions');
    return of({ industryNavigatorFilterTree: localDB.categoryOptions }).pipe(delay(serverDelay));
  },

  getProductList: function (params?: {
    body: ProductListRequestBodyDto;
  }): Observable<ProductListResponseDto> {
    console.log('mockProductsApiService.getProductList', params);
    const skip = params?.body.pagination?.skip ?? 0;
    const limit = params?.body.pagination?.limit ?? 0;
    const products = mockProductList.products.slice(skip, skip + limit);
    const pagination = { ...mockProductList.pagination, skip, limit };
    // return throwError(() => new Error('An error occurred after 1 second!'));
    return of({ ...mockProductList, products, pagination }).pipe(delay(serverDelay));
  },

  getSimpleProductsPaginated: function (params?: {
    locale?: string;
    searchTerm?: string;
    skip: number;
    limit: number;
  }): Observable<SimpleProductsResponseDto> {
    console.log('mockProductsApiService.getSimpleProducts');
    return of({ products: mockProductList.products }).pipe(delay(serverDelay));
  },

  getArticlePrices(params: {
    articleId: string;
    locale?: string;
    payerKey?: string;
    billToKey?: string;
    shipToKey: string;
    soldToKey: string;
  }): Observable<ArticlePricesDto> {
    console.log('mockProductsApiService.getArticlePrices', params);
    return of(mockArticlePricesMap[params.articleId] ?? mockArticlePrices).pipe(delay(serverDelay));
  },
};
