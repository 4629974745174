/* eslint-disable @typescript-eslint/no-unused-vars */
import { delay, Observable, of } from 'rxjs';

import {
  CartCalculationStatusEnum,
  CartMetadataDto,
  CartPatchRequestBodyDto,
  CartResponseDto,
  CartToSaveBodyDto,
  PurchaseApiService,
  SavedCartListResponseDto,
  SavedCartPatchBodyDto,
  SavedCartResponseDto,
} from '../../api';
import {
  mockCart,
  mockEvSavedCartListResponse,
  mockEvSavedCartResponse,
} from '../data/mock-cart.data';

const localDB = {
  cart: mockCart,
  savedCartsResponse: mockEvSavedCartListResponse,
  savedCart: mockEvSavedCartResponse,
};

const serverDelay = 500; // ms

export const mockPurchaseApiService: Partial<PurchaseApiService> = {
  getCartMetadata(): Observable<CartMetadataDto> {
    return of({
      calculationStatus: CartCalculationStatusEnum.CalculationFailed,
      lineItemCount: 11,
    }).pipe(delay(serverDelay));
  },

  getCurrentCart(params?: {
    pollUntilCalculateIsDone: boolean;
    locale?: string;
  }): Observable<CartResponseDto> {
    const cart = localDB.cart;
    // cart.calculationStatus = 'CALCULATION_IN_PROGRESS';

    return of(cart).pipe(delay(serverDelay));
  },

  patchCurrentCart(params: {
    locale?: string;
    body: CartPatchRequestBodyDto;
  }): Observable<CartResponseDto> {
    console.log(`PATCH [mockPurchaseApiService::patchCurrentCart] payload`, params.body);

    const resultCart = localDB.cart;

    if (params.body.actions.find(action => action.action === 'recalculate')) {
      resultCart.calculationStatus = 'CALCULATED';
    }

    return of(resultCart).pipe(delay(serverDelay));
  },

  getSavedCartsList(params?: { locale?: string }): Observable<SavedCartListResponseDto> {
    return of(localDB.savedCartsResponse).pipe(delay(serverDelay));
  },

  createNewSavedCart(params: {
    locale?: string;
    body: CartToSaveBodyDto;
  }): Observable<SavedCartResponseDto> {
    return of(localDB.savedCart).pipe(delay(serverDelay));
  },

  getSavedCartById(params: { id: string; locale?: string }): Observable<SavedCartResponseDto> {
    return of(structuredClone(localDB.savedCart)).pipe(delay(serverDelay));
  },

  deleteSavedCart(params: { id: string; locale?: string }): Observable<void> {
    localDB.savedCartsResponse.savedCarts = localDB.savedCartsResponse.savedCarts.filter(
      savedCart => savedCart.id !== params.id,
    );

    return of(undefined).pipe(delay(serverDelay));
  },

  patchSavedCart(params: {
    id: string;
    locale?: string;
    body: SavedCartPatchBodyDto;
  }): Observable<SavedCartResponseDto> {
    params.body.actions.forEach(actionObject => {
      // Removing line items if needed from the saved cart
      if (actionObject.action === 'removeLineItemSavedCart') {
        localDB.savedCart.lineItems = localDB.savedCart.lineItems.filter(
          lineItem => lineItem.id !== actionObject.lineItemId,
        );
      }
    });

    return of(localDB.savedCart).pipe(delay(serverDelay));
  },
};
