import { AccountBalanceResponseDto } from '../../api';

export const mockAccountBalanceResponse: AccountBalanceResponseDto = {
  balances: [
    {
      balance: 1289224, // Balance
      creditPostings: 870748, // Paid items
      currency: 'EUR',
      debitPostings: 418476, // Open positions
    },
  ],
  items: [
    {
      clearingDate: '2022-01-01',
      clearingDocument: 'ABC123',
      company: 'Company A',
      documentCurrency: 'USD',
      documentDate: '2022-01-01',
      documentNumber: '123456', // Reference number
      dueDate: '2022-02-01', // Due date
      itemNumber: '1',
      localValue: 456,
      overdue: false,
      overdueDays: 0,
      paymentTerm: 'Net 30',
      paymentTermText: 'Payment due in 30 days',
      postingDate: '2022-01-01', // Date of issue / credit?
      postingKey: '01',
      postingKeyText: 'General Posting', // Document type
      salesOrders: [
        {
          deliveryItemNumber: '1',
          deliveryNumber: '123',
          invoiceDocAvailable: true,
          orderNumber: 'ABC123', // PurchaseOrder
          poNumber: 'PO123',
        },
      ],
      status: 'Open',
      statusText: 'Payment due', // Status
      tax: '10%',
      value: 789, // Original amount?
    },
  ],
  pagination: {
    count: 1,
    limit: 1,
    offset: 1,
    totalCount: 1,
  },
};
