import { OrderDetailsShipment, ShipmentDocumentName, ShipmentDocumentType } from '../../api';
import { CarrierInfoOnTimeStatus } from '../../api/models/carrier-info-on-time-status';
import { CarrierInfoStatus } from '../../api/models/carrier-info-status';
import { OrderDetailsResponse } from '../../api/models/order-details-response';
import { OrderDetailsShipmentDetails } from '../../api/models/order-details-shipment-details';

export const mockOrderDetails: OrderDetailsResponse = {
  orderNo: '4000000011',
  orderCreationDate: '2021-03-01T12:40:24Z',
  poNumber: 'Test Order of Customer',
  requestedDeliveryDate: '2021-04-11',
  status: {
    openOrder: false,
    text: 'Shipped',
    code: '05',
  },
  locations: {
    soldTo: {
      id: '0000003412',
      name: 'Test Customer GmbH',
      street: 'Teststreet',
      houseNo: '10',
      city: 'Testcity',
      postalCode: '000010',
      country: 'DE',
    },
    shipTo: {
      id: '0000003412',
      name: 'Test Customer GmbH',
      street: 'Teststreet',
      houseNo: '11',
      city: 'Testcity',
      postalCode: '000010',
      country: 'DE',
    },
    billTo: {
      id: '0000003412',
      name: 'Test Customer GmbH',
      street: 'Teststreet',
      houseNo: '12',
      city: 'Testcity',
      postalCode: '000010',
      country: 'DE',
    },
    payer: {
      id: '0000003412',
      name: 'Test Customer GmbH',
      street: 'Teststreet',
      houseNo: '13',
      city: 'Testcity',
      postalCode: '000010',
      country: 'DE',
    },
  },
  price: {
    total: 920,
    currency: 'EUR',
  },
  shippingCondition: {
    text: 'truck',
    code: '02',
  },
  trackingAvailable: true,
  countOfItems: 2,
  lineItems: [
    {
      itemNo: '000010',
      article: {
        id: '000010',
        name: 'Nitric Acid techn. pure 68% (Antwerpen)',
        customerArticleId: '4124124',
      },
      orderStatus: {
        text: 'Shipped',
        code: '007',
      },
      quantity: {
        value: 4000,
        unit: 'KG',
      },
      rejection: {
        rejected: true,
        code: '05',
        text: 'Customer rej: wrongly requested / no need',
      },
      priceInfo: {
        perUnitPrice: 0.23,
        unit: 'KG',
        total: 920,
        currency: 'EUR',
        priceFinal: true,
      },
      requestedDeliveryDate: '2021-03-20',
      shipments: [
        {
          deliveryNo: '2000000050',
          shipmentDate: '2021-03-13T14:20:24Z',
          status: {
            text: 'Shipped',
            code: '06',
          },
          expectedDeliveryDate: '2021-03-01T12:40:24Z',
          weight: {
            value: 3150,
            unit: 'KG',
          },
          shipmentDocumentDownloads: [
            {
              documentType: ShipmentDocumentType.Inv,
              documentName: ShipmentDocumentName.DeliveryInvoice,
              documentDownloadUrl:
                'http://localhost:3000/api/orders/deliveries/6210126384/000010/document?documentType=INV',
              documentFileName: '000010-000000000050649443-Magnafloc 5250 AP 800KG 13H3-INV.pdf',
            },
            {
              documentType: ShipmentDocumentType.Coa,
              documentName: ShipmentDocumentName.CertificateOfAnalysis,
              documentDownloadUrl:
                'http://localhost:3000/api/orders/deliveries/6210126384/000010/document?documentType=COA',
              documentFileName: '000010-000000000050649443-Magnafloc 5250 AP 800KG 13H3-COA.pdf',
            },
          ],
          shipmentDetails: undefined,
          comproveiTrackingLink: undefined,
        },
        {
          deliveryNo: '2000000051',
          shipmentDate: '2020-01-13T14:20:24Z',
          status: {
            text: 'Shipped',
            code: '06',
          },
          expectedDeliveryDate: '2020-03-01T12:40:24Z',
          weight: {
            value: 1150,
            unit: 'KG',
          },
          shipmentDocumentDownloads: [
            {
              documentType: ShipmentDocumentType.Inv,
              documentName: ShipmentDocumentName.DeliveryInvoice,
              documentDownloadUrl:
                'http://localhost:3000/api/orders/deliveries/6210126384/000010/document?documentType=INV',
              documentFileName: '000010-000000000050649443-Magnafloc 5250 AP 800KG 13H3-INV.pdf',
            },
            {
              documentType: ShipmentDocumentType.Coa,
              documentName: ShipmentDocumentName.CertificateOfAnalysis,
              documentDownloadUrl:
                'http://localhost:3000/api/orders/deliveries/6210126384/000010/document?documentType=COA',
              documentFileName: '000010-000000000050649443-Magnafloc 5250 AP 800KG 13H3-COA.pdf',
            },
            {
              documentType: ShipmentDocumentType.Dgd,
              documentName: ShipmentDocumentName.CertificateOfAnalysis,
              documentDownloadUrl:
                'http://localhost:3000/api/orders/deliveries/6210126384/000010/document?documentType=COA',
              documentFileName: '000010-000000000050649443-Magnafloc 5250 AP 800KG 13H3-COA.pdf',
            },
          ],
          shipmentDetails: undefined,
        },
      ],
    },
    {
      itemNo: '000011',
      article: {
        id: '000011',
        name: 'Secondary Nitric Acid techn. pure 68% (Antwerpen)',
        customerArticleId: '4124124',
      },
      orderStatus: {
        text: 'Shipped',
        code: '007',
      },
      quantity: {
        value: 4000,
        unit: 'KG',
      },
      rejection: {
        rejected: true,
        code: '05',
        text: 'Customer rej: wrongly requested / no need',
      },
      priceInfo: {
        perUnitPrice: 0.23,
        unit: 'KG',
        total: 920,
        currency: 'EUR',
        priceFinal: true,
      },
      requestedDeliveryDate: '2021-03-20',
      shipments: [
        {
          deliveryNo: '2000000050',
          shipmentDate: '2021-03-13T14:20:24Z',
          status: {
            text: 'Shipped',
            code: '06',
          },
          expectedDeliveryDate: '2021-03-01T12:40:24Z',
          weight: {
            value: 3150,
            unit: 'KG',
          },
        },
        {
          deliveryNo: '2000000050',
          shipmentDate: '2021-03-13T14:20:24Z',
          status: {
            text: 'Shipped',
            code: '06',
          },
          expectedDeliveryDate: '2021-03-01T12:40:24Z',
          weight: {
            value: 3150,
            unit: 'KG',
          },
        },
      ],
    },
  ],
  allDocumentsDownloadUrl: 'https://localhost:3000/api/orders/4000000011/documents',
  documentsAvailable: true,
};

export const mockShipments: Array<OrderDetailsShipment> = [
  {
    deliveryNo: '2000000050',
    shipmentDate: '2021-03-13T14:20:24Z',
    status: {
      text: 'Shipped',
      code: '06',
    },
    expectedDeliveryDate: '2021-03-01T12:40:24Z',
    weight: {
      value: 3150,
      unit: 'KG',
    },
    shipmentDocumentDownloads: [
      {
        documentType: ShipmentDocumentType.Inv,
        documentName: ShipmentDocumentName.DeliveryInvoice,
        documentDownloadUrl:
          'http://localhost:3000/api/orders/deliveries/6210126384/000010/document?documentType=INV',
        documentFileName: '000010-000000000050649443-Magnafloc 5250 AP 800KG 13H3-INV.pdf',
      },
      {
        documentType: ShipmentDocumentType.Coa,
        documentName: ShipmentDocumentName.CertificateOfAnalysis,
        documentDownloadUrl:
          'http://localhost:3000/api/orders/deliveries/6210126384/000010/document?documentType=COA',
        documentFileName: '000010-000000000050649443-Magnafloc 5250 AP 800KG 13H3-COA.pdf',
      },
    ],
  },
];

// Data coming from VISTA API service
export function getMockShipmentDetails(): OrderDetailsShipmentDetails {
  return {
    deliveryNumber: '6212855329',
    shipmentNumber: '2012091629',
    containerNumber: 'MAGU2463610',
    vesselImo: '1234567',
    carrierInformation: {
      number: '0000220343SP',
      name: 'CMA-CGM AGENCIES WORLDWIDE S.A.S.',
      status: CarrierInfoStatus.InTransit,
      onTimeStatus: CarrierInfoOnTimeStatus.OnTime,
      estimatedTimeOfArrival: '2023-08-30T00:00:00Z',
      estimatedTimeOfDeparture: '2023-08-01T00:00:00Z',
    },
    portOfLoading: {
      name: 'HAMBURG',
      actualTimeOfDeparture: '2023-08-01T00:00:00Z',
    },
    portOfDischarge: {
      name: 'SYDNEY',
      // actualTimeOfArrival: '2023-08-30T00:00:00Z',
    },
  };
}
