/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AvailableAddressesResponseDto } from '../models/available-addresses-response-dto';
import { PatchSelectedLocationDto } from '../models/patch-selected-location-dto';
import { SelectedLocationDto } from '../models/selected-location-dto';

@Injectable({
  providedIn: 'root',
})
export class AccountsApiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAvailableSoldToAddresses
   */
  static readonly GetAvailableSoldToAddressesPath = '/api/accounts/sold-to';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAvailableSoldToAddresses()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAvailableSoldToAddresses$Response(params?: {
  }): Observable<StrictHttpResponse<AvailableAddressesResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, AccountsApiService.GetAvailableSoldToAddressesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AvailableAddressesResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAvailableSoldToAddresses$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAvailableSoldToAddresses(params?: {
  }): Observable<AvailableAddressesResponseDto> {

    return this.getAvailableSoldToAddresses$Response(params).pipe(
      map((r: StrictHttpResponse<AvailableAddressesResponseDto>) => r.body as AvailableAddressesResponseDto)
    );
  }

  /**
   * Path part for operation getAvailableShipToAddresses
   */
  static readonly GetAvailableShipToAddressesPath = '/api/accounts/ship-to';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAvailableShipToAddresses()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAvailableShipToAddresses$Response(params: {
    soldToKey: string;
  }): Observable<StrictHttpResponse<AvailableAddressesResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, AccountsApiService.GetAvailableShipToAddressesPath, 'get');
    if (params) {
      rb.query('soldToKey', params.soldToKey, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AvailableAddressesResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAvailableShipToAddresses$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAvailableShipToAddresses(params: {
    soldToKey: string;
  }): Observable<AvailableAddressesResponseDto> {

    return this.getAvailableShipToAddresses$Response(params).pipe(
      map((r: StrictHttpResponse<AvailableAddressesResponseDto>) => r.body as AvailableAddressesResponseDto)
    );
  }

  /**
   * Path part for operation getAvailableBillToAddresses
   */
  static readonly GetAvailableBillToAddressesPath = '/api/accounts/bill-to';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAvailableBillToAddresses()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAvailableBillToAddresses$Response(params: {

    /**
     * The sold-to key to query bill-to addresses for
     */
    soldToKey: string;

    /**
     * The ship-to key to query bill-to addresses for
     */
    shipToKey: string;
  }): Observable<StrictHttpResponse<AvailableAddressesResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, AccountsApiService.GetAvailableBillToAddressesPath, 'get');
    if (params) {
      rb.query('soldToKey', params.soldToKey, {});
      rb.query('shipToKey', params.shipToKey, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AvailableAddressesResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAvailableBillToAddresses$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAvailableBillToAddresses(params: {

    /**
     * The sold-to key to query bill-to addresses for
     */
    soldToKey: string;

    /**
     * The ship-to key to query bill-to addresses for
     */
    shipToKey: string;
  }): Observable<AvailableAddressesResponseDto> {

    return this.getAvailableBillToAddresses$Response(params).pipe(
      map((r: StrictHttpResponse<AvailableAddressesResponseDto>) => r.body as AvailableAddressesResponseDto)
    );
  }

  /**
   * Path part for operation getAvailablePayerAddresses
   */
  static readonly GetAvailablePayerAddressesPath = '/api/accounts/payer';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAvailablePayerAddresses()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAvailablePayerAddresses$Response(params: {

    /**
     * The sold-to key to query payer addresses for
     */
    soldToKey: string;

    /**
     * The ship-to key to query payer addresses for
     */
    shipToKey: string;

    /**
     * The bill-to key to query payer addresses for
     */
    billToKey: string;
  }): Observable<StrictHttpResponse<AvailableAddressesResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, AccountsApiService.GetAvailablePayerAddressesPath, 'get');
    if (params) {
      rb.query('soldToKey', params.soldToKey, {});
      rb.query('shipToKey', params.shipToKey, {});
      rb.query('billToKey', params.billToKey, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AvailableAddressesResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAvailablePayerAddresses$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAvailablePayerAddresses(params: {

    /**
     * The sold-to key to query payer addresses for
     */
    soldToKey: string;

    /**
     * The ship-to key to query payer addresses for
     */
    shipToKey: string;

    /**
     * The bill-to key to query payer addresses for
     */
    billToKey: string;
  }): Observable<AvailableAddressesResponseDto> {

    return this.getAvailablePayerAddresses$Response(params).pipe(
      map((r: StrictHttpResponse<AvailableAddressesResponseDto>) => r.body as AvailableAddressesResponseDto)
    );
  }

  /**
   * Path part for operation getSelectedLocation
   */
  static readonly GetSelectedLocationPath = '/api/accounts/selected-location-keys';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSelectedLocation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSelectedLocation$Response(params?: {
  }): Observable<StrictHttpResponse<(SelectedLocationDto | null)>> {

    const rb = new RequestBuilder(this.rootUrl, AccountsApiService.GetSelectedLocationPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<(SelectedLocationDto | null)>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSelectedLocation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSelectedLocation(params?: {
  }): Observable<(SelectedLocationDto | null)> {

    return this.getSelectedLocation$Response(params).pipe(
      map((r: StrictHttpResponse<(SelectedLocationDto | null)>) => r.body as (SelectedLocationDto | null))
    );
  }

  /**
   * Path part for operation patchSelectedLocation
   */
  static readonly PatchSelectedLocationPath = '/api/accounts/selected-location-keys';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchSelectedLocation()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchSelectedLocation$Response(params: {
    body: PatchSelectedLocationDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AccountsApiService.PatchSelectedLocationPath, 'patch');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchSelectedLocation$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchSelectedLocation(params: {
    body: PatchSelectedLocationDto
  }): Observable<void> {

    return this.patchSelectedLocation$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
